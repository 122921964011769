import { v4 as uuidv4 } from 'uuid';
import { loadOrders, loadScans, loadTickets, purge, updateSettings } from './actions';
import { IItem, IItemPrice } from './items';
import { fiat } from './money';
import { ITickitNfcData } from './nfcUtils';
import {
  createBlankOrder,
  IOrder,
  IOrderWithPrices,
  updateOrderLineItem,
  CardBrand,
} from './orders';
import { IScan } from './scans';
import { ICurrencies, IEventWithItems, INITIAL_STATE, ISettingsState } from './settingsReducer';
import { ITicket } from './Ticket';

interface TestCard {
  cardNumber: string;
  cardBrand: CardBrand;
  cardExpiresOn: string;
}

export const TEST_CREDIT_CARDS: TestCard[] = [
  {
    cardNumber: '4242424242424242',
    cardBrand: 'visa',
    cardExpiresOn: '12/40',
  },
  {
    cardNumber: '5555555555554444',
    cardBrand: 'mastercard',
    cardExpiresOn: '12/40',
  },
  {
    cardNumber: '378282246310005',
    cardBrand: 'amex',
    cardExpiresOn: '12/40',
  },
  {
    cardNumber: '6011111111111117',
    cardBrand: 'discover',
    cardExpiresOn: '12/40',
  },
  {
    cardNumber: '3566002020360505',
    cardBrand: 'jcb',
    cardExpiresOn: '12/40',
  },
];

export const nfcWallet: ITickitNfcData = {
  tokenWalletID: 'aabbcc001122',
  currencies: [
    { currency: 'TT-MEALS', amount: 12345 },
    { currency: 'TT-DRINKS', amount: 555 },
  ],
};

export const nfcEmptyWallet: ITickitNfcData = {
  tokenWalletID: 'zzxxyy998877',
  currencies: [],
};

export function buildTicket(props: Partial<ITicket> = {}): ITicket {
  return Object.assign(
    {},
    {
      itemID: '1',
      orderID: uuidv4(),
      ticketNumber: uuidv4(),
      deleted: false,
      guestList: false,
      hardTicket: false,
      purchaserName: 'John Smith',
      ticketHolder: 'Sally White',
      checkInComments: '',
      organization: '',
      tags: [],
      qa: [
        { question_id: 'q1', answer: 'Answer 1' },
        { question_id: 'q2', answer: 'Answer 2' },
      ],
      emails: ['john@example.com', 'sally@example.com'],
      itemTitle: 'Test Ticket Type',
      eventTitle: 'Test Event',
      unlimitedCheckins: false,
    } as ITicket,
    props,
  );
}

export function buildItem(props: Partial<IItem>): IItem {
  return Object.assign(
    {},
    {
      eventID: '1',
      eventTitle: 'Event name',
      itemID: uuidv4(),
      itemTitle: 'Item 1',
      itemGroupTitle: 'Event group',
      itemPosition: 1,
      itemGroupPosition: 1,
      generatedTokenCurrency: null,
      tokensToGenerateCents: null,
      unlimitedCheckins: false,
      assignedSeating: false,
      prices: {
        CAD: {
          basePriceCents: 12345,
          tickitFeesCents: 800,
          sellerFeesCents: 400,
          unitTaxCents: 215,
          displayFeesCents: 1200,
          displayPriceCents: 12345,
          fiat: true,
          taxPercent: 0.0,
          currency: 'CAD',
        } as IItemPrice,
      },
      quantityAvailable: 8765,
      scannable: true,
      purchasable: true,
    } as IItem,
    props,
  );
}

export const defaultItem = buildItem({
  eventID: '1',
  eventTitle: 'Event name',
  itemID: '1',
  itemTitle: 'Item 1',
});

export const compItem = buildItem({
  eventID: '1',
  eventTitle: 'Event name',
  itemID: 'c1',
  itemTitle: 'compItem',
  prices: {
    CAD: {
      basePriceCents: 0,
      tickitFeesCents: 0,
      sellerFeesCents: 0,
      unitTaxCents: 0,
      displayFeesCents: 0,
      displayPriceCents: 0,
      fiat: true,
      currency: 'CAD',
    } as IItemPrice,
  },
});

export function generateItemTokenPrice(cents: number, currency: string): IItemPrice {
  return {
    fiat: false,
    currency,
    basePriceCents: cents,
    tickitFeesCents: 0,
    sellerFeesCents: 0,
    unitTaxCents: 0,
    taxPercent: 0.0,
    displayPriceCents: cents,
    displayFeesCents: 0,
  };
}

/**
 * Create a fiat ItemPrice - subTotal per LineItem is 5135
 */
export function generateItemFiatPrice(currency = 'CAD'): IItemPrice {
  return {
    currency: currency,
    basePriceCents: 4500,
    tickitFeesCents: 200,
    sellerFeesCents: 200,
    taxPercent: 5.0,
    displayPriceCents: 4500,
    unitTaxCents: 235,
    displayFeesCents: 400,
    fiat: true,
  };
}

const purchasableItem = buildItem({
  eventID: '1',
  eventTitle: 'Event name',
  itemID: '3',
  itemTitle: 'Walk-up Day Pass',
  prices: {
    CAD: {
      basePriceCents: 12000,
      tickitFeesCents: 0,
      sellerFeesCents: 0,
      unitTaxCents: 0,
      displayFeesCents: 0,
      displayPriceCents: 12000,
      fiat: true,
      currency: 'CAD',
    } as IItemPrice,
  },
  scannable: false,
  purchasable: true,
  quantityAvailable: 9876,
});

const scannableItem = buildItem({
  eventID: '1',
  eventTitle: 'Event name',
  itemID: '4',
  itemTitle: 'Pre-sale Day Pass',
  prices: {
    CAD: {
      basePriceCents: 12000,
      tickitFeesCents: 0,
      sellerFeesCents: 0,
      unitTaxCents: 0,
      displayFeesCents: 0,
      displayPriceCents: 12000,
      fiat: true,
      currency: 'CAD',
    } as IItemPrice,
  },
  scannable: true,
  purchasable: false,
});

const multiScanItem = buildItem({
  eventID: '1',
  eventTitle: 'Event name',
  itemID: '2',
  itemTitle: 'Multiscan item',
  unlimitedCheckins: true,
  scannable: true,
  purchasable: true,
});

const otherEventItem = buildItem({
  eventID: '2',
  eventTitle: 'Other event name',
  itemID: '2-1',
  itemTitle: 'Other event item',
  scannable: true,
  purchasable: true,
});

const beerGardenEventID = 'event-beer';

export const beerGardenItems = [
  'Red Racer IPA',
  'Pilsner',
  'Forbidden Valley Cider',
  'Pinot Grigio VQA',
].map((itemTitle, index) => {
  return buildItem({
    eventID: beerGardenEventID,
    eventTitle: 'Beer & Wine',
    itemID: `${beerGardenEventID}-${index}`,
    itemTitle,
    prices: {
      CAD: {
        basePriceCents: 500,
        tickitFeesCents: 0,
        sellerFeesCents: 0,
        unitTaxCents: 0,
        displayFeesCents: 0,
        displayPriceCents: 500,
        fiat: true,
        currency: 'CAD',
      } as IItemPrice,

      'TT-DRINKS': generateItemTokenPrice(1000, 'TT-DRINKS'),
    },
    scannable: false,
    purchasable: true,
  });
});

const staffMealEventID = 'event-staff-meal';

export const staffMealItems = ['Staff Breakfast', 'Staff Dinner'].map((itemTitle, index) => {
  return buildItem({
    eventID: staffMealEventID,
    eventTitle: 'Staff Meals',
    itemID: `${staffMealEventID}-${index}`,
    itemTitle,
    prices: { 'TT-MEAL': generateItemTokenPrice(1000, 'TT-MEAL') },
    scannable: false,
    purchasable: true,
  });
});

export const currencies: ICurrencies = {
  CAD: {
    fiat: true,
    currency: 'CAD',
    iso_code: 'CAD',
    subunit_to_unit: 100,
    name: 'Canadian Dollar',
    symbol: '$',
  },
  'TT-DRINKS': {
    fiat: false,
    currency: 'TT-DRINKS',
    iso_code: 'TT-DRINKS',
    name: 'Drink Tickets',
    symbol: 'Ŧ',
    subunit_to_unit: 1000,
  },
  'TT-MEAL': {
    fiat: false,
    currency: 'TT-MEAL',
    iso_code: 'TT-MEAL',
    name: 'Staff Meal',
    symbol: 'Ŧ',
    subunit_to_unit: 1000,
  },
};

export const settings: ISettingsState = Object.assign({}, INITIAL_STATE, {
  availableItems: [
    {
      eventTitle: 'Event name',
      eventID: '1',
      items: [defaultItem, multiScanItem, purchasableItem, scannableItem],
    },
    {
      eventTitle: 'Other event name',
      eventID: '2',
      items: [otherEventItem],
    },
    {
      eventTitle: 'Beer & Wine',
      eventID: beerGardenEventID,
      items: beerGardenItems,
    },
  ],
  purchasableItems: [
    {
      eventTitle: 'Beer & Wine',
      eventID: beerGardenEventID,
      items: beerGardenItems,
    },
    {
      eventTitle: 'Event name',
      eventID: '1',
      items: [defaultItem, multiScanItem, purchasableItem],
    },
    {
      eventTitle: 'Other event name',
      eventID: '2',
      items: [otherEventItem],
    },
  ],
  scannableItems: [
    {
      eventTitle: 'Event name',
      eventID: '1',
      items: [defaultItem, multiScanItem, scannableItem],
    },
  ],
  availableItemIDs: [
    defaultItem.itemID,
    multiScanItem.itemID,
    purchasableItem.itemID,
    scannableItem.itemID,
  ],
  sellingItemIDs: [defaultItem.itemID, multiScanItem.itemID, purchasableItem.itemID],
  scanningItemIDs: [defaultItem.itemID, scannableItem.itemID, multiScanItem.itemID],
  scannerID: 'scannerID-abcdef',
  currencies,
  currentShiftDigest: 'shiftID-abcdef',
});

export const itemsForSale: Array<IEventWithItems> = (settings.purchasableItems || []).slice(0);

const manifestAPIResponse = {
  user: {
    hasCheckinPermission: true,
    hasGlobalStatsPermission: true,
  },
  currencies: currencies,
  events: [
    {
      event_id: 'combo-event-id',
      event_title: 'Combo Tickets',
      event_position: -1,
      items: [
        {
          event_id: 'combo-event-id',
          event_title: null,
          item_id: '27155',
          item_title: 'Festival and After Party Combo Ticket',
          item_group_title: null,
          item_group_position: null,
          item_position: null,
          prices: {
            CAD: {
              basePriceCents: 15500,
              tickitFeesCents: 600,
              sellerFeesCents: 200,
              taxPercent: '5.0',
              displayPriceCents: 15500,
              unitTaxCents: 785,
              displayFeesCents: 800,
              currency: 'CAD',
              fiat: true,
            },
          },
          ticket_terms: null,
          ticket_instructions: null,
          quantity_available: null,
          unlimited_checkins: false,
          scannable: false,
          purchasable: true,
        },
      ],
    },
    {
      event_id: '1',
      event_title: 'The Festival',
      event_position: 0,
      items: [
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '1',
          item_title: 'Purchasable and Scannable with a really long name',
          item_group_title: 'Multi Day',
          item_group_position: 0,
          item_position: 0,
          prices: {
            CAD: {
              basePriceCents: 13500,
              tickitFeesCents: 400,
              sellerFeesCents: 400,
              taxPercent: '5.0',
              displayPriceCents: 13500,
              unitTaxCents: 695,
              displayFeesCents: 800,
              currency: 'CAD',
              fiat: true,
            },
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: 97,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },

        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27141',
          item_title: 'Purchasable Only',
          item_group_title: 'Multi Day',
          item_group_position: 0,
          item_position: 2,
          prices: {
            CAD: {
              basePriceCents: 17500,
              tickitFeesCents: 500,
              sellerFeesCents: 400,
              taxPercent: '5.0',
              displayPriceCents: 17500,
              unitTaxCents: 895,
              displayFeesCents: 900,
              currency: 'CAD',
              fiat: true,
            },
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: false,
          purchasable: true,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27142',
          item_title: 'Scannable Only',
          item_group_title: 'Single Day',
          item_group_position: 3,
          item_position: 3,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: false,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27144',
          item_title: 'Early Bird Sunday Pass',
          item_group_title: 'Single Day',
          item_group_position: 3,
          item_position: 5,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27145',
          item_title: 'Friday Pass',
          item_group_title: 'Single Day',
          item_group_position: 3,
          item_position: 6,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27146',
          item_title: 'Saturday Pass',
          item_group_title: 'Single Day',
          item_group_position: 3,
          item_position: 7,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27147',
          item_title: 'Sunday Pass',
          item_group_title: 'Single Day',
          item_group_position: 3,
          item_position: 8,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27148',
          item_title: 'Camping Pass',
          item_group_title: 'Camping',
          item_group_position: 9,
          item_position: 9,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: 187,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
        {
          event_id: '1',
          event_title: 'The Festival',
          item_id: '27151',
          item_title: 'Early Entry Add-on',
          item_group_title: 'Add-ons',
          item_group_position: 12,
          item_position: 12,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
      ],
    },
    {
      event_id: '5614',
      event_title: 'The After Party',
      event_position: 3,
      items: [
        {
          event_id: '5614',
          event_title: 'The After Party',
          item_id: '27153',
          item_title: 'After Party Ticket',
          item_group_title: 'General Admission',
          item_group_position: 0,
          item_position: 0,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
      ],
    },
    {
      event_id: '5613',
      event_title: 'The Bar Show',
      event_position: 6,
      items: [
        {
          event_id: '5613',
          event_title: 'The Bar Show',
          item_id: '27152',
          item_title: 'Bar Show Ticket',
          item_group_title: 'General Admission',
          item_group_position: 0,
          item_position: 0,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: null,
          unlimited_checkins: false,
          scannable: true,
          purchasable: true,
        },
      ],
    },
    {
      event_id: '5615',
      event_title: 'Evening Concert With a Long Wrapping Name',
      event_position: 9,
      items: [
        {
          event_id: '5615',
          event_title: 'Evening Concert',
          item_id: '27154',
          item_title: 'VIP Seating',
          item_group_title: 'General Admission',
          item_group_position: 0,
          item_position: 0,
          prices: {
            CAD: generateItemFiatPrice(),
          },
          ticket_terms:
            'Doors open at 8pm.  If you have any questions about your order please email hello@tickit.ca.\n\nAll ticket sales and fees are final and non-refundable.',
          ticket_instructions: 'Present this ticket for entry.',
          quantity_available: 131,
          unlimited_checkins: false,
          scannable: true,
          purchasable: false,
        },
      ],
    },
  ],
};

const ticketBasics = {
  itemTitle: 'Full Festival Weekend Pass',
  eventTitle: 'Amazing Festival - 2020 Edition',
  admitted: false,
  guestList: false,
  deleted: false,
  tags: [],
  emails: [],
  orderID: 'order1',
  hardTicket: false,
  checkInComments: '',
  organization: '',
  unlimitedCheckins: false,
};

const reversedTicket: ITicket = {
  ...ticketBasics,
  itemID: '1',
  ticketNumber: '11111-A',
  purchaserName: 'Jim Smith',
  ticketHolder: 'Suzy White',
  organization: 'Villager Events, Inc.',
  emails: ['jim@example.com', 'suzy@example.com'],
};

const multiScanTicket: ITicket = {
  ...ticketBasics,
  itemID: '2',
  ticketNumber: '33333-A',
  unlimitedCheckins: true,
  purchaserName: 'Mike Jones',
  ticketHolder: 'Betty Jones',
  emails: ['betty@example.com', 'mike@example.com'],
};

const notAdmittedTicket: ITicket = {
  ...ticketBasics,
  orderID: '1',
  itemID: '1',
  ticketNumber: '777777-A',
  deleted: false,
  purchaserName: 'John Smith',
  ticketHolder: 'Sally White',
  organization: 'Villager Events, Inc.',
  emails: ['john@example.com', 'sally@example.com'],
  tags: ['VIP Entry', 'Drink Tickets: 5', 'Meal Passes: 3', 'Silver Wristband'],
  itemTitle: 'Full Festival Weekend Pass',
  eventTitle: 'Amazing Festival - 2020 Edition',
  checkInComments: 'Here are some check-in comments that take up a bunch of space',
};

const guestListTicket: ITicket = {
  ...ticketBasics,
  orderID: '1',
  itemID: '1',
  ticketNumber: '222222-A',
  purchaserName: 'John Smith',
  ticketHolder: 'Sally White',
  organization: 'Villager Events, Inc.',
  emails: ['john@example.com', 'sally@example.com'],
  tags: ['VIP Entry', 'Drink Tickets: 5', 'Meal Passes: 3', 'Silver Wristband'],
  checkInComments: 'Here are some check-in comments that take up a bunch of space',
  guestList: true,
};

const admittedTicket: ITicket = {
  ...ticketBasics,
  orderID: '1',
  itemID: '1',
  eventTitle: '',
  itemTitle: '',
  ticketNumber: '222222-A',
  deleted: false,
  purchaserName: 'John Smith',
  ticketHolder: 'Sally White',
  organization: 'Villager Events, Inc.',
  emails: ['john@example.com', 'sally@example.com'],
  tags: [],
  checkInComments: 'Here are some check-in comments that take up a bunch of space',
  guestList: false,
  admitted: true,
};

const deletedTicket: ITicket = {
  ...ticketBasics,
  itemID: '1',
  ticketNumber: '88888-A',
  deleted: true,
  deletedReason: 'Transferred away',
  purchaserName: 'John Smith',
  ticketHolder: 'Sally White',
  emails: ['john@example.com', 'sally@example.com'],
};

// not included in the availableItems settings
const unscannableTicket: ITicket = {
  ...ticketBasics,
  itemID: '9',
  ticketNumber: '55555-A',
  purchaserName: 'Point of Sale',
  ticketHolder: 'Point of Sale',
  emails: ['unscannable@example.com'],
};

const printableTicket: ITicket = {
  ...ticketBasics,
  orderID: '1',
  itemID: '1',
  ticketNumber: '66666-A',
  purchaserName: 'Printable Purchaser',
  ticketHolder: 'Printable Ticket Holder',
  organization: 'Villager Events, Inc.',
  hardTicket: true,
  fgl: {
    layout1: 'FGL-CONTENT',
  },
};

export function buildScan(props): IScan {
  return Object.assign(
    {},
    {
      uuid: 'X',
      location: 'location',
      itemID: '1',
      ticketNumber: 'X',
      scannedAt: 0,
      synced: false,
      valid: true,
      unlimitedCheckins: false,
    } as Partial<IScan>,
    props,
  );
}

const reversedScan = buildScan({
  uuid: 'AAAA',
  location: 'location',
  itemID: reversedTicket.itemID,
  ticketNumber: reversedTicket.ticketNumber,
  scannedAt: 0,
  synced: false,
  valid: true,
});

const reversingScan = buildScan({
  uuid: 'BBBB',
  location: 'location',
  itemID: reversedTicket.itemID,
  ticketNumber: reversedTicket.ticketNumber,
  scannedAt: 1,
  synced: false,
  valid: true,
  reversal: true,
});

const validScan = buildScan({
  uuid: 'CCCC',
  location: 'location',
  itemID: admittedTicket.itemID,
  ticketNumber: admittedTicket.ticketNumber,
  scannedAt: 0,
  ticket: admittedTicket,
  synced: false,
  valid: true,
  seatSection: 'Orchestra',
  seatRow: 'XX',
  seatNumber: '88',
  message: 'OK',
  details: 'Admitted on January 1, 2000 (3 days ago) at VIP Gate',
});

const alreadyAdmittedScan = buildScan({
  uuid: 'CCCCX',
  location: 'location',
  itemID: admittedTicket.itemID,
  ticketNumber: admittedTicket.ticketNumber,
  scannedAt: 0,
  ticket: admittedTicket,
  synced: false,
  valid: false,
  seatSection: 'Orchestra',
  seatRow: 'XX',
  seatNumber: '88',
  message: 'Already admitted',
  details: 'Admitted on January 1, 2000 (3 days ago) at VIP Gate',
});

const basicOrder = () => {
  const order = createBlankOrder({
    orderID: '1',
    uuid: 'basic1',
    currency: 'CAD',
    tickets: [admittedTicket],
  });

  return updateOrderLineItem(order, defaultItem, 1);
};

export const pendingTokenOrder = () => {
  const order = createBlankOrder({
    email: 'buyer@example.com',
    name: 'Buyer name',
    currency: 'TT-DRINKS',
  });

  return updateOrderLineItem(order, beerGardenItems[0], 2);
};

const pendingFilledInOrder = () => {
  let order = createBlankOrder({
    email: 'buyer@example.com',
    name: 'Buyer name',
    cardNumber: '4000 0000 0000 0000',
    cardVerification: '',
    cardExpiresOn: '12 / 20',
  });

  order = updateOrderLineItem(order, defaultItem, 2);
  order = updateOrderLineItem(order, purchasableItem, 3);
  return order;
};

const completedOrder = () => {
  let order = createBlankOrder({
    orderID: '12345',
    email: 'buyer@example.com',
    name: 'Completed order buyer’s name',
    cardNumber: '4000 0000 0000 0000',
    cardVerification: '123',
    cardExpiresOn: '12 / 20',
  });

  order = updateOrderLineItem(order, defaultItem, 2);
  order = updateOrderLineItem(order, purchasableItem, 4);
  return order;
};

const decorateOrderWithPrices = (order: IOrder): IOrderWithPrices => {
  return {
    ...order,
    displayFees: fiat(123),
    tax: fiat(456),
    tip: fiat(789),
    grandTotal: fiat(123456),
  } as IOrderWithPrices;
};

const emptyOrder = () => {
  return createBlankOrder();
};

const blankPendingOrder = () => {
  let order = createBlankOrder();
  order = updateOrderLineItem(order, defaultItem, 2);
  order = updateOrderLineItem(order, purchasableItem, 3);
  return order;
};

export function seedStore(store) {
  store.dispatch(purge());

  const timestamp = new Date().getTime();

  const tickets = {};
  tickets[admittedTicket.ticketNumber] = admittedTicket;
  tickets[deletedTicket.ticketNumber] = deletedTicket;
  tickets[multiScanTicket.ticketNumber] = multiScanTicket;
  tickets[reversedTicket.ticketNumber] = reversedTicket;
  tickets[unscannableTicket.ticketNumber] = unscannableTicket;
  tickets[printableTicket.ticketNumber] = printableTicket;

  store.dispatch(updateSettings(settings));
  store.dispatch(loadOrders([basicOrder()]));

  store.dispatch(
    loadTickets(tickets, {
      lastTicketReceived: timestamp,
      lastTicketPull: timestamp,
      hasTicketsSince: timestamp,
    }),
  );

  const scans = [reversedScan, reversingScan, validScan];

  store.dispatch(
    loadScans(scans, {
      lastTicketReceived: timestamp,
      lastTicketPull: timestamp,
      hasTicketsSince: timestamp,
    }),
  );
}

export {
  decorateOrderWithPrices,
  basicOrder,
  emptyOrder,
  completedOrder,
  otherEventItem,
  blankPendingOrder,
  pendingFilledInOrder,
  deletedTicket,
  reversedTicket,
  multiScanTicket,
  admittedTicket,
  guestListTicket,
  notAdmittedTicket,
  unscannableTicket,
  printableTicket,
  reversingScan,
  reversedScan,
  validScan,
  alreadyAdmittedScan,
  manifestAPIResponse,
};
