import { BackgroundPollingTimer } from 'shared/lib/BackgroundPollingTimer';

let pollingIntervalID: number | undefined;

const pollingTimer: BackgroundPollingTimer = {
  startPollingInterval: (callback, timeout) => {
    console.debug('pollingTimer.startPollingInterval');
    pollingIntervalID = window.setInterval(callback, timeout);
  },

  stopPollingInterval: () => {
    if (pollingIntervalID) {
      window.clearInterval(pollingIntervalID);
      pollingIntervalID = undefined;
    }
  },
  platform: 'web',
};

window.pollingTimer = pollingTimer;
