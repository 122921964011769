import { createSelector } from '@reduxjs/toolkit';
import { fiat } from 'shared/money';
import { addTicketTypesToOrder, createBlankOrder, IOrder, IOrderWithPrices } from 'shared/orders';
import { IScan } from 'shared/scans';
import { generateSearchResult } from 'shared/search';
import { getQuestions } from '../getAnswersForTicket';
import { RootState } from '../rootReducer';
import { getTicketTypes } from '../ticketTypeSelector';
import getPossibleOrderTotals from './getPossibleOrderTotals';

interface Props {
  orderUUID?: string;
}

const getOrder = (state: RootState, props: Props | null | undefined): IOrder | undefined => {
  const orderUUID = props && props.orderUUID ? props.orderUUID : state.orders.currentOrderUUID;
  return orderUUID ? state.orders.orders[orderUUID] : undefined;
};
const getScanningItemIDs = (state: RootState) => state.settings.scanningItemIDs;
const getTickets = (state: RootState) => state.tickets;
const getScansByTicketNumber = (state: RootState) => state.scans.scansByTicketNumber;

/**
 * Returns the current shopping card Order with any tickets fully decorated.
 *
 * Tickets will live update when scans are received.
 *
 * If the order has a CURRENCY defined then we'll also populate the grandTotal etc...
 *
 * Used throughout the Purchase section.
 */
const getCurrentOrder = createSelector(
  getOrder,
  getTicketTypes,
  getScanningItemIDs,
  getTickets,
  getScansByTicketNumber,
  getQuestions,
  (
    rawOrder,
    ticketTypes,
    scanningItemIDs,
    tickets,
    scansByTicketNumber,
    questions,
  ): IOrderWithPrices => {
    if (!rawOrder) {
      rawOrder = createBlankOrder();
    }

    const orderWithTickets = { ...rawOrder };

    orderWithTickets.tickets = [];
    Object.values(tickets).forEach(ticket => {
      if (ticket.orderID !== orderWithTickets.orderID) return;

      const scan: IScan | undefined = scansByTicketNumber[ticket.ticketNumber];

      orderWithTickets.tickets.push(generateSearchResult(ticket, scan, questions));
    });

    const orderWithTicketTypes = addTicketTypesToOrder(
      orderWithTickets,
      ticketTypes,
      scanningItemIDs,
    );

    const orderWithPrices: IOrderWithPrices = {
      ...orderWithTicketTypes,
      displayFees: null,
      tax: null,
      tip: null,
      totalExcludingTip: fiat(0),
      grandTotal: fiat(0),
    };

    if (orderWithTicketTypes.currency) {
      const possible = getPossibleOrderTotals(orderWithTicketTypes);

      const currencyTotal = possible.PossibleOrderTotals.find(
        t => t.currency === orderWithTicketTypes.currency,
      );
      if (currencyTotal) {
        orderWithPrices.tax = currencyTotal.tax;
        orderWithPrices.displayFees = currencyTotal.displayFees;
        orderWithPrices.tip = currencyTotal.tip;
        orderWithPrices.totalExcludingTip = currencyTotal.totalExcludingTip;
        orderWithPrices.grandTotal = currencyTotal.grandTotal;
      }
    }

    return orderWithPrices;
  },
);

/** Factory for getCurrentOrder to allow us to pass specific orderUUIDs */
export const makeGetCurrentOrder = () => {
  return getCurrentOrder;
};

export default getCurrentOrder;
