import { createAction } from '@reduxjs/toolkit';
import * as constants from './constants';
import { IDeviceStatus } from './deviceStatusReducer';
import { IItem } from './items';
import { IOrder, CartData } from './orders';
import { IScan } from './scans';
import { ISettingsState, UIScreen } from './settingsReducer';
import { ITicket } from './Ticket';

/**
 * Reset all redux stores to their initial state. Usually dispatched via the `clearAllData` thunk.
 */
export const purge = createAction('app/purge');

export const recordScan = createAction('scans/record', function prepare(
  scan: IScan,
  showOnScanScreen = true,
) {
  return { payload: scan, meta: { showOnScanScreen } };
});

export const updateSettings = createAction('settings/update', function prepare(
  settings: Partial<ISettingsState>,
  skipPersisting = false,
) {
  return {
    payload: { settings: settings },
    meta: { skipPersisting },
  };
});

export function changeCurrentScreen(screenName: UIScreen) {
  return {
    type: updateSettings.toString(),
    payload: { settings: { currentScreen: screenName } },
  };
}

export const loadTickets = createAction('tickets/load', function prepare(
  tickets: { [key: string]: ITicket },
  settings: Partial<ISettingsState> = {},
  skipPersisting = false,
) {
  return { payload: { tickets, settings }, meta: { skipPersisting } };
});

export const loadScans = createAction('scans/load', function prepare(
  scans: Array<IScan>,
  settings: Partial<ISettingsState> = {},
  skipPersisting = false,
) {
  return {
    payload: { scans, settings },
    meta: { skipPersisting },
  };
});

export const loadOrders = createAction('orders/load', function prepare(
  orders: Array<IOrder>,
  skipPersisting = false,
) {
  return { payload: orders, meta: { skipPersisting } };
});

export const saveCompletedOrder = createAction('orders/saveCompleted', function prepare(
  order: IOrder,
) {
  return {
    payload: order,
  };
});

export const setCurrentOrderUUID = createAction('orders/setCurrentUUID', function prepare(
  orderUUID: string | undefined,
) {
  return {
    payload: orderUUID,
  };
});

export function clearCurrentScan() {
  return {
    type: constants.CLEAR_CURRENT_SCAN,
  };
}

// export function processScan(ticketNumber: string, reversal: boolean = false) {
//   return {
//     type: constants.PROCESS_SCAN,
//     payload: ticketNumber,
//     meta: { reversal },
//   };
// }

// // do a scan that does not update the <Scan> component (e.g. from the <Search> or <Purchase> components)
// export function processManualScan(ticketNumber: string, reversal: boolean = false) {
//   return {
//     type: constants.PROCESS_SCAN,
//     payload: ticketNumber,
//     meta: { reversal, preserveScanScreen: true },
//   };
// }

export function scanRandom() {
  return {
    type: constants.SCAN_RANDOM,
  };
}

export function reverseAdmission(ticketNumber) {
  return {
    type: constants.PROCESS_SCAN,
    payload: ticketNumber,
    meta: { reversal: true },
  };
}

export const search = createAction('search/query', function prepare(
  searchTerms: string | undefined,
) {
  return {
    payload: { searchTerms: searchTerms || '' },
  };
});

export const markScansAsSynced = createAction('scans/markSynced', function prepare(
  uuids: Array<string>,
) {
  return {
    payload: uuids,
  };
});

export const markShiftsAsSynced = createAction('settings/markShiftsSynced', function prepare(
  shiftDigests: Array<string>,
) {
  return {
    payload: shiftDigests,
  };
});

export function updateFromSharedConfig() {
  return {
    type: constants.UPDATE_FROM_SHARED_CONFIG,
  };
}

type LoadManifestParams = {
  scanningItemIDs?: Array<string | number>;
  sellingItemIDs?: Array<string | number>;
  scanningOccurrenceIDs?: Array<string>;
  sellingOccurrenceIDs?: Array<string>;
};

export const defineManifest = createAction('settings/defineManifest', function prepare({
  scanningItemIDs = [],
  sellingItemIDs = [],
  scanningOccurrenceIDs = [],
  sellingOccurrenceIDs = [],
}: LoadManifestParams) {
  return {
    payload: {
      scanningItemIDs,
      sellingItemIDs,
      scanningOccurrenceIDs,
      sellingOccurrenceIDs,
    },
  };
});

export const updateOrder = createAction('orders/updateCurrent', function prepare(
  order: Partial<IOrder>,
) {
  return {
    payload: order,
  };
});

export const updateCurrentOrderFromServer = createAction(
  'orders/updateCurrentOrderFromServer',
  function prepare(cartData: CartData) {
    return {
      payload: cartData,
    };
  },
);

export const updateOrderLineItem = createAction('orders/updateCurrentLineItems', function prepare(
  item: IItem,
  quantity: number,
) {
  return {
    payload: {
      item,
      quantity,
    },
  };
});

export function setHardTicketDelivery(hardTicketDelivery: boolean) {
  return {
    type: constants.SET_HARD_TICKET_DELIVERY,
    payload: hardTicketDelivery,
  };
}

export function printTickets(order: IOrder, ticketNumbers: Array<string> = []) {
  return {
    type: constants.PRINT_TICKETS,
    payload: {
      order,
      ticketNumbers,
    },
  };
}

export function connectToPrinter(printerIP: string) {
  return {
    type: constants.CONNECT_TO_PRINTER,
    payload: printerIP,
  };
}

export function reconnectToPrinter() {
  return {
    type: constants.RECONNECT_TO_PRINTER,
  };
}

export function disconnectFromPrinter() {
  return {
    type: constants.DISCONNECT_FROM_PRINTER,
  };
}

export const resetCurrentOrderLocally = createAction('orders/resetCurrent');

export function startPolling() {
  return { type: constants.START_POLLING };
}

export function stopPolling() {
  return { type: constants.STOP_POLLING };
}

export function postDebugData() {
  return { type: constants.POST_DEBUG_DATA };
}

export function updateBattery(batteryPercent: number | null | undefined, pluggedIn: boolean) {
  return { type: constants.UPDATE_BATTERY, payload: { batteryPercent, pluggedIn } };
}

export function updateDeviceStatus(payload: Partial<IDeviceStatus>) {
  return { type: constants.UPDATE_DEVICE_STATUS, payload };
}
