import React from 'react';
import { connect } from 'react-redux';
import { updateSettings } from 'shared/actions';
import { AppThunkDispatch, RootState } from 'shared/rootReducer';
import availableItemsForLoading from 'shared/settings/availableItemsForLoading';
import getShareableConfig from 'shared/settings/getShareableConfig';
import { getWebSetupSettings, ISettingsState, SetupScreens } from 'shared/settingsReducer';
import {
  configureManifestAndImport,
  ConfigureManifestParams,
  postDeviceConfiguration,
} from 'shared/syncActions';
import { ErrorBoundary } from '../ErrorBoundary';
import SettingsConfigQR from './SettingsConfigQR';
import { SettingsLoadTickets } from './SettingsLoadTickets';

interface State {
  loadingManifest: boolean;
  setupErrorMessage: string | undefined;
}

class SettingsPanel extends React.Component<Props, State> {
  state = {
    loadingManifest: false,
    setupErrorMessage: undefined,
  };

  onClose = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    this.props.onClose();
  };

  configureManifestAndImport = async (params: ConfigureManifestParams): Promise<void> => {
    this.setState({ loadingManifest: true, setupErrorMessage: undefined });
    try {
      await this.props.configureManifestAndImport(params);
    } catch (error) {
      this.setState({
        loadingManifest: false,
        setupErrorMessage: error.message,
      });
    }
  };

  renderBody() {
    switch (this.props.settings.setupScreen) {
      case SetupScreens.ConfigureQR:
        return (
          <SettingsConfigQR
            shareableConfigSettings={this.props.shareableConfigSettings}
            postDeviceConfiguration={this.props.postDeviceConfiguration}
          />
        );

      case SetupScreens.LoadingManifest:
        return (
          <SettingsLoadTickets
            loadingManifest={this.state.loadingManifest}
            setupErrorMessage={this.state.setupErrorMessage}
            scanningItemIDs={this.props.settings.scanningItemIDs}
            scanningOccurrenceIDs={this.props.settings.scanningOccurrenceIDs}
            configureManifestAndImport={this.configureManifestAndImport}
            availableItems={this.props.availableItems}
          />
        );
      default: {
        console.warn('unknown setup screen');
      }
    }
  }

  render() {
    const classNames = ['overlay-panel', 'settings-panel', 'visible'];

    if (!this.props.settings.setupScreen) return null;

    return (
      <div className={classNames.join(' ')}>
        <ErrorBoundary>
          <button className="overlay-close-btn" onClick={this.onClose} aria-label="Close Stats">
            <i className="icon icon-close" />
          </button>
          <div className="settings-panel-content overlay-content">{this.renderBody()}</div>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    settings: getWebSetupSettings(state),
    availableItems: availableItemsForLoading(state),
    shareableConfigSettings: getShareableConfig(state),
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    postDeviceConfiguration: () => dispatch(postDeviceConfiguration()),
    configureManifestAndImport: (params: ConfigureManifestParams) =>
      dispatch(configureManifestAndImport(params)),
    updateSettings: (settings: Partial<ISettingsState>, skipPersisting = false) =>
      dispatch(updateSettings(settings, skipPersisting)),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ReceivedProps = {
  onClose: () => void;
  selectInput: (deleteInput?: boolean) => void;
};

interface Props extends StateProps, DispatchProps, ReceivedProps {}

export interface PassedProps extends Omit<Props, 'configureManifestAndImport'> {
  loadingManifest: boolean;
  setupErrorMessage: string | undefined;
  configureManifestAndImport: (params: ConfigureManifestParams) => Promise<void>;
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);
