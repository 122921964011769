import Dinero from 'dinero.js';
import uniq from 'lodash/uniq';
import { fiat, isTokenCurrency, tokens } from 'shared/money';
import { addLineItemPrices, IOrder } from 'shared/orders';

export interface PossibleOrderTotal {
  tax: Dinero.Dinero;
  displayFees: Dinero.Dinero;
  totalExcludingTip: Dinero.Dinero;
  tip: Dinero.Dinero;
  grandTotal: Dinero.Dinero;
  fiat: boolean;
  currency: string;
}

export interface PossibleOrderTotals {
  allowCompPurchase: boolean;
  onlyFiatPurchase: boolean;
  onlyPossibleCurrency: string | undefined;
  PossibleOrderTotals: Array<PossibleOrderTotal>;
}

/**
 * Returns an object detailing the various currencies, both fiat and token,
 * eligible to use during checkout.
 */
function getPossibleCurrencies(order: IOrder): Array<string> {
  let possibleCurrencies: string[] | null = null;

  Object.values(order.lineItems).forEach(lineItem => {
    if (lineItem.quantity < 1) {
      return;
    }

    // when returning from the API (i.e. calling fromJSON) we won't have an item object but we
    // will already have all the subtotals
    const item = lineItem.item;
    if (!item) {
      console.error('Missing lineItem.item');
      return;
    }
    // build possibleCurrencies as the intersection of existing currencies
    const itemCurrencyIDs = Object.keys(item.prices);

    if (possibleCurrencies == null) {
      // first item
      possibleCurrencies = itemCurrencyIDs; // currencyIDs
    } else {
      possibleCurrencies = possibleCurrencies.filter(currencyID => {
        return itemCurrencyIDs.includes(currencyID);
      });
    }
  });

  return uniq(possibleCurrencies) || [];
}

function allowCompPurchase(order: IOrder, PossibleOrderTotals: Array<PossibleOrderTotal>): boolean {
  // no line items
  if (Object.keys(order.lineItems).length < 1) return false;

  // no valid totals for the mix of line items
  if (PossibleOrderTotals.length < 1) return false;

  for (let i = 0; i < PossibleOrderTotals.length; i++) {
    const PossibleOrderTotal = PossibleOrderTotals[i];
    // disallowed if any item can be purchased with tokens (currently)
    if (!PossibleOrderTotal.fiat) return false;

    // not all items are comp
    if (!PossibleOrderTotal.grandTotal.isZero()) return false;
  }

  return true;
}

function calculateOrderTotals(order: IOrder, currency: string): PossibleOrderTotal {
  const isFiat = !isTokenCurrency(currency);
  const monetize = isFiat ? fiat : tokens;

  const tipPercent = (order.tipPercent || 0) / 100.0;

  const result: PossibleOrderTotal = {
    fiat: isFiat,
    currency: currency,
    tax: monetize(0, currency),
    tip: monetize(0, currency),
    totalExcludingTip: monetize(0, currency),
    displayFees: monetize(0, currency),
    grandTotal: monetize(0, currency),
  };

  Object.values(order.lineItems).forEach(rawLineItem => {
    const lineItem = addLineItemPrices(rawLineItem, currency);
    result.tax = result.grandTotal.add(lineItem.tax);
    result.displayFees = result.grandTotal.add(lineItem.displayFees);
    result.totalExcludingTip = result.totalExcludingTip.add(lineItem.subTotal);

    const lineItemTip = lineItem.basePrice.multiply(lineItem.quantity).multiply(tipPercent);
    result.tip = result.tip.add(lineItemTip);
    // console.log('cal qty', lineItem.itemID, lineItem.quantity, 'res', lineItemTip.toJSON());
    // if (isFiat) debugLineItem(lineItem);
    result.grandTotal = result.totalExcludingTip.add(result.tip);
  });

  return result;
}

/**
 * Returns an object detailing the various currencies, both fiat and token,
 * eligible to use during checkout.
 */
function getPossibleOrderTotals(order: IOrder): PossibleOrderTotals {
  const PossibleOrderTotals: Array<PossibleOrderTotal> = [];
  const possibleCurrencies = getPossibleCurrencies(order);

  possibleCurrencies.forEach(currency => {
    PossibleOrderTotals.push(calculateOrderTotals(order, currency));
  });

  const onlyFiatPurchase =
    possibleCurrencies.length > 0 && PossibleOrderTotals.every(total => total.fiat);

  return {
    PossibleOrderTotals,
    onlyFiatPurchase,
    onlyPossibleCurrency: possibleCurrencies.length === 1 ? possibleCurrencies[0] : undefined,
    allowCompPurchase: allowCompPurchase(order, PossibleOrderTotals),
  };
}

export default getPossibleOrderTotals;
