import { debugAnalytics } from 'shared/debug';
import { setErrorReportingUser } from './errorReporting';

export function setCurrentScreen(screenName) {
  debugAnalytics(`setCurrentScreen: ${screenName}`);
  if (window.ga) {
    window.ga('send', 'event', 'currentScreen', screenName);
  }
}

export function setUserId(userId) {
  debugAnalytics(`setUserId: ${userId}`);
  if (window.ga) {
    window.ga('set', 'userId', userId);
  }

  setErrorReportingUser(userId);
}

export function logEvent(event, params) {
  debugAnalytics(`logEvent: ${event}`, params);
  if (window.ga) {
    window.ga('set', 'event', event);
  }
}
