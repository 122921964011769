import { IOrder } from './Order';

/**
 * Sum of the line item quantities in an order
 */
export default function (order: IOrder, onlyItemIDs?: string[]): number {
  let lineItems = Object.values(order.lineItems);
  if (onlyItemIDs) {
    lineItems = lineItems.filter(lineItem => onlyItemIDs.includes(lineItem.itemID));
  }

  return lineItems.reduce(
    (a, b) => {
      return { quantity: a.quantity + b.quantity };
    },
    { quantity: 0 },
  ).quantity;
}
