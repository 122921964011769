import React from 'react';

interface Props {
  waiting: boolean;
  setupErrorMessage?: string;
  buttonLabel: string;
  waitingLabel: string;
}

export const SettingsActions: React.FunctionComponent<Props> = props => {
  let buttonClass, buttonLabel;

  const message = props.setupErrorMessage ? <p>{props.setupErrorMessage}</p> : false;

  if (props.waiting) {
    buttonLabel = '';
    buttonClass = 'btn btn-primary waiting';
  } else {
    ({ buttonLabel } = props);
    buttonClass = 'btn btn-primary ';
  }

  return (
    <div className="form-group settings-actions-group">
      <div className="message error" data-testid="setup-error-message">
        {message}
      </div>
      <div className="buttons">
        <button type="submit" className={buttonClass} disabled={props.waiting}>
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};
