import React from 'react';
import { TicketBadges } from './TicketBadges';
import { CheckInComments } from './CheckInComments';
import Seat from './Seat';
import { ITicket } from 'shared/Ticket';
import map from 'lodash/map';
import TickitUtil from 'shared/Utils';

interface Props {
  staticStatus?: string | undefined | null;
  onValidate: (ticketNumber: string, reversal?: boolean) => void;
  selectInput: () => void;
  ticket: ITicket;
}

interface State {
  showDetails: boolean;
}

export class SearchResultRow extends React.Component<Props, State> {
  hasClicked: boolean;

  constructor(props) {
    super(props);
    this.hasClicked = false; // prevent duplicate clicks before the async scan action has fully completed
    this.scan = this.scan.bind(this);
    this.reverseScan = this.reverseScan.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = { showDetails: false };
  }

  componentDidUpdate() {
    this.hasClicked = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.ticket.cacheKey !== nextProps.ticket.cacheKey ||
      this.state.showDetails !== nextState.showDetails
    );
  }

  scan(event) {
    event.preventDefault();
    if (this.hasClicked) {
      return;
    }
    this.props.onValidate(this.props.ticket.ticketNumber);
    this.hasClicked = true;
  }

  reverseScan(event) {
    event.preventDefault();
    if (this.hasClicked) {
      return;
    }
    this.props.onValidate(this.props.ticket.ticketNumber, true);
    this.hasClicked = true;
  }

  toggleDetails(event) {
    event.preventDefault();
    event.target.blur();

    this.setState(
      {
        showDetails: !this.state.showDetails,
      },
      () => {
        if (this.props.selectInput) this.props.selectInput();
      },
    );
  }

  renderDetails() {
    const { ticket } = this.props;
    if (ticket.deleted) {
      return (
        <div className="ticket-details">
          <p className="ticket-info">{ticket.deletedReason}.</p>
        </div>
      );
    }

    let i = 0;
    const details = map(this.props.ticket.details, detail => {
      i = i + 1;
      return (
        <p key={i} className="ticket-info">
          {detail}
        </p>
      );
    });
    return <div className="ticket-details">{details}</div>;
  }

  renderOccurrence(ticket: ITicket) {
    if (!ticket || !ticket.occurrenceStartsAt) return;

    return <p className="ticket-occurrence">{TickitUtil.formattedOccurrence(ticket)}</p>;
  }

  renderAnswers(ticket: ITicket) {
    if (!this.state.showDetails) return false;
    const { answers } = ticket;

    if (!answers || answers.length === 0) return false;
    const result: JSX.Element[] = [];
    answers.forEach((res, index) => {
      result.push(
        <p key={`q${index}`}>
          {res.question}
          {' // '}
          {res.answer}
        </p>,
      );
    });
    return result;
  }

  render() {
    let button, organization;
    const className = ['search-results-row'];
    const { ticket, staticStatus } = this.props;

    if (this.state.showDetails) {
      className.push('show-details');
    }

    if (ticket.deleted) {
      className.push('search-results-row-deleted');
    }

    let admitted = false;

    if (ticket.organization) {
      organization = ` // ${ticket.organization}`;
    }
    const name = (
      <span className="ticket-holder-name">
        {ticket.ticketHolder} {organization}
      </span>
    );

    if (ticket.unlimitedCheckins || ticket.deleted || !ticket.showAdmitButton) {
      button = '';
    } else {
      if (ticket.scan) {
        if (ticket.scan.reversal) {
          button = (
            <button className="btn btn-scan" onTouchStart={this.scan} onClick={this.scan}>
              Admit
            </button>
          );
        } else {
          className.push('admitted');
          admitted = true;
        }
      }

      if (staticStatus) {
        button = <div className="static-status">{staticStatus}</div>;
      } else {
        if (admitted) {
          button = (
            <button
              className="btn btn-scan btn-scan-reverse"
              onTouchStart={this.reverseScan}
              onClick={this.reverseScan}
            >
              Reverse
            </button>
          );
        } else {
          button = (
            <button className="btn btn-scan" onTouchStart={this.scan} onClick={this.scan}>
              Admit
            </button>
          );
        }
      }
    }

    const statusIcon = <i className="icon icon-info" />;

    const seat = ticket.seatNumber ? (
      <span>
        <Seat ticket={ticket} />
        <span className="sep">{'//'}</span>
      </span>
    ) : (
      false
    );

    return (
      <div
        className={className.join(' ')}
        onTouchStart={this.toggleDetails}
        onClick={this.toggleDetails}
      >
        <div className="search-results-info-col">
          <h5 className="ticket-holder">
            {name}
            <a href="#toggle-status" className="status-icon">
              {statusIcon}
            </a>
            <span className="ticket-number">{ticket.ticketNumber}</span>
          </h5>

          <div className="ticket-info">
            {this.renderOccurrence(ticket)}
            <p>
              {seat}
              {this.props.ticket.itemTitle}
              <span className="sep">{'//'}</span>
              <span className="event-name">{ticket.eventTitle}</span>

              <TicketBadges ticket={ticket} />
            </p>

            {this.renderAnswers(ticket)}

            <CheckInComments ticket={ticket} />
          </div>

          {this.renderDetails()}
        </div>
        <div className="search-results-btn-col">{button}</div>
      </div>
    );
  }
}
