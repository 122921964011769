import React from 'react';
import { reportError } from './lib/errorReporting';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Record<string, unknown>, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    console.error(error);
    this.setState({ hasError: true });
    reportError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          Sorry, something went wrong. Please refresh and try again. If the problem keeps happening
          then email us at <a href="mailto:support@tickit.ca">support@tickit.ca</a> and we’ll get
          things fixed up.
        </p>
      );
    }
    return <>{this.props.children}</>;
  }
}
