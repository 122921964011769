import cloneDeep from 'lodash/cloneDeep';
import { IItem } from 'shared/items';
import { ILineItem, IOrder, CartDataSeat } from 'shared/orders';
import getOrderItemQuantity from './getOrderItemQuantity';
// import { debugLineItem, debugItemPrice, debugTotalsByCurrency } from '../lib/debug';

/**
 * Updates the quantity (only) of a line item in an order. Price calculations
 * are handled elsewhere.
 *
 * IMMUTABLE
 */
const updateOrderLineItem = (
  rawOrder: IOrder,
  item: IItem,
  quantity: number,
  seats?: {
    [key: string]: CartDataSeat;
  },
): IOrder => {
  const order: IOrder = { ...rawOrder };
  const lineItem = {
    item: { ...item },
    itemID: item.itemID,
    quantity: quantity,
    currency: undefined,
    seats: seats,
    delivery: order.hardTicketDelivery ? 'hard_tickets' : 'digital',
  } as ILineItem;

  order.lineItems = cloneDeep(order.lineItems || {});

  //   lineItem.quantity = quantity;
  if (lineItem.quantity <= 0) {
    delete order.lineItems[item.itemID];
  } else {
    order.lineItems[item.itemID] = lineItem;
  }

  // TODO QTY AVAILABLE ERRORS
  order.ticketQuantity = getOrderItemQuantity(order);
  return order;
};

export default updateOrderLineItem;
