import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { IScan, QuestionWithAnswer } from './scans';

export interface TicketFGL {
  [key: string]: string;
}

export interface TicketAnswer {
  [key: string]: string;
}

export interface ITicket {
  ticketNumber: string;
  tags: string[];
  qa?: TicketAnswer[];
  answers?: QuestionWithAnswer[];
  guestList: boolean;
  deleted: boolean;
  deletedReason?: string;
  orderID: string;
  itemID: string;
  ticketHolder: string;
  purchaserName?: string;
  organization: string;
  emails: string[];
  checkInComments: string;
  scan?: IScan;
  admitted?: boolean;
  details?: string[];
  seatSection?: string;
  seatRow?: string;
  seatNumber?: string;
  hardTicket: boolean;
  showAdmitButton?: boolean;
  unlimitedCheckins: boolean;
  itemTitle?: string;
  eventTitle?: string;
  eventID?: string;
  occurrenceID?: string;
  occurrenceEntireDay?: boolean;
  occurrenceStartsAt?: number;
  fgl?: TicketFGL;
  cacheKey?: string;
}

const hasSamePurchaserAndTicketHolder = (ticket: ITicket) => {
  if (!ticket.purchaserName) return true;
  return ticket.ticketHolder === ticket.purchaserName;
};

const generateTicketCacheKey = (ticket: ITicket) => {
  const scanUUID = ticket.scan ? ticket.scan.uuid : 'not-admitted';
  return `${ticket.ticketNumber}-${scanUUID}-${ticket.deleted ? 'deleted' : 'not-deleted'}`;
};

// whether to also print the event title when rendering a ticket
const ticketHasDifferentEventTitle = (ticket: ITicket) => {
  return !isEmpty(ticket.eventTitle) && !isEqual(ticket.itemTitle, ticket.eventTitle);
};

// dexie schema - https://dexie.org/docs/Version/Version.stores()
// only fields that should be indexed need to be defined here
const TicketSchema = {
  name: 'Ticket',
  primaryKey: 'ticketNumber',
  properties: {
    ticketNumber: 'string',
    tags: 'string?[]',
    guestList: 'bool',
    deleted: 'bool',
    deletedReason: 'string?',
    itemID: 'string',
    orderID: 'string?',
    ticketHolder: 'string?',
    purchaserName: 'string',
    organization: 'string?',
    seatSection: 'string?',
    seatRow: 'string?',
    seatNumber: 'string?',
    emails: 'string?[]',
    checkInComments: 'string?',
    hardTicket: 'bool',
    unlimitedCheckins: 'bool',
  },
};

export {
  hasSamePurchaserAndTicketHolder,
  TicketSchema,
  generateTicketCacheKey,
  ticketHasDifferentEventTitle,
};
