import uniq from 'lodash/uniq';
import TrieSearch from 'trie-search';
import { ITicketsState } from '../ticketsReducer';

const NAME_FIELDS = ['ticketHolder', 'purchaserName', 'organization'];

type TicketNumbers = Array<string>;

let trieNameSearch, trieEmailSearch;

function saveSearchIndexes(newTickets: ITicketsState): void {
  Object.values(newTickets).forEach(ticket => {
    ticket.emails.forEach(email => {
      if (typeof email !== 'string') return;
      trieEmailSearch.add({ email: email, ticketNumber: ticket.ticketNumber });
    });

    //  we don't allow searching deleted tickets by name
    if (ticket.deleted) return;
    NAME_FIELDS.forEach(nameField => {
      const name = ticket[nameField];
      if (typeof name !== 'string') return;
      trieNameSearch.add({ name: name, ticketNumber: ticket.ticketNumber });
    });
  });
}

function resetSearchIndexes(): void {
  trieNameSearch = new TrieSearch('name', {
    indexField: 'ticketNumber',
    ignoreCase: true,
    min: 2,
    splitOnRegEx: /[\s,]+/g,
  });

  trieEmailSearch = new TrieSearch('email', {
    indexField: 'ticketNumber',
    ignoreCase: true,
    min: 2,
  });
}

function searchByName(searchTerms: string): TicketNumbers {
  // if the user has only typed in a few characters we return fewer results
  // to reduce the amount of rendering we need to do - once they've typed in
  // more we can return more results
  const limit = searchTerms.length > 2 ? 100 : 40;

  return uniq(
    trieNameSearch
      .get(searchTerms)
      .slice(0, limit)
      .map(result => result.ticketNumber),
  );
}

function searchByEmail(searchTerms: string): TicketNumbers {
  return uniq(trieEmailSearch.get(searchTerms).map(result => result.ticketNumber));
}

resetSearchIndexes();

export {
  trieNameSearch,
  trieEmailSearch,
  saveSearchIndexes,
  resetSearchIndexes,
  searchByName,
  searchByEmail,
};
