import { formatDistanceToNow, format } from 'date-fns';
import { ITicket } from './Ticket';

type OccurrenceFields = Pick<ITicket, 'occurrenceEntireDay' | 'occurrenceStartsAt'>;

type PermissiveTimestamp = number | Date | null | undefined;

// the JSON methods here are used for both the local asyncstorage store and
// for the web API (both HTTP and websockets)
const TickitUtils = {
  ticketNumberRegex: /^#?[0-9]+-[0-9]+-[0-9]+-[A-Za-z]+/,

  isTicketNumber(str): boolean {
    if (!str) {
      return false;
    }

    return this.ticketNumberRegex.test(str);
  },

  formattedOccurrence(ticket: OccurrenceFields): string {
    const { occurrenceStartsAt } = ticket;
    if (!occurrenceStartsAt) return '';

    if (ticket.occurrenceEntireDay) {
      return format(occurrenceStartsAt, 'EEEE, MMM d');
    } else {
      return format(occurrenceStartsAt, "EEEE, MMM d 'at' h:mmaaaaa'm'");
    }
  },

  condensedFormattedTimestamp(timestamp: PermissiveTimestamp): string {
    if (!timestamp) return 'Never';

    const today = new Date();
    const date = new Date(timestamp);

    // TODO: this should be removed once dates are stored in Redux as timestamps
    // see https://github.com/alexdunae/tickit-mobile/issues/221
    if (date.getTime() === 0) return 'Never';

    const strfmt = today.toDateString() === date.toDateString() ? "h:mm:ssaaaaa'm'" : 'MMM d yyyy';

    return format(date, strfmt);
  },

  formattedOccurrenceDate(timestamp: PermissiveTimestamp): string {
    if (!timestamp) return 'Unknown';

    const date = new Date(timestamp);

    // TODO: this should be removed once dates are stored in Redux as timestamps
    // see https://github.com/alexdunae/tickit-mobile/issues/221
    if (date.getTime() === 0) return 'Never';

    const strfmt = 'EEEE, MMM d, yyyy';
    return format(date, strfmt);
  },

  formattedTimestamp(timestamp: PermissiveTimestamp, showSeconds = false): string {
    if (!timestamp) return 'Never';

    const date = new Date(timestamp);

    // TODO: this should be removed once dates are stored in Redux as timestamps
    // see https://github.com/alexdunae/tickit-mobile/issues/221
    if (date.getTime() === 0) return 'Never';

    const strfmt = showSeconds ? "MMM d yyyy 'at' h:mm:ssaaaaa'm'" : "MMM d yyyy 'at' h:mmaaaaa'm'";
    return format(date, strfmt);
  },

  formattedTimeOnly(timestamp: PermissiveTimestamp): string {
    if (!timestamp) return 'Never';

    const date = new Date(timestamp);

    // TODO: this should be removed once dates are stored in Redux as timestamps
    // see https://github.com/alexdunae/tickit-mobile/issues/221
    if (date.getTime() === 0) return 'Never';

    return format(date, "h:mmaaaaa'm'");
  },

  timestampAgo(timestamp: PermissiveTimestamp): string {
    if (!timestamp) return 'Never';

    const date = new Date(timestamp);

    // TODO: this should be removed once dates are stored in Redux as timestamps
    // see https://github.com/alexdunae/tickit-mobile/issues/221
    if (date.getTime() === 0) return 'Never';

    return formatDistanceToNow(date, { addSuffix: true });
  },

  formattedNumber(number: number | undefined): number {
    return number || 0;
  },

  normalizeTicketNumber(ticketNumber: string | undefined): string {
    if (!ticketNumber) {
      return '';
    }

    return ticketNumber
      .toUpperCase()
      .replace(/[^A-Z0-9-]/g, '')
      .replace(/^[0]*/, '');
  },

  formattedCurrency(cents): string {
    let dollars = cents.toUnit ? cents.toUnit : cents / 100.0;
    if (isNaN(dollars)) {
      dollars = 0;
    }
    return `$${dollars.toFixed(2)}`;
  },

  // replace preceding zeros in an IP address -- needed since Boca can give us
  // addressess like 192.168.000.035
  normalizeIPAddress(ipAddress: string | undefined): string {
    // return ipAddress.trim().replace(/(\.0{0,2})/gm, '.');
    if (!ipAddress) return '';
    const parts = ipAddress.trim().match(/(\d+)/gm);

    if (!parts || parts.length !== 4) {
      throw new Error(`Invalid IP address: ${ipAddress}`);
    }

    return parts
      .map(part => {
        part = part.replace(/^(0)+/, '');
        if (part.length === 0) part = '0';
        return part;
      })
      .join('.');
  },
};

export default TickitUtils;
