// import { reportError } from '../lib/errorReporting';
import { createReducer } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import * as actions from '../actions';
import * as constants from '../constants';
import { IScan, ScansByTicketNumber } from '../scans';
import { isTestTicketNumber } from '../testTickets';
const ADMIT_TIMESTAMP_THRESHOLD = 60 * 60 * 1000;

// how many recent scans to store
export const SCAN_HISTORY_LENGTH = 20;

export interface IScansState {
  scansByUUID: {
    [key: string]: IScan;
  };
  countsByItem: {
    [key: string]: number;
  };
  countsByItemAndShift: {
    [key: string]: {
      [key: string]: number;
    };
  };
  currentScan: IScan | undefined;
  scansByTicketNumber: {
    [key: string]: IScan;
  };
  unsyncedUUIDs: Array<string>;
  deviceAdmitTimestamps: number[];
  totalDeviceScanCount: number;
  scanHistory: Array<IScan>;
  scannerID: string; // hardcoded device scanner ID
}

export const INITIAL_STATE: IScansState = {
  scansByUUID: {}, // key is the UUID
  countsByItem: {}, // key is the itemID
  countsByItemAndShift: {}, // key is the shiftID, subkey is the itemID
  currentScan: undefined,
  scansByTicketNumber: {}, // key is the ticketNumber
  unsyncedUUIDs: [],

  // timestamps for every admission made by this device
  // used to generate admits per hour stats
  deviceAdmitTimestamps: [],
  totalDeviceScanCount: 0,

  scannerID: '',

  // the most recent scans performed by this device
  scanHistory: [],
};

function markScansAsSynced(state: IScansState, uuids: string[] | undefined): void {
  if (!uuids || uuids.length < 1) return;

  uuids.forEach(uuid => {
    const scan = { ...state.scansByUUID[uuid] };

    if (scan && !scan.synced) {
      scan.synced = true;
      state.scansByUUID[uuid] = scan;
    }

    // TODO: do indexes
    const index = uuids.findIndex(u => u === uuid);
    if (index < 0) return;
    state.unsyncedUUIDs.splice(index, 1);
  });
}

// we only ignore scans if we already have counted an earlier scan for the
// same ticket number
function determineSingleScanCount(
  scansByTicketNumber: ScansByTicketNumber,
  newScan: IScan,
  unlimitedCheckins: boolean,
): number {
  const previousScan = scansByTicketNumber[newScan.ticketNumber];
  const isNewScanOlder = previousScan && newScan.scannedAt < previousScan.scannedAt;

  // store the latest scan for a ticket, even if the ticket is missing
  if (!isNewScanOlder) {
    scansByTicketNumber[newScan.ticketNumber] = newScan;
  }

  if (unlimitedCheckins) return 1;

  // older single scan that will already have been accounted for
  if (isNewScanOlder) return 0;

  // guard against edge case where the first scan is a reversal
  if (!previousScan) {
    return newScan.reversal ? 0 : 1;
  }

  // guard against edge case where we have two reversals in a row
  if (newScan.reversal) {
    return previousScan.reversal ? 0 : -1;
  }

  // guard against edge case where we have to admissions in a row
  return previousScan.reversal ? 1 : 0;
}

function loadScans(
  state: IScansState,
  newScans: Array<IScan>,
  newCurrentScan: IScan | undefined = undefined,
): void {
  const admitTimestampThreshold = Date.now() - ADMIT_TIMESTAMP_THRESHOLD;

  let changed = false;

  if (newCurrentScan) {
    state.currentScan = newCurrentScan;
    changed = true;
  }

  try {
    newScans.forEach(scan => {
      if (!scan.valid) return;
      const itemID = scan.itemID;

      if (!itemID) {
        console.warn('Mising itemID for scan', scan);
        return;
      }

      // if (!scanningItemIDs.includes(itemID)) {
      //   return;
      // }
      const unlimitedCheckins = scan.unlimitedCheckins;

      if (isTestTicketNumber(scan.ticketNumber)) {
        console.debug('loadScans ignoring test ticket', scan.ticketNumber);
        return;
      }

      if (state.scansByUUID[scan.uuid]) {
        const existingScan = state.scansByUUID[scan.uuid];
        if (scan.synced && !existingScan.synced) {
          // scan was recently synced so update but do not recalculate any counts
          changed = true;
          state.scansByUUID[scan.uuid].synced = true;
        }
      } else {
        // we've never seen this scan before
        changed = true;

        const scanCount = determineSingleScanCount(
          state.scansByTicketNumber,
          scan,
          unlimitedCheckins,
        );
        if (scanCount !== 0) {
          const existingItemCount = state.countsByItem[itemID] || 0;
          state.countsByItem[itemID] = existingItemCount + scanCount;

          const scanShiftID = scan.shift;

          if (!state.countsByItemAndShift[scanShiftID]) {
            state.countsByItemAndShift[scanShiftID] = {};
          }

          const existingShiftItemCount = state.countsByItemAndShift[scanShiftID][itemID] || 0;
          state.countsByItemAndShift[scanShiftID][itemID] = existingShiftItemCount + scanCount;

          if (scan.scannerID === state.scannerID && !scan.reversal) {
            state.totalDeviceScanCount += 1;
            if (scan.scannedAt > admitTimestampThreshold) {
              state.deviceAdmitTimestamps.push(scan.scannedAt);
            }
          }
        }

        // TODO: do we want to do this when we’re restoring? the later sort
        // by timestamp could by expensive
        if (scan.scannerID === state.scannerID) {
          state.scanHistory.push(scan);
        }

        state.scansByUUID[scan.uuid] = scan;
      }

      if (scan.synced) {
        const index = state.unsyncedUUIDs.findIndex(u => u === scan.uuid);
        if (index >= 0) {
          state.unsyncedUUIDs.splice(index, 1);
        }
      } else {
        state.unsyncedUUIDs.push(scan.uuid);
      }
    });
  } catch (error) {
    console.error(error);
  }

  // trim out deviceAdmitTimestamps that are too old
  let newStartingTimestamp = 0;
  for (let i = 0; i < state.deviceAdmitTimestamps.length; i++) {
    if (state.deviceAdmitTimestamps[i] < admitTimestampThreshold) {
      newStartingTimestamp = i;
      changed = true;
    } else {
      break;
    }
  }

  if (changed) {
    state.deviceAdmitTimestamps = state.deviceAdmitTimestamps.slice(newStartingTimestamp).sort();
    state.scanHistory = sortBy(state.scanHistory, s => s.scannedAt * -1).slice(
      0,
      SCAN_HISTORY_LENGTH,
    );
  }
}
function recordScan(state: IScansState, scan: IScan, showOnScanScreen = false): void {
  loadScans(state, [scan], showOnScanScreen ? scan : undefined);
}

function resetStore(preservedState: Partial<IScansState>): IScansState {
  return { ...INITIAL_STATE, ...preservedState };
}

export const scansReducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(actions.recordScan, (state, action) => {
    recordScan(state, action.payload, action.meta.showOnScanScreen);
  });

  builder.addCase(actions.loadScans, (state, action) => {
    loadScans(state, action.payload.scans);
  });

  builder.addCase(constants.CLEAR_CURRENT_SCAN, state => {
    state.currentScan = undefined;
  });

  builder.addCase(actions.markScansAsSynced, (state, action) => {
    return markScansAsSynced(state, action.payload);
  });

  builder.addCase(actions.updateSettings, (state, action) => {
    if (action.payload.settings && action.payload.settings.scannerID) {
      state.scannerID = action.payload.settings.scannerID;
    }
  });

  builder.addCase(actions.defineManifest, state => {
    const preserved = { scannerID: state.scannerID };
    return resetStore(preserved);
  });

  // left over scans should have been synced before calling this (e.g. via `clearAllData`)
  builder.addCase(actions.purge, state => {
    const preserved = { scannerID: state.scannerID };
    return resetStore(preserved);
  });
});
