/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';

import success from './audio/checkin-scan-success.mp3';
import error from './audio/checkin-scan-error.mp3';

export enum Sounds {
  Success = 'successSound',
  Error = 'errorSound',
}

export default class Audio extends React.Component {
  successSound: React.RefObject<HTMLAudioElement>;
  errorSound: React.RefObject<HTMLAudioElement>;

  constructor(props) {
    super(props);
    this.successSound = React.createRef();
    this.errorSound = React.createRef();
  }

  play(soundKey: Sounds) {
    let audioElement: HTMLAudioElement | null;
    switch (soundKey) {
      case Sounds.Success:
        audioElement = this.successSound.current;
        break;
      case Sounds.Error:
        audioElement = this.errorSound.current;
        break;
      default:
        throw new Error(`Unknown soundKey: ${soundKey}`);
    }

    if (!audioElement) {
      console.warn('missing audioElement ref');
      return false;
    }
    audioElement.currentTime = 0;
    audioElement.play();
    return true;
  }

  render() {
    return (
      <div>
        <audio
          preload="auto"
          id="checkin-scan-success-sound"
          src={success}
          ref={this.successSound}
        />
        <audio preload="auto" id="checkin-scan-error-sound" src={error} ref={this.errorSound} />
      </div>
    );
  }
}
