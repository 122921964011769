import { createSelector } from '@reduxjs/toolkit';
import trim from 'lodash/trim';
import addTicketTypeToTicket from '../addTicketTypeToTicket';
import { getQuestions } from '../getAnswersForTicket';
import { IItem } from '../items';
import { RootState } from '../rootReducer';
import { IScan } from '../scans';
import { generateSearchResult } from '../search';
import { searchByEmail, searchByName } from '../search/trieSearch';
import { IQuestion } from '../settingsReducer';
import { ITicket } from '../Ticket';
import { ITicketsState } from '../ticketsReducer';
import { getTicketTypes } from '../ticketTypeSelector';
import TickitUtils from '../Utils';

const LIMIT = 200;
const ORDER_OR_TICKET_REGEX = new RegExp(/[#]?[0-9]{4,}(-|$)/);

const getTickets = (state: RootState) => state.tickets;
const getScanningItemIDs = (state: RootState) => state.settings.scanningItemIDs;
const getScansByTicketNumber = (state: RootState) => state.scans.scansByTicketNumber;
const getSearchTerms = (state: RootState) => state.search.searchTerms;

type ScansByTicketNumber = {
  [key: string]: IScan;
};

type TicketNumbers = Array<string>;

function searchByOrderOrTicketNumber(tickets: ITicketsState, searchTerms): TicketNumbers {
  searchTerms = TickitUtils.normalizeTicketNumber(searchTerms);

  // add a hyphen if the keywords don't contain one, since we're trying to match
  // by order number
  if (searchTerms.indexOf('-') === -1) searchTerms = `${searchTerms}-`;

  // we must match exactly from the beginning
  return Object.keys(tickets).filter(ticketNumber => {
    return ticketNumber.indexOf(searchTerms) === 0;
  });
}

function decorateSearchResults(
  ticketNumbers: TicketNumbers,
  ticketTypes: Map<string, IItem>,
  tickets: ITicketsState,
  scanningItemIDs: Array<string>,
  scans: ScansByTicketNumber,
  questions: {
    [key: string]: IQuestion;
  },
): Array<ITicket> {
  const decoratedTickets: Array<ITicket> = [];
  ticketNumbers.forEach(ticketNumber => {
    const ticket = tickets[ticketNumber];

    if (!ticket) {
      console.warn(`Missing ticket ${ticketNumber} even though it was in the search results`);
      return;
    }

    // not scannable (likely bought via point of sale)
    if (!ticket.itemID || !scanningItemIDs.includes(ticket.itemID)) return;

    const ticketWithItem = addTicketTypeToTicket(ticket, ticketTypes);
    ticketWithItem.showAdmitButton = true;

    const scan = scans[ticketNumber];
    decoratedTickets.push(generateSearchResult(ticketWithItem, scan, questions));
  });

  return decoratedTickets;
}

function sortTickets(tickets: Array<ITicket>): Array<ITicket> {
  tickets.sort((a: ITicket, b: ITicket) => {
    const orderIDSort = a.orderID.localeCompare(b.orderID);
    if (orderIDSort != 0) return orderIDSort;

    return a.ticketNumber.localeCompare(b.ticketNumber);
  });
  return tickets;
}

/**
 * This is the main interface for searching tickets by keyword or ticket number.
 *
 * It combines tickets, ticket types (items) and scans and then filters by the
 * searchReducer's `searchTerms`.
 */
const getSearchResults = createSelector(
  [
    getTickets,
    getScanningItemIDs,
    getTicketTypes,
    getScansByTicketNumber,
    getSearchTerms,
    getQuestions,
  ],
  (
    tickets: ITicketsState,
    scanningItemIDs: Array<string>,
    ticketTypes: Map<string, IItem>,
    scans: ScansByTicketNumber,
    rawSearchTerms: string,
    questions: {
      [key: string]: IQuestion;
    },
  ): Array<ITicket> => {
    const searchTerms: string = trim(rawSearchTerms.slice(0)).toLowerCase();

    // console.debug('getSearchResults', searchTerms);

    const termsLength = searchTerms.length;
    if (termsLength < 2 || termsLength > 150) {
      return [];
    }

    let ticketNumbers: TicketNumbers = [];

    // TODO
    // if (searchTerms.indexOf(CONFIG_URL_SCHEME) === 0) {
    // }

    if (searchTerms.indexOf('@') > -1) {
      // email search
      ticketNumbers = searchByEmail(searchTerms);
    } else if (ORDER_OR_TICKET_REGEX.test(searchTerms)) {
      // ticket number or order number search
      ticketNumbers = searchByOrderOrTicketNumber(tickets, searchTerms);
    } else {
      // name search
      ticketNumbers = searchByName(searchTerms);
    }

    ticketNumbers = ticketNumbers.slice(0, LIMIT);

    // TODO: 2019
    // logEvent("search", { searchTerms, results: ticketNumbers.length });

    // no results
    if (ticketNumbers.length < 1) {
      return [];
    }

    const decorated = decorateSearchResults(
      ticketNumbers,
      ticketTypes,
      tickets,
      scanningItemIDs,
      scans,
      questions,
    );
    return sortTickets(decorated);
  },
);

export default getSearchResults;
