// these errors do not need to be reported to an error reporting service
export class TickitConnectionError extends Error {
  url: string;
  requestHeaders: any;

  constructor(message, url, requestHeaders) {
    super(message);
    this.name = 'TickitConnectionError';
    this.url = url;
    this.requestHeaders = requestHeaders;
  }

  toString() {
    return `${this.name}: ${this.message}`;
  }
}

export const ERROR_MESSAGES = {
  '0': 'Unable to connect to the Tickit servers – are you connected to the internet?',
  '400': 'Incorrect request format – does the checkin app need updating?',
  '401': 'Incorrect store ID or secret key',
  '500': 'Error connecting to the Tickit servers',
};

export const API_URL = 'https://api.tickit.ca';

export interface RequestHeaders {
  Accept: string;
  'Content-type': string;
  'User-Agent'?: string;
  Authorization: string;
}

/**
 * ReactNative NativeModule interface
 */
export interface TickitNetInterface {
  fetchJSON: (endpoint: string, requestHeaders: RequestHeaders) => Promise<object>;
  postJSON: (endpoint: string, requestHeaders: RequestHeaders, jsonBody: object) => Promise<object>;
}
