import React from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { connect } from 'react-redux';
import { getScanHistory } from 'shared/scans';
import { SearchResultRow } from './SearchResultRow';

type Props = {
  onClose: () => void;
  scanHistory: any[];
};
class ScanHistory extends React.Component<Props> {
  onClose = event => {
    if (event) event.preventDefault();
    this.props.onClose();
  };

  renderBody() {
    const { scanHistory } = this.props;
    if (scanHistory.length < 1) {
      return <p>There haven’t been any scans recorded on this device yet.</p>;
    }

    const tickets = scanHistory.map(ticket => this.renderTicket(ticket));
    return <div>{tickets}</div>;
  }

  renderTicket(ticket: any) {
    return (
      <SearchResultRow
        key={ticket.cacheKey}
        ticket={ticket}
        staticStatus={ticket.scan && ticket.scan.reversal ? 'Reversed' : 'Admitted'}
        selectInput={() => {
          console.log('avoiding lint error - selectInput');
        }}
        onValidate={() => {
          console.log('avoiding lint error - onValidate');
        }}
      />
    );
  }

  render() {
    const classNames = ['overlay-panel', 'scan-history-panel', 'visible'];

    return (
      <div className={classNames.join(' ')}>
        <ErrorBoundary>
          <button
            className="overlay-close-btn"
            onTouchStart={this.props.onClose}
            onClick={this.props.onClose}
            aria-label="Close Stats"
          >
            <i className="icon icon-close" />
          </button>
          <div className="overlay-content">
            <div className="overlay-header">
              <h3 className="overlay-header-title">Scan History</h3>
            </div>
            <div className="overlay-content">{this.renderBody()}</div>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scanHistory: getScanHistory(state),
  };
};

export default connect(mapStateToProps)(ScanHistory);
