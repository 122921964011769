import Dexie from 'dexie';
import debounce from 'lodash/debounce';
import { debugTiming } from 'shared/debug';
import { IOrder } from 'shared/orders';
import { IPersister } from 'shared/rootReducer';
import { IScan } from 'shared/scans';
import { ITicket } from 'shared/Ticket';
import { ITicketsState } from 'shared/ticketsReducer';

const SettingsSchema = '&key';
const TicketSchema = '&ticketNumber';
const ScanShema = '&uuid';

const SETTINGS_DEBOUNCE_DURATION = 1500;

const SCHEMA_VERSION = 1;

export class TickitDatabase extends Dexie {
  settings: Dexie.Table<SettingsJSONBlob, string>;
  tickets: Dexie.Table<ITicket, string>;
  scans: Dexie.Table<IScan, string>;

  constructor() {
    super('TickitCheckin');
    this.version(SCHEMA_VERSION).stores({
      settings: SettingsSchema,
      tickets: TicketSchema,
      scans: ScanShema,
    });

    // The following line is needed if your typescript
    // is compiled using babel instead of tsc:
    this.settings = this.table('settings');
    this.tickets = this.table('tickets');
    this.scans = this.table('scans');
  }
}

interface SettingsJSONBlob {
  key: string;
  settings: string;
}

export const db = new TickitDatabase();
// db.version(SCHEMA_VERSION).stores({
//   settings: SettingsSchema,
//   tickets: TicketSchema,
//   scans: ScanShema
// });

export function purge(): Promise<void> {
  const ts = Date.now();

  return db.delete().then(() => {
    debugTiming('persist.purge', ts);
  });
}

// we don't need to this for every tiny change
const debouncedPersistSettings = debounce(
  (settingsJSON: string) => {
    const ts = Date.now();

    db.open().then(db => {
      (db as TickitDatabase).settings
        .put({
          key: 'settings',
          settings: settingsJSON,
        })
        .then(() => {
          debugTiming('persistMiddleware.persistSettings', ts);
        });
    });
  },
  SETTINGS_DEBOUNCE_DURATION,
  { trailing: true },
);

function persistSettings(settingsJSON: string): Promise<void> {
  debouncedPersistSettings(settingsJSON);
  return Promise.resolve();
}

function persistTickets(tickets: ITicketsState): Promise<void> {
  const ts = Date.now();
  const ticketsToPersist = Object.values(tickets);

  if (ticketsToPersist.length < 1) {
    console.warn('persistTickets could not lookup any tickets', ticketsToPersist);
    return Promise.resolve();
  }

  return db.open().then(db => {
    return (db as TickitDatabase).tickets.bulkPut(ticketsToPersist).then(() => {
      debugTiming(`persistMiddleware.persistTickets (${ticketsToPersist.length})`, ts);
    });
  });
}

function persistScans(scans: IScan[]): Promise<void> {
  if (scans.length < 1) return Promise.resolve();

  const ts = Date.now();

  return db.open().then(db => {
    return (db as TickitDatabase).scans.bulkPut(scans).then(() => {
      debugTiming(`persistMiddleware.persistScans (${scans.length})`, ts);
    });
  });
}

export function persistOrders(orders: IOrder[]): Promise<void> {
  return Promise.reject(new Error('Orders are not supported on web checkin'));
}

const persister: IPersister = {
  purge,
  persistScans,
  persistTickets,
  persistSettings,
  persistOrders,
};

export { persister };
