import throttle from 'lodash/throttle';
import React from 'react';
import { connect } from 'react-redux';
import { search } from 'shared/actions';
import { getSearchResults, getSearchTerms } from 'shared/search';
import { processScan } from 'shared/thunks';
// import { search } from 'shared/actions';
// import { processScan } from 'shared/thunks'
import { ITicket } from 'shared/Ticket';
import { ScanForm } from './ScanForm';
import { SearchResults } from './SearchResults';

interface Props {
  search: typeof search;
  searchTerms: string;
  searchResults: Array<ITicket>;
  processScan: typeof processScan;
  selectInput: () => void;
  toggleScanHistory: () => void;
}

class SearchContainer extends React.Component<Props> {
  mainContent: HTMLDivElement | null;
  throttledSearchTerms: string | undefined;
  throttledSearch: () => void;

  constructor(props) {
    super(props);
    this.mainContent = null;
    this.onInput = this.onInput.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.throttledSearch = throttle(
      () => {
        this.props.search(this.throttledSearchTerms);
      },
      250,
      { leading: true },
    );
  }

  onInput(query: string) {
    this.throttledSearchTerms = query;
    this.throttledSearch();
  }

  onValidate(ticketNumber: string, reversal = false) {
    this.props.processScan(ticketNumber, reversal);
    this.props.selectInput();
  }

  scrollToTop() {
    if (!this.mainContent)
      this.mainContent = document.getElementById('main-content') as HTMLDivElement;
    this.mainContent.scrollTop = 0;
    this.props.selectInput();
  }

  render() {
    return (
      <div className="main-content" ref={content => (this.mainContent = content)} id="main-content">
        <ScanForm
          onValidate={this.onValidate}
          onChange={this.onInput}
          selectInput={this.props.selectInput}
        />

        <SearchResults
          searchResults={this.props.searchResults}
          searchTerms={this.props.searchTerms}
          onValidate={this.onValidate}
          scrollToTop={this.scrollToTop}
          selectInput={this.props.selectInput}
          toggleScanHistory={this.props.toggleScanHistory}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchTerms: getSearchTerms(state),
    searchResults: getSearchResults(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    search: searchTerms => dispatch(search(searchTerms)),
    processScan: (ticketNumber, reversal = false) => dispatch(processScan(ticketNumber, reversal)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
