import { createReducer } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import * as actions from './actions';
import addTicketTypeToTicket from './addTicketTypeToTicket';
import { debugTiming } from './debug';
import { IItem } from './items';
import { resetSearchIndexes, saveSearchIndexes } from './search/trieSearch';
import { ITicket } from './Ticket';
import { getTicketTypes } from './ticketTypeSelector';

// we keep counts outside the reducer to avoid any unnecessary re-renders
let countsByItem: Map<string, number> = new Map();
let countedTicketNumbers: Set<string> = new Set();

export interface ITicketsState {
  [key: string]: ITicket;
}

export const INITIAL_STATE: ITicketsState = {};

interface AddTickets {
  tickets: ITicketsState;
}

const PRESERVED_LOCAL_KEYS = ['orderID', 'fgl'];

// when we sell a ticket via the app the API returns the FGL fields for printing
// we want to make sure that any updates (i.e. via websocket or /tickets calls,
// which don't include FGL) preserves the FGL content
const preserveLocalOrderData = (oldTickets: ITicketsState, newTicket: ITicket): ITicket => {
  const oldTicket = oldTickets[newTicket.ticketNumber];
  if (!oldTicket) {
    return newTicket;
  }

  const mergedTicket = { ...newTicket };

  PRESERVED_LOCAL_KEYS.forEach(key => {
    if (isEmpty(mergedTicket[key]) && !isEmpty(oldTicket[key])) {
      mergedTicket[key] = oldTicket[key];
    }
  });

  return mergedTicket;
};

function loadTickets(state: ITicketsState, newTickets: ITicketsState): void {
  const ts = Date.now();

  Object.values(newTickets).forEach(ticket => {
    const { ticketNumber, itemID } = ticket;
    if (!itemID) return;

    const existingItemCount = countsByItem.get(itemID) || 0;
    const alreadyCounted = countedTicketNumbers.has(ticketNumber);

    try {
      if (alreadyCounted && ticket.deleted) {
        // decrement if a ticket becomes deleted after it has been counted
        countsByItem.set(itemID, existingItemCount - 1);
        countedTicketNumbers.delete(ticketNumber);
      } else if (!alreadyCounted && !ticket.deleted) {
        // increment if not counted and not deleted
        countsByItem.set(itemID, existingItemCount + 1);
        countedTicketNumbers.add(ticketNumber);
      }
    } catch (error) {
      console.error(error);
    }

    ticket = preserveLocalOrderData(state, ticket);

    state[ticketNumber] = ticket;
  });

  debugTiming('loadTickets.base', ts);

  saveSearchIndexes(newTickets);
  debugTiming('loadTickets.saveSearchIndexes', ts);
  countsByItem = new Map(countsByItem);
  countedTicketNumbers = new Set(countedTicketNumbers);
}

function resetStore(): ITicketsState {
  resetSearchIndexes();
  countsByItem = new Map();
  countedTicketNumbers = new Set();
  return {};
}

const getTicketCountsByItem = () => countsByItem;

const getOrderTickets = (state, orderID: string): Array<ITicket> => {
  const ticketTypes: Map<string, IItem> = getTicketTypes(state);
  const tickets: ITicketsState = state.tickets;

  return Object.values(tickets)
    .filter(ticket => {
      return ticket.orderID === orderID;
    })
    .map(ticket => {
      return addTicketTypeToTicket(ticket, ticketTypes);
    });
};

const ticketsReducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(actions.loadTickets, (state, action) => {
    loadTickets(state, action.payload.tickets);
  });

  builder.addCase(actions.defineManifest, resetStore);
  builder.addCase(actions.purge, resetStore);
});

export { ticketsReducer, getTicketCountsByItem, getOrderTickets };
