const printerStatuses = {
  noPrinter: 'noPrinter',
  connecting: 'connecting',
  connected: 'connected',
  notConnected: 'notConnected',
  errorConnecting: 'errorConnecting',
  notCapable: 'notCapable',
};

const printerStatusStrings = {
  noPrinter: '',
  connecting: 'Connecting...',
  connected: 'Your printer is ready to print',
  notConnected: 'Not connected',
  errorConnecting:
    'Unable to connect to your printer – be sure the printer is on and you’re connected to wi-fi',
};

export { printerStatuses, printerStatusStrings };
