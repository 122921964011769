import isEqual from 'lodash/isEqual';
import * as constants from './constants';
// TODO: 2019 import TickitDeviceInfo from '../lib/TickitDeviceInfo';

export interface IDeviceStatus {
  batteryPercent?: number;
  sledBatteryPercent?: number;
  pluggedIn?: boolean;
  sledConnected: boolean;
  sledPluggedIn?: boolean;
  showBatteryWarning: boolean;
  deviceType: string;
  lineaScannerCapable: boolean;
  hasBuiltinBarcodeReader: boolean;
  hasHeadphoneJack?: boolean;
  scannerName: string;
  scannerID: string;
  deviceBrand: string;
  deviceManufacturer: string;
  systemName: string;
  systemVersion: string;
  printerCapable: boolean;
  stripeTerminalCapable: boolean;
  readNFC: boolean;
  writeNFC: boolean;
  scannerUserAgent: string;
}

const INITIAL_STATE: IDeviceStatus = {
  batteryPercent: undefined,
  pluggedIn: false,
  sledBatteryPercent: undefined,
  sledConnected: false,
  sledPluggedIn: false,
  showBatteryWarning: false,
  deviceType: '',
  lineaScannerCapable: true,
  hasBuiltinBarcodeReader: false,
  hasHeadphoneJack: false,
  scannerName: '',
  scannerID: '',
  deviceBrand: '',
  deviceManufacturer: '',
  systemName: '',
  systemVersion: '',
  printerCapable: false,
  stripeTerminalCapable: true,
  readNFC: false,
  writeNFC: false,
  scannerUserAgent: '',
};

function updateDeviceStatus(
  initialState: IDeviceStatus,
  deviceStatus: Partial<IDeviceStatus>,
): IDeviceStatus {
  return Object.assign({}, initialState, deviceStatus) as IDeviceStatus;
}

function updateBattery(
  deviceStatus: IDeviceStatus,
  batteryPercent?: number,
  pluggedIn?: boolean,
): IDeviceStatus {
  if (
    isEqual(batteryPercent, deviceStatus.batteryPercent) &&
    isEqual(pluggedIn, deviceStatus.pluggedIn)
  ) {
    return deviceStatus;
  }

  const showBatteryWarning =
    batteryPercent !== undefined && batteryPercent !== null && batteryPercent < 5 && !pluggedIn;

  return Object.assign({}, deviceStatus, {
    batteryPercent,
    pluggedIn,
    showBatteryWarning,
  }) as IDeviceStatus;
}

// battery and diagnostic information to ping back to the server for health checks
const deviceStatus = (state = INITIAL_STATE, action): IDeviceStatus => {
  switch (action.type) {
    case constants.UPDATE_BATTERY: {
      return updateBattery(state, action.payload.batteryPercent, action.payload.pluggedIn);
    }

    case constants.UPDATE_DEVICE_STATUS: {
      return updateDeviceStatus(state, action.payload);
    }

    default: {
      return state;
    }
  }
};

export { deviceStatus };
