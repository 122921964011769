import React from 'react';

export interface ConnectivityContextState {
  isOnline: boolean;
  readyToScan: boolean;
  readyToSell: boolean;
  storeKey: string | undefined;
  hasCheckinPermission: boolean;
  hasGlobalStatsPermission: boolean;
  useHardwareBarcodeReader: boolean;
  storeCredentialsOK: boolean;
  printerCapable: boolean;
}

const INITIAL_STATE: ConnectivityContextState = {
  isOnline: false,
  readyToScan: false,
  readyToSell: false,
  storeKey: undefined,
  hasCheckinPermission: false,
  hasGlobalStatsPermission: false,
  useHardwareBarcodeReader: false,
  storeCredentialsOK: false,
  printerCapable: false,
};

/**
 * Handle rarely-changing connection status flags
 */
const ConnectivityContext = React.createContext<ConnectivityContextState>(INITIAL_STATE);

ConnectivityContext.displayName = 'ConnectivityContext';

export default ConnectivityContext;
