import Dinero from 'dinero.js';
import { monetizeItemPrice } from 'shared/items';
import { fiat, isTokenCurrency, tokens } from 'shared/money';
import { ILineItem, ILineItemWithPrices } from 'shared/orders';
/**
 * Add the price fields for the given currency to a lineItem, performing
 * all the calculations necesary to create the order balance.
 *
 * Used when building carts via the purchase flow.
 *
 * IMMUTABLE - returns a copy of the lineItem object.
 */
export default function addLineItemPrices(
  rawLineItem: ILineItem | ILineItemWithPrices,
  currency: string,
): Readonly<ILineItemWithPrices> {
  const monetize = isTokenCurrency(currency) ? tokens : fiat;
  const ZERO: Dinero.Dinero = monetize(0, currency);

  const lineItem: ILineItemWithPrices = Object.assign({}, rawLineItem, {
    currency: currency,
    basePrice: monetize(0, currency),
    extendedBasePrice: monetize(0, currency),
    taxable: monetize(0, currency),
    tickitFees: monetize(0, currency),
    tax: monetize(0, currency),
    displayFees: monetize(0, currency),
    subTotal: monetize(0, currency),
  });

  const item = lineItem.item;

  if (!item) {
    throw new Error(`lineItem.item is required`);
  }

  const itemPrice = monetizeItemPrice(item.prices[currency]);

  if (isTokenCurrency(currency) && itemPrice.fiat) {
    throw new Error(
      `${currency} is a token but itemPrice ${itemPrice.currency} has fiat=${itemPrice.fiat}`,
    );
  }

  if (!itemPrice) {
    throw new Error(
      `Missing itemPrice in currency "${currency}" in item.prices: ${Object.keys(item.prices).join(
        ',',
      )}`,
    );
  }

  // debugItemPrice(currency, itemPrice);
  // console.debug('doing FIAT', itemID, itemPrice.basePrice.toObject());
  // error guard

  lineItem.basePrice = itemPrice.basePrice;
  lineItem.extendedBasePrice = lineItem.basePrice.multiply(lineItem.quantity);

  lineItem.taxable = lineItem.basePrice.add(itemPrice.sellerFees).multiply(lineItem.quantity);

  lineItem.tickitFees = itemPrice.tickitFees
    ? itemPrice.tickitFees.multiply(lineItem.quantity)
    : ZERO;

  lineItem.tax = lineItem.taxable.multiply((itemPrice.taxPercent || 0.0) / 100);

  lineItem.displayFees = itemPrice.displayFees
    ? itemPrice.displayFees.multiply(lineItem.quantity)
    : ZERO;

  lineItem.subTotal = lineItem.taxable.add(lineItem.tax).add(lineItem.tickitFees);

  return lineItem;
}
