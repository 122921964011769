import React from 'react';
import { IEventStats, IItemStats } from 'shared/statsSelector';
import { Props as ParentProps } from './StatsContainer';

interface PanelProps extends ParentProps {
  toggleShiftStats: () => void;
  showShiftStats: boolean;
}

const StatsPanel: React.FunctionComponent<PanelProps> = props => {
  function onClose(event) {
    event.preventDefault();
    props.onClose();
  }

  function toggleShiftStats(event) {
    event.preventDefault();
    props.toggleShiftStats();
  }

  function renderOverview() {
    const { showShiftStats } = props;
    const { overview } = props.stats;
    const singleScanCount = showShiftStats ? overview.shiftScanCount : overview.singleScanCount;
    const multiScanCount = showShiftStats ? overview.shiftMultiScanCount : overview.multiScanCount;
    const shiftLabel = showShiftStats ? ' this shift' : '';
    let multiScan: React.ReactNode = false;

    if (multiScanCount > 0) {
      multiScan = <span> + {multiScanCount} multi-scans</span>;
    }

    return (
      <div className="stats-overview">
        <p>
          {singleScanCount} / {overview.singleScanTotal} admitted {shiftLabel}
          {multiScan}
        </p>
      </div>
    );
  }

  function renderShiftToggle() {
    if (!props.hasGlobalStatsPermission) return false;

    const shiftLabel = props.showShiftStats
      ? 'Show stats from all scanners'
      : 'Only show this shift?';

    return (
      <a
        href="#shift-stats"
        className="overlay-header-small-link"
        onTouchStart={toggleShiftStats}
        onClick={toggleShiftStats}
      >
        {shiftLabel}
      </a>
    );
  }

  const events = props.stats.events.map(eventObject => (
    <StatsPanelEvent
      event={eventObject}
      key={`stat-event${eventObject.eventID}`}
      showShiftStats={props.showShiftStats}
    />
  ));

  const classNames = ['overlay-panel', 'stats-panel', 'visible'];

  return (
    <div className={classNames.join(' ')}>
      <button
        className="overlay-close-btn"
        onTouchStart={onClose}
        onClick={onClose}
        aria-label="Close Stats"
      >
        <i className="icon icon-close" />
      </button>
      <div className="stats-panel-content overlay-content">
        <div className="overlay-header">
          <h3 className="overlay-header-title">Check-in Stats</h3>
          {renderShiftToggle()}
        </div>
        {renderOverview()}
        {events}
      </div>
    </div>
  );
};

interface EventProps {
  event: IEventStats;
  showShiftStats: boolean;
}

const StatsPanelEvent: React.FunctionComponent<EventProps> = props => {
  const items = props.event.data.map(itemObject => (
    <StatsPanelItem
      item={itemObject}
      key={`stat-item${itemObject.itemID}`}
      showShiftStats={props.showShiftStats}
    />
  ));

  return (
    <div className="stats-panel-event-list">
      <h4 className="stats-event-title">{props.event.eventTitle}</h4>
      <div className="stats-table">{items}</div>
    </div>
  );
};

interface ItemProps {
  item: IItemStats;
  showShiftStats: boolean;
}

const StatsPanelItem: React.FunctionComponent<ItemProps> = props => {
  const { item, showShiftStats } = props;

  function renderProgressBar() {
    const percentAdmitted = showShiftStats ? item.percentAdmittedInShift : item.percentAdmitted;
    const percentRemaining = 1 - percentAdmitted;

    let klass = 'stats-progress-bar';

    if (item.unlimitedCheckins) klass += ' multiscan';

    const barStyle = { flex: percentAdmitted };
    const remainingStyle = { flex: percentRemaining };

    return (
      <div className={klass}>
        <div className="bar" style={barStyle} />
        <div className="remaining" style={remainingStyle} />
      </div>
    );
  }

  const total = item.unlimitedCheckins ? '∞' : item.soldCount;
  const admitted = showShiftStats ? item.shiftScanCount : item.scanCount;

  return (
    <div>
      <div className="stats-row">
        <div className="stats-item">{item.itemTitle}</div>
        <div className="stats-count">
          {admitted} / {total}
        </div>
      </div>
      {renderProgressBar()}
    </div>
  );
};

export default StatsPanel;
