/*eslint @typescript-eslint/no-use-before-define: "off"*/

import {
  clearCurrentScan,
  defineManifest,
  loadScans,
  loadTickets,
  markScansAsSynced,
  markShiftsAsSynced,
  purge,
  updateSettings,
  resetCurrentOrderLocally,
} from 'shared/actions';
import { BackgroundPollingTimer } from 'shared/lib/BackgroundPollingTimer';
import { TickitConnectionError } from 'shared/lib/jsonRequest-helpers';
import {
  getConnectionSettings,
  SetupScreens,
  ConnectionSettings,
  getSyncSettings,
} from 'shared/settingsReducer';
import * as api from './api';
import { debugNetwork, debugStripeTerminal, debugTiming } from './debug';
import { deviceStatusSelector } from './deviceStatusSelector';
import { AppThunkAction } from './rootReducer';
import { getUnsyncedScans } from './scans';
import { getUnsyncedShifts } from './settingsReducer';
import { getCurrentOrder } from './orders';
import { PersistedShareableConfig } from './settings/shareableConfig';
import getShareableConfig from './settings/getShareableConfig';

/** Allow us to prevent more than one user-initiated sync requests at a time  */
let CURRENTLY_SYNCING = false;

const pollingTimer: BackgroundPollingTimer = globalThis.pollingTimer;
if (!pollingTimer) {
  throw new Error('Missing globalThis.pollingTimer - unable to poll');
}

console.debug(`pollingTimer.ready platform=${pollingTimer.platform}`);

const POLL_FREQUENCY_MS = 4 * 60 * 1000; // milliseconds

export const fetchStripeTerminalConnectionToken = function (): AppThunkAction<Promise<
  api.StripeTerminalConnectionTokenResponse
>> {
  debugStripeTerminal('syncActions fetch token');
  return function (dispatch, getState): Promise<api.StripeTerminalConnectionTokenResponse> {
    const settings = getConnectionSettings(getState());
    const { storeSlug, storeKey } = settings;

    debugStripeTerminal(
      `syncActions fetch token running!!! storeKey=${storeKey} storeSlug=${storeSlug}`,
    );
    return api.fetchStripeTerminalConnectionToken({ storeSlug, storeKey });
  };
};

export const postDeviceConfiguration = function (): AppThunkAction<Promise<
  PersistedShareableConfig
>> {
  return function (dispatch, getState) {
    const syncSettings = getSyncSettings(getState());
    const deviceConfiguration = getShareableConfig(getState());
    deviceConfiguration.userAgent = syncSettings.scannerUserAgent;
    return api.postDeviceConfiguration(syncSettings, deviceConfiguration);
  };
};

export const fetchDeviceConfiguration = function (
  connectionSettings: ConnectionSettings,
  deviceConfigurationID: string,
): AppThunkAction<Promise<PersistedShareableConfig>> {
  return async function () {
    return api.fetchDeviceConfiguration(connectionSettings, deviceConfigurationID);
  };
};

const fetchTickets = (): AppThunkAction => async (dispatch, getState) => {
  try {
    const ts = Date.now();
    const originalSettings = getState().settings;
    let cursor: string | undefined;

    do {
      const response = await api.fetchTickets(originalSettings, cursor);
      response.settings.lastTicketPull = new Date().getTime();
      cursor = response.pagination?.next_cursor;

      debugTiming('fetchTickets.download', ts);
      debugNetwork(
        `fetchTickets count=${Object.keys(response.tickets).length} nextCursor=${cursor}`,
      );
      dispatch(loadTickets(response.tickets, response.settings));
      debugTiming('fetchTickets.total', ts);
    } while (cursor);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const fetchScans = (): AppThunkAction => async (dispatch, getState) => {
  try {
    const ts = Date.now();
    const originalSettings = getState().settings;
    let cursor: string | undefined;

    do {
      const response = await api.fetchScans(originalSettings, cursor);
      response.settings.lastScanPull = new Date().getTime();
      cursor = response.pagination?.next_cursor;
      debugTiming('fetchScans.download', ts);
      debugNetwork(`fetchScans count=${response.scans.length} nextCursor=${cursor}`);
      dispatch(loadScans(response.scans, response.settings));
      debugTiming('fetchScans.total', ts);
    } while (cursor);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const pushScans = function (): AppThunkAction<Promise<void>> {
  return function (dispatch, getState) {
    try {
      const state = getState();
      const originalSettings = state.settings;
      const unsyncedScans = getUnsyncedScans(state);
      const unsyncedShifts = getUnsyncedShifts(state);
      const deviceStatus = deviceStatusSelector(state);

      if (unsyncedScans.size === 0 && unsyncedShifts.length === 0) {
        debugNetwork(`No scans or shifts to push`);
        return Promise.resolve();
      }

      debugNetwork(`Pushing scans and device status (${unsyncedScans.size} scans)`);
      // api.pushScans()
      // type response = { syncedUUIDs, syncedShiftDigests, settings }
      return api
        .pushScans(
          originalSettings,
          Array.from(unsyncedScans.values()),
          unsyncedShifts,
          deviceStatus,
        )
        .then(({ syncedUUIDs, syncedShiftDigests, settings }) => {
          if (syncedUUIDs.length > 0) {
            debugNetwork(`Synced scans (${syncedUUIDs.length})`);
            dispatch(markScansAsSynced(syncedUUIDs));
          }

          if (syncedShiftDigests.length > 0) {
            debugNetwork(`Synced shifts (${syncedShiftDigests.length})`);
            dispatch(markShiftsAsSynced(syncedShiftDigests));
          }

          if (settings.lastScanPost) {
            dispatch(updateSettings(settings));
          }
        })
        .catch(error => {
          console.error('pushScans', error);
        });
    } catch (error) {
      console.warn('pushScans handledError', error);
      return Promise.reject(`There was an error syncing your scans.`);
    }
  };
};

export const stopPolling = function (): AppThunkAction {
  return function () {
    debugNetwork('polling=stop');
    try {
      pollingTimer.stopPollingInterval();
    } catch (error) {
      console.debug(`polling=unable to stop`, error);
    }
  };
};

export const startPolling = function (): AppThunkAction {
  return function (dispatch) {
    (function queue() {
      dispatch(stopPolling());
      debugNetwork(`polling=queue`);
      pollingTimer.startPollingInterval(() => {
        debugNetwork('polling=start');
        dispatch(sync(false)).then(() => {
          debugNetwork('polling=done');
          queue();
        });
      }, POLL_FREQUENCY_MS);
    })();
  };
};

export const sync = (userInitiated = false): AppThunkAction<Promise<void>> => async dispatch => {
  debugNetwork(`sync userInitiated=${userInitiated} currentlySyncing=${CURRENTLY_SYNCING}`);
  if (CURRENTLY_SYNCING) {
    debugNetwork(`sync skipping`);
    return Promise.resolve();
  }

  CURRENTLY_SYNCING = true;

  try {
    const ts = Date.now();
    if (userInitiated) {
      dispatch(stopPolling());
    }

    dispatch(pushScans());

    return Promise.all([dispatch(fetchTickets()), dispatch(fetchScans())])
      .then(() => {
        debugTiming('sync', ts);
      })
      .catch(error => {
        if (error instanceof TickitConnectionError) {
          console.warn('sync', error.toString(), error.requestHeaders);
        } else {
          throw error;
        }
      })
      .finally(() => {
        CURRENTLY_SYNCING = false;
        if (userInitiated) {
          dispatch(startPolling());
        }
      });
  } catch (error) {
    console.error(error);
    CURRENTLY_SYNCING = false;
  }
};

/**
 * Reset all stores to their initial states. This attempts to push unsynced scans first and will fail if the push fails.
 */
export const clearAllData = (): AppThunkAction<Promise<void>> => async dispatch => {
  try {
    await dispatch(pushScans());
  } catch (error) {
    throw new Error(
      `There was an error syncing your scans before logging out. Please check your internet connection and try agian.`,
    );
  }
  await dispatch(resetCurrentOrderSync());
  dispatch(purge());
};

export const resetCurrentOrderSync = (): AppThunkAction<Promise<boolean>> => async (
  dispatch,
  getState,
) => {
  debugNetwork('resetCurrentOrderSync');
  const settings = getConnectionSettings(getState());
  const order = getCurrentOrder(getState(), {});
  const { storeSlug, storeKey } = settings;
  await api.releasePendingOrder({ storeSlug, storeKey }, order);
  dispatch(resetCurrentOrderLocally());
  return true;
};

/**
 * Open the 'connect to store' flow, fetching the current manifest list in the process
 */
export const openSetup = function (
  setupScreen: SetupScreens | undefined,
): AppThunkAction<Promise<void>> {
  // console.log('THUNK 1', setupScreen);
  return function (dispatch, getState) {
    debugNetwork(`openSetup setupScreen=${setupScreen}`);
    // console.log('THUNK 2', setupScreen);
    const settings = getConnectionSettings(getState());
    const { storeSlug, storeKey } = settings;

    // closing setup
    if (!setupScreen) {
      dispatch(updateSettings({ setupScreen: undefined, importingManifest: false }));
      return Promise.resolve();
    }

    return api
      .fetchManifests({ storeSlug, storeKey })
      .then(updatedSettings => {
        dispatch(
          updateSettings({
            ...updatedSettings,
            setupScreen: setupScreen || SetupScreens.LoadingManifest,
          }),
        );
        dispatch(clearCurrentScan());
      })
      .catch(error => {
        console.warn('openSetup handled error', error);
        return Promise.reject(error);
      });
  };
};

export type ValidatedCredentials = {
  storeSlug: string;
  storeKey: string;
};

/** Validate login credentials and call fetchManifests for the new store. Any unsynced scans are pushed first. */
export const connectToStore = (
  storeSlug: string,
  storeKey: string,
  isOffline: boolean | undefined = false,
): AppThunkAction<Promise<ValidatedCredentials>> => async dispatch => {
  try {
    await dispatch(pushScans());
  } catch (error) {
    console.warn('connectToStore.pushScans handledError', error);
    return Promise.reject(
      new Error(`Unable to push unsynced scans before re-connecting to your store.`),
    );
  }

  try {
    const updatedSettings = await api.fetchManifests({ storeSlug, storeKey });
    dispatch(updateSettings(updatedSettings));
    return { storeSlug: updatedSettings.storeSlug, storeKey: updatedSettings.storeKey };
  } catch (error) {
    if (isOffline) {
      return Promise.reject(
        new Error(
          `Unable to connect to Tickit. Please make sure you are connected to the internet and try again.`,
        ),
      );
    } else {
      return Promise.reject(
        new Error(
          `Unable to connect to Tickit. Please check your store ID and secret key and try again.`,
        ),
      );
    }
  }
};

export type ConfigureManifestParams = {
  scanningItemIDs?: Array<string | number>;
  sellingItemIDs?: Array<string | number>;
  scanningOccurrenceIDs?: Array<string>;
  sellingOccurrenceIDs?: Array<string>;
};

export const configureManifestAndImport = function ({
  scanningItemIDs = [],
  sellingItemIDs = [],
  scanningOccurrenceIDs = [],
  sellingOccurrenceIDs = [],
}: ConfigureManifestParams): AppThunkAction<Promise<void>> {
  return function (dispatch): Promise<void> {
    const ts = Date.now();
    try {
      dispatch(updateSettings({ importingManifest: true }));
      dispatch(stopPolling());

      // yield call(purgeStorage); // purging storage is async so we wait for it to resolve here
      dispatch(
        defineManifest({
          scanningItemIDs,
          sellingItemIDs,
          scanningOccurrenceIDs,
          sellingOccurrenceIDs,
        }),
      );

      return Promise.all([dispatch(fetchTickets()), dispatch(fetchScans())])
        .then(() => {
          debugTiming('configureManifestAndImport', ts);
          dispatch(
            updateSettings({
              setupScreen: undefined,
              importingManifest: false,
            }),
          );
          dispatch(startPolling());
          return Promise.resolve();
        })
        .catch(error => {
          console.warn('configureManifestAndImport handled error', error);
          return Promise.reject(new Error(`There was an error while syncing your data`));
        })
        .finally(() => {
          dispatch(updateSettings({ importingManifest: false }));
        });

      // TODO logEvent("import", { scanningItemIDs, sellingItemIDs });
    } catch (error) {
      console.warn('configureManifestAndImport handled error', error);

      return Promise.reject(new Error(`There was an error while trying to download your data`));
    }

    dispatch(updateSettings({ importingManifest: false }));
    dispatch(startPolling());
  };
};
