import {
  loadTickets,
  recordScan,
  saveCompletedOrder,
  updateOrder,
  updateSettings,
} from 'shared/actions';
import { debugPurchase } from 'shared/debug';
import { getCurrentOrder, IOrder, PurchaseMode } from 'shared/orders';
import { AppThunkAction, RootState } from 'shared/rootReducer';
import { getConnectionSettings } from 'shared/settingsReducer';
import * as api from './api';
import validateScan from './validateScan';

export const processScan = function (
  ticketNumber: string,
  reversal = false,
  preserveScanScreen = false,
): AppThunkAction<void> {
  return function (dispatch, getState) {
    const state = getState();
    const currentScan = validateScan(state, ticketNumber, { reversal });
    dispatch(recordScan(currentScan, !preserveScanScreen));
  };
};

export const processManualScan = function (
  ticketNumber: string,
  reversal = false,
): AppThunkAction<void> {
  return function (dispatch) {
    dispatch(processScan(ticketNumber, reversal, true));
  };
};

export const admitEntireOrder = function (orderUUID: string): AppThunkAction<Promise<void>> {
  return function (dispatch, getState) {
    const state: RootState = getState();
    const scanningItemIDs = state.settings.scanningItemIDs;
    const order = state.orders.orders[orderUUID];

    if (!order) {
      console.warn('admitEntireOrder was missing the actual order, uuid: ', orderUUID);
      return Promise.resolve();
    }

    // const scans:Array<string> = [];
    order.tickets.forEach(ticket => {
      if (!scanningItemIDs.includes(ticket.itemID)) {
        // not loaded for scanning
        return;
      }

      // silently skip any tickets we've already admitted
      if (ticket.admitted) return;

      const currentScan = validateScan(state, ticket.ticketNumber, { reversal: false });
      dispatch(recordScan(currentScan, false));
    });
    return Promise.resolve();
  };
};

/**
 * Scan a random valid ticket - result may either be valid or invalid.
 *
 * This is only available in dev mode so performance isn't a big issue.
 */
export const scanRandom = function (): AppThunkAction<void> {
  return function (dispatch, getState) {
    const state: RootState = getState();
    const scanningItemIDs = state.settings.scanningItemIDs;
    const tickets = Object.values(state.tickets).filter(ticket => {
      return scanningItemIDs.includes(ticket.itemID);
    });

    if (tickets.length < 1) {
      console.warn('No tickets available for scanRandom');
      return;
    }

    const ticket = tickets[Math.floor(Math.random() * tickets.length)];

    dispatch(processScan(ticket.ticketNumber));
  };
};

// export const connectToStripeTerminal = function (): AppThunkAction<void> {
//   ``
// }

export const makePurchase = function (
  purchaseMode: PurchaseMode,
  checkoutParams: Partial<IOrder> = {},
): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState): Promise<void> {
    debugPurchase('makePurchase start');
    let success = false;

    // batch up settings store updates
    try {
      dispatch(updateSettings({ processingOrder: true }));
      dispatch(updateOrder({ errors: [] }));
      const state = getState();
      const scanningItemIDs = state.settings.scanningItemIDs;
      const baseOrder = { ...getCurrentOrder(state, undefined), checkoutParams };
      debugPurchase(`makePurchase posting with purchaseMode=${purchaseMode}`, baseOrder);

      const settings = getConnectionSettings(state);
      const responseOrder: IOrder = await api.makePurchase(purchaseMode, settings, baseOrder);
      debugPurchase(`makePurchase response success=${responseOrder.success}`, responseOrder);
      // } catch (error) {
      //   return Promise.reject(error);
      // }
      success = responseOrder.success;

      if (responseOrder.success) {
        // import the tickets into the current manifest in case websockets
        // don't work for whatever reason, limiting to the ticket types we've actually loaded for scanning
        const scannableTickets = {};
        let hasScannableTickets = false;

        responseOrder.tickets.forEach(ticket => {
          if (scanningItemIDs.includes(ticket.itemID)) {
            scannableTickets[ticket.ticketNumber] = ticket;
            hasScannableTickets = true;
          }
        });

        debugPurchase(
          `makePurchase hasScannableTickets=${hasScannableTickets}`,
          responseOrder.tickets,
        );

        if (hasScannableTickets) {
          dispatch(loadTickets(scannableTickets, { lastTicketReceived: new Date().getTime() }));
        }
        dispatch(saveCompletedOrder(responseOrder));
      } else {
        dispatch(updateOrder({ errors: responseOrder.errors }));
      }

      // update the quantityAvailable count
      debugPurchase(`makePurchase fetchManifests start`);
      const updatedSettings = await api.fetchManifests({
        storeSlug: settings.storeSlug,
        storeKey: settings.storeKey,
      });

      dispatch(updateSettings({ ...updatedSettings, processingOrder: false }));
      // } catch (error) {
      //   purchaseResultSettings = Object.assign(purchaseResultSettings, {
      //     loadingManifest: false,
      //   });
      //   debugNetwork(`makePurchase fetchManifests error`, error);
      // }
      // } catch (error) {
      //   success = false;
      //   debugNetwork(`makePurchase handled error`, error);
      //   dispatch(updateOrder({ errors: [error.toString()] }));

      //   dispatch(updateSettings({ processingOrder: false }));
      // }

      // purchaseResultSettings = Object.assign(purchaseResultSettings, { processingOrder: false });
      // dispatch(updateSettings(purchaseResultSettings));
      debugPurchase(`makePurchase complete, success=${success}`);
      const errorMessage = responseOrder.errors ? responseOrder.errors[0] : null;
      return success ? Promise.resolve() : Promise.reject(errorMessage);
    } catch (error) {
      dispatch(updateSettings({ processingOrder: false }));
      return Promise.reject(error);
    }
  };
};
