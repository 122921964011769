import { RootState } from '../rootReducer';
import {
  ShareableSettings,
  ShareableConfig,
  calculateDigest,
  generateLabel,
} from './shareableConfig';
import { createSelector } from '@reduxjs/toolkit';

/* Extract settings to be shared with the app on other devices

   This lets us configure a single device and then export its settings to other devices.
*/
const getShareableConfigSettings = (state: RootState): ShareableSettings => {
  const {
    storeSlug,
    storeKey,
    scanningItemIDs,
    scanningOccurrenceIDs,
    sellingItemIDs,
    sellingOccurrenceIDs,
    requirePurchaseName,
    requirePurchaseEmail,
  } = state.settings;
  return {
    storeSlug,
    storeKey,
    scanningItemIDs,
    scanningOccurrenceIDs,
    sellingItemIDs,
    sellingOccurrenceIDs,
    requirePurchaseName,
    requirePurchaseEmail,
  };
};

const getShareableConfig = createSelector(
  [getShareableConfigSettings],
  (settings): ShareableConfig => {
    const digest = calculateDigest(settings);
    const label = generateLabel(digest);

    return {
      digest,
      label,
      settings,
    };
  },
);

export default getShareableConfig;
