import React from 'react';
import { SettingsActions } from '../Settings/SettingsActions';
import { PassedProps as Props } from './LoginContainer';

interface State {
  waiting: boolean;
  setupErrorMessage: string | undefined;
}

class Login extends React.Component<Props, State> {
  storeSlugRef: React.RefObject<HTMLInputElement>;
  storeKeyRef: React.RefObject<HTMLInputElement>;
  constructor(props) {
    super(props);
    this.goToShareQr = this.goToShareQr.bind(this);
    this.storeSlugRef = React.createRef();
    this.storeKeyRef = React.createRef();
    this.state = {
      waiting: false,
      setupErrorMessage: undefined,
    };
  }

  submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ waiting: true });
    const storeSlug = this.storeSlugRef.current ? this.storeSlugRef.current.value : '';
    const storeKey = this.storeKeyRef.current ? this.storeKeyRef.current.value : '';

    try {
      await this.props.connectToStore(storeSlug, storeKey);
    } catch (error) {
      this.setState({ waiting: false, setupErrorMessage: error.message });
    }
    return false;
  };

  goToShareQr(event) {
    event.preventDefault();
    throw new Error('goToShareQr not implemented');
  }

  render() {
    return (
      <div className="login-takeover">
        <div
          className="settings-single-screen settings-credentials-screen credentials-form"
          data-testid="login-screen"
        >
          <div className="overlay-header">
            <h3 className="overlay-header-title">Connect to your store</h3>
            <p className="overlay-header-text">
              Enter your store ID and secret key (found on your dashboard’s “Box Office” page).
            </p>
          </div>

          <form
            method="post"
            acceptCharset="utf-8"
            className="credentials-form"
            onSubmit={this.submit}
          >
            <div className="form-group">
              <input
                type="text"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="username"
                required={true}
                className="form-control text"
                ref={this.storeSlugRef}
                name="storeSlug"
                placeholder="Enter your store ID"
                aria-label="Store ID"
                defaultValue={this.props.storeSlug}
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="current-password"
                required={true}
                className="form-control text"
                ref={this.storeKeyRef}
                name="storeKey"
                placeholder="Enter your store checkin key"
                aria-label="Checkin key"
                defaultValue={this.props.storeKey}
              />
            </div>

            <SettingsActions
              {...this.props}
              waiting={this.state.waiting}
              setupErrorMessage={this.state.setupErrorMessage}
              buttonLabel="Connect"
              waitingLabel="Connecting..."
            />
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
