import React from 'react';

import { Props as ParentProps } from './App';

export interface Props
  extends Pick<
    ParentProps,
    | 'isOnline'
    | 'hasCheckinPermission'
    | 'hasGlobalStatsPermission'
    | 'clearAllData'
    | 'readyToScan'
  > {
  toggleSettingsPanel: () => void;
  toggleStatsContainer: () => void;
}

const StatusButtons: React.FunctionComponent<Props> = props => {
  function toggleSettings(event: React.MouseEvent) {
    event.preventDefault();
    props.toggleSettingsPanel();
  }

  function toggleStats(event: React.MouseEvent) {
    event.preventDefault();
    props.toggleStatsContainer();
  }

  function logout(event: React.MouseEvent) {
    event.preventDefault();
    if (!window.confirm('Are you sure you want to logout?')) return;
    props.clearAllData().catch(error => {
      // likely a pushScans error
      window.alert(error.message);
    });
  }

  function statsButton() {
    if (!props.readyToScan) return null;
    if (!props.hasCheckinPermission) return null;

    // technically we could show per-shift stats here but for now we're just hiding all stats
    if (!props.hasGlobalStatsPermission) return null;

    return (
      <button
        className="btn btn-outlined toggle-stats"
        tabIndex={-1}
        onClick={toggleStats}
        data-testid="stats-btn"
      >
        <i className="icon icon-hamburger" /> Stats
      </button>
    );
  }

  function logoutButton() {
    return (
      <button className="btn btn-outlined" tabIndex={-1} data-testid="logout-btn" onClick={logout}>
        <i className="icon icon-logout-guy" />
        Sign out
      </button>
    );
  }

  return (
    <span data-testid="status-buttons">
      <button className="btn btn-outlined toggle-settings" tabIndex={-1} onClick={toggleSettings}>
        <i className="icon icon-gear" /> Load Tickets
      </button>
      {statsButton()}
      {logoutButton()}
    </span>
  );
};

export default StatusButtons;
