import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { IEventsWithItems, ISettingsState, IOccurrenceWithItemIDs } from '../settingsReducer';

export function getItemIDsFromEvents(events: IEventsWithItems) {
  if (!events) return [];
  const itemIDs: Array<string> = [];
  events.forEach(event => {
    if (!event.items) return;
    event.items.forEach(item => itemIDs.push(item.itemID));
  });
  return itemIDs;
}

export function getOccurrenceFromEvents(events: IEventsWithItems): IOccurrenceWithItemIDs[] {
  if (!events) return [];

  const results = {};

  events.forEach(event => {
    if (!event.items) return;
    event.items.forEach(item => {
      const { occurrenceID, occurrenceDate, occurrenceStartsAt, eventID } = item;
      if (!occurrenceID || !occurrenceDate || !occurrenceStartsAt) return;
      if (!results[occurrenceID]) {
        results[occurrenceID] = {
          eventID,
          occurrenceID,
          occurrenceDate,
          occurrenceStartsAt,
          itemIDs: [],
        } as IOccurrenceWithItemIDs;
      }

      results[occurrenceID].itemIDs.push(item.itemID);
    });
  });

  return Object.values(results);
}

export function filterAvailableItems(
  mode = 'scannable',
  availableItems: IEventsWithItems,
  itemIDsToLoad: string[] = [],
  restrictToitemIDsToLoad = false,
): IEventsWithItems {
  const filteredEventsItems: IEventsWithItems = [];

  (availableItems || []).forEach(event => {
    event = cloneDeep(event);
    event.hasLoadedItems = false;

    const items = event.items.filter(item => {
      // check if the items is valid for the mode (e.g. scannable)
      if (item[mode] === false) {
        return false;
      }

      const itemLoaded = itemIDsToLoad.includes(item.itemID);

      if (itemLoaded) {
        event.hasLoadedItems = true;
      }

      if (restrictToitemIDsToLoad && !itemLoaded) {
        return false;
      }

      return true;
    });

    if (items.length < 1) {
      return;
    }
    event.eventID = String(event.eventID);
    event.items = items;
    filteredEventsItems.push(event);
  });

  return filteredEventsItems;
}

export function readyToScan(settings: ISettingsState) {
  // console.log(
  //   'check readytoscan',
  //   'scan IDS',
  //   settings.scanningItemIDs,
  //   'slug',
  //   settings.storeKey,
  //   settings.storeSlug,
  // );
  if (
    !settings.scanningItemIDs ||
    settings.scanningItemIDs.length < 1 ||
    isEmpty(settings.storeSlug) ||
    isEmpty(settings.storeKey)
  ) {
    return false;
  }

  return true;
}

export function readyToSell(settings: Partial<ISettingsState>) {
  if (
    !settings.sellingItemIDs ||
    settings.sellingItemIDs.length < 1 ||
    isEmpty(settings.storeSlug) ||
    isEmpty(settings.storeKey)
  ) {
    return false;
  }

  return true;
}

export function defineManifest(state: ISettingsState, payload): Partial<ISettingsState> {
  const scanningOccurrenceIDs: Array<string> = payload.scanningOccurrenceIDs || [];
  const sellingOccurrenceIDs: Array<string> = payload.sellingOccurrenceIDs || [];
  let scanningItemIDs: Array<string> = [];
  let sellingItemIDs: Array<string> = [];

  const hasGlobalStatsPermission =
    'hasGlobalStatsPermission' in payload
      ? payload.hasGlobalStatsPermission
      : state.hasGlobalStatsPermission;
  const hasCheckinPermission =
    'hasCheckinPermission' in payload ? payload.hasCheckinPermission : state.hasCheckinPermission;

  scanningItemIDs = (payload.scanningItemIDs || []).map(itemID => String(itemID));
  sellingItemIDs = (payload.sellingItemIDs || []).map(itemID => String(itemID));

  // Occurrence IDs are appended and coexist with regular item selection
  if (scanningOccurrenceIDs.length > 0 || sellingOccurrenceIDs.length > 0) {
    state.availableOccurrences.forEach(occ => {
      if (scanningOccurrenceIDs.includes(occ.occurrenceID)) {
        scanningItemIDs = scanningItemIDs.concat(occ.itemIDs);
      }

      if (sellingOccurrenceIDs.includes(occ.occurrenceID)) {
        sellingItemIDs = sellingItemIDs.concat(occ.itemIDs);
      }
    });
  }

  return {
    hasGlobalStatsPermission,
    hasCheckinPermission,
    sellingItemIDs,
    scanningItemIDs,
    scanningOccurrenceIDs,
    sellingOccurrenceIDs,
    hasTicketsSince: null,
    hasScansSince: null,
    lastTicketReceived: null,
    lastScanReceived: null,
    lastTicketPull: null,
    lastScanPull: null,
    lastScanPost: null,
    checkinShifts: {},
  };
}
