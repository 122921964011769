import React from 'react';
import { SetupScreens } from 'shared/settingsReducer';
import { Props as ParentProps } from './AppContainer';
import Audio, { Sounds } from './Audio';
import DeviceName from './DeviceName';
import { ErrorBoundary } from './ErrorBoundary';
import ScanHistory from './ScanHistory';
import ScanResults from './ScanResults';
import SearchContainer from './SearchContainer';
import SettingsPanel from './Settings/index';
import StatsContainer from './StatsContainer';
import StatusBar from './StatusBar';
import StatusButtons from './StatusButtons';

export interface Props
  extends Pick<
    ParentProps,
    | 'setupScreen'
    | 'currentTheme'
    | 'openSetup'
    | 'currentScan'
    | 'hasCheckinPermission'
    | 'hasGlobalStatsPermission'
    | 'sync'
    | 'readyToScan'
    | 'clearAllData'
  > {
  dataLoaded: boolean;
  isOnline: boolean;
  showFirstRun: boolean;
}

interface Stats {
  statsVisible: boolean;
  deviceNameVisible: boolean;
  historyVisible: boolean;
}

export default class App extends React.Component<Props, Stats> {
  mainContent: HTMLDivElement | null;
  scanInput: HTMLInputElement | null;
  audioContainer: React.RefObject<Audio>;

  constructor(props: Props) {
    super(props);
    this.mainContent = null;
    this.scanInput = null;
    this.audioContainer = React.createRef();
    this.state = {
      deviceNameVisible: false,
      statsVisible: false,
      historyVisible: false,
    };
  }

  // play the success or error sound when a new scan has been received
  componentDidUpdate(prevProps: Props) {
    if (!this.props.currentScan) return;

    const previousUUID = prevProps.currentScan ? prevProps.currentScan.uuid : false;
    const currentUUID = this.props.currentScan ? this.props.currentScan.uuid : false;

    if (currentUUID && previousUUID !== currentUUID) {
      this.props.currentScan.valid ? this.playSuccessSound() : this.playErrorSound();
    }
  }

  closeOverlays = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();

    document.onkeydown = null;

    if (this.props.setupScreen) {
      this.props.openSetup(undefined);
    }

    if (this.state.statsVisible || this.state.historyVisible || this.state.deviceNameVisible) {
      this.setState({
        statsVisible: false,
        historyVisible: false,
        deviceNameVisible: false,
      });
    }

    this.selectInput();
  };

  toggleSettingsPanel = (event?: React.MouseEvent<Element>) => {
    if (event) event.preventDefault();
    if (this.props.setupScreen) {
      this.closeOverlays();
    } else {
      this.closeOverlays();
      this.props.openSetup(SetupScreens.LoadingManifest);
      this.bindEscapeKey();
    }
  };

  toggleDeviceName = (event?: React.MouseEvent<Element>) => {
    if (event) event.preventDefault();
    if (this.state.deviceNameVisible) {
      this.closeOverlays();
    } else {
      this.closeOverlays();
      this.setState({ deviceNameVisible: true });
      this.bindEscapeKey();
    }
  };

  toggleStatsContainer = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    if (this.state.statsVisible) {
      this.closeOverlays();
    } else {
      this.closeOverlays();
      this.bindEscapeKey();
      this.setState({
        statsVisible: true,
      });
    }
  };

  toggleScanHistory = (event?: React.SyntheticEvent) => {
    if (event) event.preventDefault();
    if (this.state.historyVisible) {
      this.closeOverlays();
    } else {
      this.closeOverlays();
      this.bindEscapeKey();
      this.setState({
        historyVisible: true,
      });
    }
  };

  bindEscapeKey = () => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        this.closeOverlays();
      }
    });
  };

  // return focus to the input field while preserving the main div's scroll
  // position
  selectInput = (deleteInput = false) => {
    // TODO: maybe re-instate this?
    // if (Tickit.settings.usingIOS) return;

    if (!this.mainContent)
      this.mainContent = document.getElementById('main-content') as HTMLDivElement;
    if (!this.scanInput) this.scanInput = document.getElementById('scan-input') as HTMLInputElement;

    if (!this.scanInput) return; // might be hidden if we're in the first-run view

    const { scrollTop } = this.mainContent;
    if (deleteInput) {
      this.scanInput.value = '';
    } else {
      this.scanInput.setSelectionRange(0, this.scanInput.value.length);
    }
    this.scanInput.focus();
    this.mainContent.scrollTop = scrollTop;
  };

  playSuccessSound() {
    if (!this.audioContainer.current) return;
    this.audioContainer.current.play(Sounds.Success);
  }

  playErrorSound() {
    if (!this.audioContainer.current) return;
    this.audioContainer.current.play(Sounds.Error);
  }

  renderHeader() {
    return (
      <div className="main-header">
        <ErrorBoundary>
          <div className="header-content-row">
            <div className="header-content-button-col">
              <StatusButtons
                isOnline={this.props.isOnline}
                toggleSettingsPanel={this.toggleSettingsPanel}
                toggleStatsContainer={this.toggleStatsContainer}
                clearAllData={this.props.clearAllData}
                readyToScan={this.props.readyToScan}
                hasCheckinPermission={this.props.hasCheckinPermission}
                hasGlobalStatsPermission={this.props.hasGlobalStatsPermission}
              />
            </div>

            <div className="header-content-logo-col">
              <div className="header-wordmark">
                <i className="icon icon-tickit-wordmark" />
              </div>
            </div>
          </div>

          <StatusBar
            toggleScanHistory={this.toggleScanHistory}
            selectInput={this.selectInput}
            toggleDeviceName={this.toggleDeviceName}
          />
        </ErrorBoundary>
      </div>
    );
  }

  renderMain() {
    if (!this.props.dataLoaded) {
      return (
        <div className="main-content" id="main-content" data-testid="dataloading-takeover">
          <div className="takeover-content">
            <p>Welcome to the Tickit Check-in App.</p>
            <p>We’re reloading your tickets now&hellip;</p>
          </div>
        </div>
      );
    } else if (!this.props.hasCheckinPermission) {
      return (
        <div className="main-content" id="main-content" data-testid="nocheckinpermission-takeover">
          <div className="takeover-content">
            <p>
              Your account does not have the ability to check-in tickets. Please{' '}
              <a href="#settings-panel" onClick={this.toggleSettingsPanel}>
                {' '}
                sign in using a different account
              </a>{' '}
              to use Tickit Web Check-in.
            </p>
          </div>
        </div>
      );
    } else if (this.props.showFirstRun) {
      return (
        <div className="main-content" id="main-content">
          <div className="takeover-content" data-testid="firstrun-takeover">
            <p>Welcome to the Tickit Check-in App.</p>
            <p>
              Select{' '}
              <a href="#settings-panel" onClick={this.toggleSettingsPanel}>
                {' '}
                settings
              </a>{' '}
              to load tickets for entry.
            </p>
            <p>Once you’ve loaded your tickets you’ll be able to start admitting your attendees.</p>
          </div>
        </div>
      );
    }

    return (
      <SearchContainer selectInput={this.selectInput} toggleScanHistory={this.toggleScanHistory} />
    );
  }

  renderStatsContainer() {
    if (!this.state.statsVisible) return false;
    return (
      <StatsContainer
        onClose={this.toggleStatsContainer}
        hasGlobalStatsPermission={this.props.hasGlobalStatsPermission}
      />
    );
  }

  renderSettingsPanel(): React.ReactNode {
    if (!this.props.setupScreen) return null;
    return <SettingsPanel onClose={this.toggleSettingsPanel} selectInput={this.selectInput} />;
  }

  renderDeviceName(): React.ReactNode {
    if (!this.state.deviceNameVisible) return null;
    return <DeviceName onClose={this.closeOverlays} />;
  }

  renderScanHistory() {
    if (!this.state.historyVisible) return false;
    return <ScanHistory onClose={this.toggleScanHistory} />;
  }

  render() {
    const { showFirstRun, setupScreen, dataLoaded } = this.props;
    const className = [`${this.props.currentTheme}-theme`];

    if (
      setupScreen ||
      this.state.statsVisible ||
      this.state.historyVisible ||
      this.state.deviceNameVisible
    ) {
      className.push('showing-overlay');
    }

    if (showFirstRun || !dataLoaded) className.push('showing-main-takeover');

    return (
      <div className={className.join(' ')}>
        <div className="page-frame">
          <ErrorBoundary>
            <ScanResults
              currentScan={this.props.currentScan}
              readyToScan={this.props.readyToScan}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <section className="main" role="main">
              {this.renderHeader()}
              {this.renderMain()}
            </section>
          </ErrorBoundary>
        </div>
        <div className="overlay-mask" onClick={this.closeOverlays} />
        {this.renderDeviceName()}
        {this.renderStatsContainer()}
        {this.renderSettingsPanel()}
        {this.renderScanHistory()}
        <Audio ref={this.audioContainer} />
      </div>
    );
  }
}
