import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import { Middleware, MiddlewareAPI } from 'redux';
import * as actions from 'shared/actions';
import { loadScans, loadTickets } from 'shared/actions';
import { debugNetwork } from 'shared/debug';
import { RootState } from 'shared/rootReducer';
import { getUnsyncedScans, IScan } from 'shared/scans';
import {
  getSyncSettings,
  getUnsyncedShifts,
  ISettingsState,
  ISyncSettings,
} from 'shared/settingsReducer';
import { ITicket } from 'shared/Ticket';
import parseScansResponse, { ScansJSONResponse } from './parseScansResponse';
import parseTicketsResponse, { TicketsJSONResponse } from './parseTicketsResponse';

const debug = (logLine: any, params?: any) => {
  if (params) {
    debugNetwork(`websocketsMW: ${logLine}`, params);
  } else {
    debugNetwork(`websocketsMW: ${logLine}`);
  }
};

export type WebSocketConnectionSettings = Pick<
  ISyncSettings,
  'storeSlug' | 'storeKey' | 'scannerUserAgent'
>;

/* How long to throttle new records received over websockets before dispatching
   them to the local reducers (in milliseconds) */
const THROTTLE_DISPATCH_DURATION = 4000;

/* How long to throttle sending records back to the server (in milliseconds) */
const PUSH_DATA_THROTTLE_DURATION = 2000;

const middlewareActions = [
  actions.updateSettings.toString(), // credentials may have changed
  actions.loadOrders.toString(), // credentials may have changed
  actions.loadTickets.toString(), // credentials may have changed
  actions.loadScans.toString(), // credentials may have changed
  actions.defineManifest.toString(), // credentials may have changed
  actions.recordScan.toString(),
];

export interface IWebsocketTransporter {
  settings: WebSocketConnectionSettings;
  isConnected(): boolean;
  isConnecting(): boolean;

  // new (settings: Partial<ISyncSettings>);
  connect(): void;
  disconnect(): void;
  reconnect(settings: WebSocketConnectionSettings): void;
  onMessage?: (data: any) => void;
  recordScan(scan: IScan): void;
  recordShifts(data: any): void;
}

let storeSlug: string | undefined = undefined;
let storeKey: string | undefined = undefined;

const maybeReconnect = (ws: IWebsocketTransporter, state: RootState) => {
  debug('maybeReconnect');
  const settings: ISyncSettings = getSyncSettings(state);
  const credentialsChanged = settings.storeSlug !== storeSlug || settings.storeKey !== storeKey;

  storeSlug = settings.storeSlug;
  storeKey = settings.storeKey;

  if (isEmpty(storeSlug) || isEmpty(storeSlug)) {
    // unable to connect
    if (ws) {
      debug('no credentials - disconnecting');
      ws.disconnect();
    }

    debug('no credentials - bailing');
    return;
  }

  if (!credentialsChanged) {
    return;
  }
  debug('credentailsChanged so reconnecting');
  // TODO: should this go in a promise?
  ws.reconnect({
    storeKey: settings.storeKey,
    storeSlug: settings.storeSlug,
    scannerUserAgent: settings.scannerUserAgent,
  });
};

// we don't need to this for every tiny change
const throttledPushData = throttle(
  (ws: IWebsocketTransporter, state: RootState) => {
    debug('pushData');
    ws.recordShifts(getUnsyncedShifts(state));

    getUnsyncedScans(state).forEach(scan => {
      ws.recordScan(scan);
    });
  },
  PUSH_DATA_THROTTLE_DURATION,
  { trailing: true },
);

//   recordScan(scan: Scan) {
//     if (!scan) return;
//     if (!scan.valid) return;
//     this.send('record_scan', scan.toJSON());
//   }

//   recordShifts(shifts: Array<Shift>) {
//     if (shifts.length < 1) return;
//     this.send('record_shifts', { shifts: shifts.map(shift => shift.toJSON()) });
//   }

//   onScansRecorded(data) {
//     debug(`Websockets received checkins ${data.uuids}`);
//     store.dispatch(markScansAsSynced(data.uuids));
//     store.dispatch(updateSettings({ lastScanReceived: new Date().getTime() } as Partial<ISyncSettings>));
//   }

//   onShiftsRecorded(data) {
//     debug(`Websockets received synced shifts ${data.shift_digests}`);
//     store.dispatch(markShiftsAsSynced(data.shift_digests));
//   }

let throttledScans: { [key: string]: IScan } = {};
let throttledScanSettings: Partial<ISettingsState> = {};
const throttledShiftDigests: Set<string> = new Set([]);
let throttledTickets: { [key: string]: ITicket } = {};
let throttledTicketsSettings: Partial<ISettingsState> = {};

const throttledDispatchUpdates = throttle(
  (store: MiddlewareAPI) => {
    const ticketNumbers = Object.keys(throttledTickets);
    const scanUUIDs = Object.keys(throttledScans);
    const shiftDigests = Array.from(throttledShiftDigests);
    let state: RootState | undefined;

    debug(
      `throttledDispatchUpdates (tickets=${ticketNumbers.length}, scans=${scanUUIDs.length}, shiftDigests=${shiftDigests.length})`,
    );

    if (ticketNumbers.length > 0) {
      store.dispatch(loadTickets(throttledTickets, throttledTicketsSettings));
      throttledTickets = {};
      throttledTicketsSettings = {};
    }

    if (scanUUIDs.length > 0) {
      if (!state) {
        state = store.getState();
      }

      // only record ticket types loaded for scanning
      const scanningItemIDs = state!.settings.scanningItemIDs;

      store.dispatch(
        loadScans(
          Object.values(throttledScans).filter(s => scanningItemIDs.includes(s.itemID || '')),
          throttledScanSettings,
        ),
      );

      throttledScans = {};
      throttledScanSettings = {};
    }

    if (shiftDigests.length > 0) {
      store.dispatch(actions.markShiftsAsSynced(shiftDigests));
      throttledShiftDigests.clear();
    }
  },
  THROTTLE_DISPATCH_DURATION,
  { leading: true, trailing: false },
);

const onTicketsUpdated = (store: MiddlewareAPI, data: TicketsJSONResponse) => {
  const { tickets, settings } = parseTicketsResponse(data);

  if (!tickets) {
    console.warn('Error parsing in onTicketsUpdated', data);
    return;
  }

  const parsedTickets = Object.values(tickets);

  debug(`received tickets (${parsedTickets.length})`);

  parsedTickets.forEach(ticket => {
    throttledTickets[ticket.ticketNumber] = ticket;
  });

  throttledTicketsSettings = settings;
  throttledDispatchUpdates(store);
};

const onShiftsRecorded = (store: MiddlewareAPI, data: { shift_digests: Array<string> }) => {
  if (!data || data.shift_digests.length < 1) return;
  data.shift_digests.forEach(shiftDigest => {
    throttledShiftDigests.add(shiftDigest);
  });
  throttledDispatchUpdates(store);
};

const onNewScans = (store: MiddlewareAPI, data: ScansJSONResponse) => {
  const { scans, settings } = parseScansResponse(data);
  debug(`received scans (${scans.length})`);

  scans.forEach(scan => {
    throttledScans[scan.uuid] = { ...scan, synced: true };
  });

  throttledScanSettings = settings;
  throttledDispatchUpdates(store);
};

function onMessage(data: any, store: MiddlewareAPI) {
  if (data.tickets) {
    onTicketsUpdated(store, data as TicketsJSONResponse);
  } else if (data.scans) {
    onNewScans(store, data as ScansJSONResponse);
  } else if (data.shift_digests) {
    debug('Received shift digests');
    onShiftsRecorded(store, data);
  } else {
    debug('received unknown message', data);
    console.error('received unknown message', data);
  }
}

const middlewareCreator = (ws: IWebsocketTransporter) => {
  if (!ws) {
    throw new Error('no ws transporter');
  }
  const websocketMiddleware: Middleware = (store: MiddlewareAPI) => next => action => {
    if (!ws.onMessage) {
      ws.onMessage = function (data: any) {
        onMessage(data, store);
      };
    }

    const returnValue = next(action);

    if (!middlewareActions.includes(action.type)) {
      // debug('skipping action', action.type);
      return returnValue;
    }

    const state: RootState = store.getState();

    if (action.type === actions.updateSettings.toString()) {
      maybeReconnect(ws, state);
    }

    if (action.type !== actions.recordScan.toString()) {
      return returnValue;
    }
    throttledPushData(ws, state);

    return returnValue;
  };

  return websocketMiddleware;
};

export default middlewareCreator;
