import { loadScans, loadTickets, updateSettings } from 'shared/actions';
import { debugTiming } from 'shared/debug';
import { IScan } from 'shared/scans';
import { ITicket } from 'shared/Ticket';
import { setUserId } from '../lib/analytics';
import { reportError } from '../lib/errorReporting';
import { db, TickitDatabase } from './persist';
import { store } from './store';

function restoreSettings(db: TickitDatabase) {
  return new Promise((resolve, reject) => {
    const ts = Date.now();
    try {
      db.settings.get('settings').then(settingsObject => {
        if (settingsObject) {
          const settings = JSON.parse(settingsObject.settings);
          store.dispatch(updateSettings(settings, true));
          setUserId(settings.storeSlug);
        }
        debugTiming(`persist.restoreSettings`, ts);

        resolve();
      });
    } catch (error) {
      console.warn('Error restoring settings', error);
      reportError(error);
      reject(error);
    }
  });
}

function restoreScans(db: TickitDatabase) {
  return new Promise((resolve, reject) => {
    const ts = Date.now();
    try {
      console.log('restore scans');
      const scans: Array<IScan> = [];
      db.scans
        .toCollection()
        .each((scan: IScan) => {
          scan.valid = true;
          scans.push(scan);
        })
        .then(() => {
          debugTiming(`persist.restoreScans (${scans.length})`, ts);
          if (scans.length > 0) {
            store.dispatch(loadScans(scans, {}, true));
          }
          resolve();
        });
    } catch (error) {
      console.warn('Error restoring scans', error);
      reportError(error);
      reject(error);
    }
  });
}

function restoreTickets(db: TickitDatabase) {
  return new Promise((resolve, reject) => {
    const ts = Date.now();
    try {
      const tickets: {
        [key: string]: ITicket;
      } = {};
      db.tickets
        .toCollection()
        .each((ticket: ITicket) => {
          tickets[ticket.ticketNumber] = ticket;
        })
        .then(() => {
          debugTiming(`persist.restoreTickets (${Object.keys(tickets).length})`, ts);
          if (Object.keys(tickets).length > 0) {
            store.dispatch(loadTickets(tickets, {}, true));
          }
          resolve();
        });
    } catch (error) {
      console.warn('Error restoring tickets', error);
      reportError(error);
      reject(error);
    }
  });
}

/** Restore the redux state from local storage */
export function rehydrate(): Promise<void> {
  const ts = Date.now();
  return new Promise(resolve => {
    db.open().then(db => {
      Promise.all([
        restoreSettings(db as TickitDatabase),
        restoreScans(db as TickitDatabase),
        restoreTickets(db as TickitDatabase),
      ])
        .then(() => {
          debugTiming('persist.restore.total', ts);
          resolve();
        })
        .catch(error => {
          throw error;
        });
    });
  });
}
