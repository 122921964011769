// import Ticket from './Ticket';
import isEmpty from 'lodash/isEmpty';
import { formatScanForServer, IScan } from 'shared/scans';
import { debugNetwork, debugPurchase } from './debug';
import { IOrder, PurchaseMode } from './orders';
import formatOrderForServer from './orders/formatOrderForServer';
import parseCurrencyResponse from './parseCurrencyResponse';
import parseManifestReponse from './parseManifestReponse';
import parseOrderResponse from './parseOrderResponse';
import parseScansResponse, { ScansWithSettings } from './parseScansResponse';
import parseTicketsResponse, { TicketsWithSettings } from './parseTicketsResponse';
import { formatCheckinShiftForServer, ICheckinShift } from './settings/checkinShift';
import { ConnectionSettings, ISettingsState, ISyncSettings } from './settingsReducer';
import { ShareableConfig, PersistedShareableConfig } from './settings/shareableConfig';
const API_VERSION = 'v2';

export type JSONRequestCallback = (
  settings: Partial<ISyncSettings>,
  method: string,
  endpoint: string,
  data?: any,
) => Promise<any>;

// if (!global.jsonRequest) {
//   throw new Error('global.jsonRequest must be defined');
// }

// const jsonRequest: JSONRequestCallback = global.jsonRequest;

export function fetchTickets(
  settings: ISyncSettings,
  cursor?: string,
): Promise<TicketsWithSettings> {
  const { scanningItemIDs, hasTicketsSince } = settings;
  if (scanningItemIDs.length < 1) {
    return new Promise(resolve => resolve({ tickets: {}, settings: {} }));
  }

  let endpoint = `/tickets?v=${API_VERSION}&item_ids=${settings.scanningItemIDs.join(
    ',',
  )}&paginate=true`;

  if (hasTicketsSince) {
    endpoint += `&since=${Math.floor(hasTicketsSince / 1000)}`;
  }

  if (cursor) {
    endpoint += `&cursor=${cursor || ''}`;
  }

  return globalThis
    .jsonRequest(settings, 'GET', endpoint)
    .then(response => parseTicketsResponse(response))
    .catch(error => {
      throw error;
    });
}

export interface StripeTerminalConnectionTokenResponse {
  store_slug: string;
  stripe_account: string | null;
  secret: string | null;
  error: string | null;
  locations: {
    [location_id: string]: string;
  };
}

export function fetchStripeTerminalConnectionToken(
  settings: ConnectionSettings,
): Promise<StripeTerminalConnectionTokenResponse> {
  const endpoint = `/stripe_terminal_connection_token?v=${API_VERSION}`;
  return globalThis.jsonRequest(settings, 'POST', endpoint);
}

export function fetchScans(settings: ISyncSettings, cursor?: string): Promise<ScansWithSettings> {
  const { scanningItemIDs, hasScansSince } = settings;
  if (scanningItemIDs.length < 1) {
    return new Promise(resolve => resolve({ scans: [], settings: {} }));
  }

  let endpoint = `/scans?v=${API_VERSION}&item_ids=${settings.scanningItemIDs.join(
    ',',
  )}&paginate=true`;

  if (hasScansSince) {
    endpoint += `&since=${Math.floor(hasScansSince / 1000)}`;
  }

  if (cursor) {
    endpoint += `&cursor=${cursor || ''}`;
  }

  return globalThis
    .jsonRequest(settings, 'GET', endpoint)
    .then(response => parseScansResponse(response))
    .catch(error => {
      throw error;
    });
}

type FetchManifestsResponses = ISyncSettings &
  Pick<
    ISettingsState,
    | 'availableItems'
    | 'storeCredentialsOK'
    | 'currencies'
    | 'hasCheckinPermission'
    | 'hasGlobalStatsPermission'
  >;

export function fetchManifests(settings: Partial<ISyncSettings>): Promise<FetchManifestsResponses> {
  return globalThis
    .jsonRequest(settings, 'GET', `/manifests?v=${API_VERSION}`)
    .then(response => {
      const updatedSettings: Partial<ISettingsState> = { ...settings };
      updatedSettings.availableItems = parseManifestReponse(response);
      updatedSettings.currencies = parseCurrencyResponse(response.currencies);
      updatedSettings.hasGlobalStatsPermission = response.hasGlobalStatsPermission;
      updatedSettings.hasCheckinPermission = response.hasCheckinPermission;
      updatedSettings.storeCredentialsOK = true;
      return updatedSettings;
    })
    .catch(error => {
      throw error;
    });
}

export function fetchDeviceConfiguration(
  settings: Partial<ISyncSettings>,
  deviceConfigurationID: string,
): Promise<PersistedShareableConfig> {
  return globalThis
    .jsonRequest(
      settings,
      'GET',
      `/device_configurations/${deviceConfigurationID}?v=${API_VERSION}`,
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
}

export function postDeviceConfiguration(
  settings: Partial<ISyncSettings>,
  deviceConfiguration: ShareableConfig,
): Promise<PersistedShareableConfig> {
  debugNetwork('postDeviceConfiguration', JSON.stringify(deviceConfiguration));
  return globalThis
    .jsonRequest(settings, 'POST', `/device_configurations?v=${API_VERSION}`, {
      deviceConfiguration,
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
}

export type PushScansResponse = {
  syncedUUIDs: Array<string>;
  syncedShiftDigests: Array<string>;
  settings: {
    lastScanPost?: number;
  };
};

export function pushScans(
  settings: ISyncSettings,
  unsyncedScans: Array<IScan>,
  unsyncedShifts: Array<ICheckinShift>,
  deviceStatus: object,
): Promise<PushScansResponse> {
  // maybe first run with no previous credentials
  if (isEmpty(settings.storeSlug) || isEmpty(settings.storeKey)) {
    return Promise.resolve({
      syncedUUIDs: [],
      syncedShiftDigests: [],
      settings: {},
    } as PushScansResponse);
  }

  const formattedScans = unsyncedScans.map(scan => formatScanForServer(scan));
  const formattedShifts = unsyncedShifts.map(shift => formatCheckinShiftForServer(shift));
  const payload = {
    scans: formattedScans,
    shifts: formattedShifts,
    deviceStatus,
  };
  const lastScanPost = new Date().getTime();
  // debugNetwork(payload);
  return globalThis
    .jsonRequest(settings, 'POST', `/scans?v=${API_VERSION}`, payload)
    .then(data => {
      return {
        syncedUUIDs: data.uuids || [],
        syncedShiftDigests: data.shift_digests || [],
        settings: { lastScanPost },
      } as PushScansResponse;
    })
    .catch(() => {
      return { syncedUUIDs: [], syncedShiftDigests: [], settings: {} } as PushScansResponse;
    });
}

interface BuiltOrderForTerminal {
  order_id: string;
  currency: string;
  balance_for_checkout_cents: number;
  fees_for_checkout_cents: number;
}

export function buildOrderForTerminal(
  purchaseMode: PurchaseMode,
  settings: ConnectionSettings,
  order: IOrder,
): Promise<BuiltOrderForTerminal> {
  // Actions.logEvent('Make Purchase');
  // TODO2019 order.currentShiftDigest = settings.currentShiftDigest;

  const orderJSON = formatOrderForServer(purchaseMode, order);
  orderJSON.build_for_terminal = true;
  debugNetwork('API makePurchase, jsonPOST.build_for_terminal', purchaseMode, orderJSON);
  return globalThis
    .jsonRequest(settings, 'POST', `/orders?v=${API_VERSION}`, orderJSON)
    .then(data => {
      if (data.order && data.order.errors && data.order.errors.length > 0) {
        throw new Error(data.order.errors[0]);
      }
      debugPurchase('BuiltOrderForTerminal -------------------');
      const toReturn = {
        order_id: data.order.order_id,
        currency: data.order.currency,
        balance_for_checkout_cents: data.order.balance_for_checkout_cents,
        fees_for_checkout_cents: data.order.fees_for_checkout_cents,
      };
      console.log(toReturn);

      return toReturn;
    })
    .catch(error => {
      console.warn(error);
      throw error;
    });
}

export function captureForTerminal(
  settings: ConnectionSettings,
  orderID: string,
  paymentIntentId: string,
): Promise<IOrder> {
  const payload = {
    order_id: orderID,
    payment_intent_id: paymentIntentId,
  };
  debugNetwork('captureForTerminal', payload);
  return globalThis
    .jsonRequest(settings, 'POST', `/orders/capture?v=${API_VERSION}`, payload)
    .then(data => {
      return parseOrderResponse(data.order);
    })
    .catch(error => {
      throw error;
    });
}

export function releasePendingOrder(
  settings: ConnectionSettings,
  order?: IOrder,
): Promise<boolean> {
  const payload = {
    order_id: order?.orderID,
    token: order?.orderToken,
  };

  if (!payload.order_id || !payload.token) {
    debugNetwork('releasePendingOrder - missing order_id or token');
    return Promise.resolve(false);
  }

  debugNetwork('releasePendingOrder', payload);
  return globalThis
    .jsonRequest(settings, 'POST', `/orders/release?v=${API_VERSION}`, payload)
    .then(() => {
      return true;
    })
    .catch(error => {
      throw error;
    });
}

export function makePurchase(
  purchaseMode: PurchaseMode,
  settings: ConnectionSettings,
  order: IOrder,
): Promise<IOrder> {
  // Actions.logEvent('Make Purchase');
  // TODO2019 order.currentShiftDigest = settings.currentShiftDigest;

  const orderJSON = formatOrderForServer(purchaseMode, order);

  debugNetwork('API makePurchase, jsonPOST', purchaseMode, orderJSON);
  return globalThis
    .jsonRequest(settings, 'POST', `/orders?v=${API_VERSION}`, orderJSON)
    .then(data => {
      console.log('ORDER JSON -------------------');
      console.log(data);

      const responseOrder = parseOrderResponse(data.order);

      // this isn't currently returned from the server so we manually inject it here
      responseOrder.uuid = order.uuid;

      // preserve line items on failure so buyers can retry
      if (!responseOrder.success) {
        responseOrder.lineItems = order.lineItems;
      }

      return responseOrder;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export function postDebugData(settings, debugData) {
  return globalThis.jsonRequest(settings, 'POST', `/debug?v=${API_VERSION}`, {
    payload: debugData,
  });
}
