import React from 'react';
import { connect } from 'react-redux';
import { statsSelector } from 'shared/statsSelector';
import StatsPanel from './StatsPanel';

interface State {
  showShiftStats: boolean;
}

class StatsContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showShiftStats: false,
    };
  }

  toggleShiftStats = () => {
    this.setState({ showShiftStats: !this.state.showShiftStats });
  };

  render() {
    const showShiftStats = this.props.hasGlobalStatsPermission ? this.state.showShiftStats : true;

    return (
      <StatsPanel
        hasGlobalStatsPermission={this.props.hasGlobalStatsPermission}
        showShiftStats={showShiftStats}
        toggleShiftStats={this.toggleShiftStats}
        stats={this.props.stats}
        onClose={this.props.onClose}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    stats: statsSelector(state),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type PassedProps = {
  onClose: () => void;
  hasGlobalStatsPermission: boolean;
};

export interface Props extends StateProps, PassedProps {}

export default connect(mapStateToProps)(StatsContainer);
