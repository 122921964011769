import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { IEventWithItems } from '../settingsReducer';
import TickitUtil from '../Utils';

export interface ItemOrOccurrence {
  dataKey: string;
  itemID?: string;
  isOccurrence: boolean;
  occurrenceID?: string;
  occurrenceDate?: number;
  occurrenceStartsAt?: number;
  occurrenceEntireDay?: boolean;
  title: string;
  scannable: boolean;
  purchasable: boolean;
}

const getAvailableItems = (state: RootState) => state.settings.availableItems;

export interface EventWithItemsAndOccurrences {
  eventID: string;
  eventTitle: string;
  eventStartsAt: number | null;
  multipleStartTimes: boolean;
  data: ItemOrOccurrence[];
  eventItemIDs: string[];
  eventOccurrenceIDs: string[];
  scannableItemIDs: string[];
  purchasableItemIDs: string[];
  scannableOccurrenceIDs: string[];
  purchasableOccurrenceIDs: string[];
}

function generateEventData(event: IEventWithItems): EventWithItemsAndOccurrences {
  const eventItemIDs = new Set<string>();
  const eventOccurrenceIDs = new Set<string>();
  const scannableItemIDs = new Set<string>();
  const purchasableItemIDs = new Set<string>();
  const scannableOccurrenceIDs = new Set<string>();
  const purchasableOccurrenceIDs = new Set<string>();
  const items: ItemOrOccurrence[] = [];

  event.items.forEach(item => {
    const { occurrenceID } = item;
    if (occurrenceID) {
      const { occurrenceID, occurrenceDate } = item;
      if (!occurrenceID || !occurrenceDate) {
        throw new Error('missing occurrence Date or occurrenceID');
      }

      if (eventOccurrenceIDs.has(occurrenceID)) return;

      eventOccurrenceIDs.add(occurrenceID);
      if (item.scannable) scannableOccurrenceIDs.add(occurrenceID);
      if (item.purchasable) purchasableOccurrenceIDs.add(occurrenceID);

      items.push({
        dataKey: `occurrenceID-${occurrenceID}`,
        occurrenceID: occurrenceID,
        occurrenceDate: item.occurrenceDate,
        occurrenceEntireDay: item.occurrenceEntireDay,
        occurrenceStartsAt: item.occurrenceStartsAt,
        title: TickitUtil.formattedOccurrence(item),
        isOccurrence: true,
        scannable: item.scannable,
        purchasable: item.purchasable,
      });
    } else {
      eventItemIDs.add(item.itemID);
      if (item.scannable) scannableItemIDs.add(item.itemID);
      if (item.purchasable) purchasableItemIDs.add(item.itemID);
      items.push({
        dataKey: `itemID-${item.itemID}`,
        itemID: item.itemID,
        title: item.itemTitle,
        isOccurrence: false,
        scannable: item.scannable,
        purchasable: item.purchasable,
      });
    }
  });

  return {
    eventID: event.eventID,
    eventTitle: event.eventTitle || '',
    eventStartsAt: event.eventStartsAt,
    multipleStartTimes: event.multipleStartTimes || false,
    data: items,
    eventItemIDs: [...eventItemIDs].sort(),
    eventOccurrenceIDs: [...eventOccurrenceIDs].sort(),
    scannableItemIDs: [...scannableItemIDs].sort(),
    purchasableItemIDs: [...purchasableItemIDs].sort(),
    scannableOccurrenceIDs: [...scannableOccurrenceIDs].sort(),
    purchasableOccurrenceIDs: [...purchasableOccurrenceIDs].sort(),
  };
}

/** Retrieves a formatted list of ticket types and timed event occurrences available for loading */
const availableItemsForLoading = createSelector(
  getAvailableItems,

  availableItems => {
    const events = Object.values(availableItems);
    const results: EventWithItemsAndOccurrences[] = [];
    events.forEach(event => {
      results.push(generateEventData(event));
    });

    return results;
  },
);

export default availableItemsForLoading;
