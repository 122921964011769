import React from 'react';
import { Props as ParentProps } from './SettingsSelectEvent';

interface Props extends Pick<ParentProps, 'onToggleItem'> {
  itemID: string;
  itemTitle: string;
  detail?: string;
  selected: boolean;
  readonly: boolean;
}

export const SettingsTicketItem: React.FunctionComponent<Props> = props => {
  function toggle(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (props.readonly) return false;
    props.onToggleItem(props.itemID, !props.selected);
  }

  let klass = 'item-control';
  if (props.selected) {
    klass += ' item-control-selected';
  }

  if (props.readonly) {
    klass += ' item-control-readonly';
  }

  const detail = props.detail ? <span className="item-control-detail">{props.detail}</span> : false;

  return (
    <div className={klass} onClick={toggle}>
      <span className="item-control-icon">
        <i className="icon icon-checkmark" />
      </span>
      <span className="item-control-title">{props.itemTitle}</span>
      {detail}
    </div>
  );
};
