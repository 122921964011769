import React from 'react';

import { ITicket } from 'shared/Ticket';

interface Props {
  ticket: ITicket;
}

export const CheckInComments: React.FunctionComponent<Props> = props => {
  const { checkInComments } = props.ticket;

  if (checkInComments == null || !(checkInComments.length > 0)) {
    return null;
  }

  return (
    <p className="check-in-comments">
      <em className="check-in-comments-icon">*</em> {checkInComments}
    </p>
  );
};
