import * as Sentry from '@sentry/react';
import { TickitConnectionError } from 'shared/lib/jsonRequest-helpers';

export function setupErrorReporting() {
  const devEnvironment = process.env.NODE_ENV === 'development';
  Sentry.init({
    dsn:
      'https://167ca3401fe34d8abdfe76c623ab240e@o4505325319421952.ingest.sentry.io/4505371808563200',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /\/\/(api\.)?tickit\.(ca|io|test|dev)/],
      }),
      new Sentry.Replay(),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    debug: devEnvironment,
    enabled: !devEnvironment,
    environment: devEnvironment ? 'development' : 'production',
  });
}

export function setErrorReportingUser(user) {
  Sentry.setUser({ id: user, username: user });
}

export function reportError(error) {
  if (error instanceof TickitConnectionError) return;
  Sentry.captureException(error);
}
