/* eslint-disable @typescript-eslint/no-use-before-define */

// main redux middleware to handle persistence
// we put as much logic here about what to persist (e.g. whether records are valid) rather than in
// the device-specific `persist` adapters

import omit from 'lodash/omit';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import * as actions from 'shared/actions';
import { loadScans, loadTickets, markScansAsSynced, recordScan } from 'shared/actions';
import { IOrder, UNPERSISTED_KEYS as UNPERSISTED_ORDER_KEYS } from 'shared/orders';
import { IPersister, RootState } from 'shared/rootReducer';
import { IScan } from 'shared/scans';
import settingsToPersistSelector from 'shared/settingsToPersistSelector';
import { ITicketsState } from './ticketsReducer';

const SETTINGS_CHANGED_ACTIONS: Array<string> = [
  actions.updateSettings.toString(),
  actions.loadOrders.toString(),
  actions.loadTickets.toString(),
  actions.loadScans.toString(),
  actions.markScansAsSynced.toString(),
  actions.markShiftsAsSynced.toString(),
  actions.defineManifest.toString(),
  // purge has its own persist actions so doesn't need to be here
];

const PERSIST_ACTIONS: Array<string> = [
  actions.updateSettings.toString(),
  actions.loadOrders.toString(),
  actions.saveCompletedOrder.toString(),
  actions.loadTickets.toString(),
  actions.loadScans.toString(),
  actions.recordScan.toString(),
  actions.markScansAsSynced.toString(),
  actions.markShiftsAsSynced.toString(),
  actions.defineManifest.toString(),
  actions.purge.toString(),
];

/**
 * Shared middleware that listens to key actions and automatically persists records, debouncing
 * as needed.
 *
 * @param persister Injected persistence logic for either web or mobile
 */
const persistMiddlewareCreator = (persister: IPersister) => {
  if (!persister) {
    throw new Error('no ws transporter');
  }

  const persistMiddleware: Middleware<Dispatch> = (api: MiddlewareAPI) => next => (
    action: AnyAction,
  ) => {
    const result = next(action);

    if (!PERSIST_ACTIONS.includes(action.type)) {
      return result;
    }

    if (action.meta && action.meta.skipPersisting) {
      console.debug('persistMiddleware.skipPersisting', action.type);
      return result;
    }

    const state = api.getState();

    if (SETTINGS_CHANGED_ACTIONS.includes(action.type)) {
      maybePersistSettings(state, persister);
    }

    switch (action.type as string) {
      case actions.loadTickets.toString(): {
        const payload = (action as ReturnType<typeof loadTickets>).payload;
        const ticketNumbers = Object.keys(payload.tickets);
        persistTickets(state, ticketNumbers, persister);
        break;
      }

      case actions.loadScans.toString(): {
        const payload = (action as ReturnType<typeof loadScans>).payload;
        const uuids = payload.scans.filter(s => s.valid).map(s => s.uuid);

        persistScans(state, uuids, persister);
        break;
      }

      case actions.recordScan.toString(): {
        const payload = (action as ReturnType<typeof recordScan>).payload;
        if (!payload.valid) break;

        persistScans(state, [payload.uuid], persister);
        break;
      }

      case actions.markScansAsSynced.toString(): {
        const uuids = (action as ReturnType<typeof markScansAsSynced>).payload;
        persistScans(state, uuids, persister);
        break;
      }

      case actions.saveCompletedOrder.toString(): {
        const uuid = (action as ReturnType<typeof actions.saveCompletedOrder>).payload.uuid;
        const order = state.orders.orders[uuid];

        if (!order) break;
        if (!order.completed || !order.success) break;

        persistOrders([order], persister);
        break;
      }

      case actions.loadOrders.toString(): {
        const payload = (action as ReturnType<typeof actions.loadOrders>).payload;
        const orders: IOrder[] = [];

        payload.forEach(order => {
          if (!order || !order.completed || !order.success) return;
          orders.push(order);
        });

        if (orders.length < 1) break;
        persistOrders(orders, persister);
        break;
      }

      case actions.purge.toString(): {
        persister.purge();
        break;
      }
      case actions.updateSettings.toString():
      case actions.markShiftsAsSynced.toString():
      case actions.defineManifest.toString(): {
        // already done
        break;
      }
      default: {
        console.warn('Unsupported persist action', action.type);
      }
    }

    return result;
  };
  return persistMiddleware;
};

// track the last JSON blob we stored so we can avoid re-persisting if only
// unpersisted settings keys have changed
let lastPersistedSettingsJSON = '';

/**
 * Only persist settings are elligible to be persisted, and only if they have actually changed.
 */
function maybePersistSettings(state: any, persister: IPersister): void {
  const settings = settingsToPersistSelector(state);
  if (Object.keys(settings).length < 1) return;

  const settingsJSON = JSON.stringify(settings);
  if (settingsJSON === lastPersistedSettingsJSON) {
    console.debug('persistMiddleware.persistSettings skip unchanged');
    return;
  }

  persister.persistSettings(settingsJSON);
  lastPersistedSettingsJSON = settingsJSON;
}

function persistScans(state: RootState, uuids: string[], persister: IPersister): void {
  if (uuids.length < 1) return;
  const scans: Array<IScan> = [];
  uuids.forEach(uuid => {
    const scan = state.scans.scansByUUID[uuid];
    if (!scan) return;
    if (!scan.valid) return;
    scans.push(scan);
  });

  if (scans.length < 1) return;

  persister.persistScans(scans);
}

function persistTickets(state: RootState, ticketNumbers: string[], persister: IPersister): void {
  if (ticketNumbers.length < 1) return;
  const tickets: ITicketsState = {};

  ticketNumbers.forEach(ticketNumber => {
    const ticket = state.tickets[ticketNumber];
    if (!ticket || ticket === undefined) return;
    tickets[ticket.ticketNumber] = ticket;
  });

  persister.persistTickets(tickets);
}

function persistOrders(orders: IOrder[], persister: IPersister): void {
  if (orders.length < 1) return;

  // filter unpersisted keys (e.g. cardNumber)
  const filteredOrders = orders.map(o => omit(o, UNPERSISTED_ORDER_KEYS) as IOrder);
  persister.persistOrders(filteredOrders);
}

export { persistMiddlewareCreator as default };
