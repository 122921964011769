import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSettings } from 'shared/actions';
import { AppThunkDispatch, RootState } from 'shared/rootReducer';
import { getShiftSettings, SetupScreens } from 'shared/settingsReducer';
import { statusBarSelector } from 'shared/statsSelector';
import { openSetup, sync } from 'shared/syncActions';
import ConnectivityContext, { ConnectivityContextState } from './ConnectivityContext';

const StatusBar: React.FunctionComponent<PassedProps> = props => {
  const [syncing, setSyncing] = useState(false);

  function switchTheme(event) {
    event.preventDefault();
    props.updateSettings({
      currentTheme: props.currentTheme === 'dark' ? 'light' : 'dark',
    });
    props.selectInput();
  }

  function toggleScanHistory(event) {
    event.preventDefault();
    props.toggleScanHistory();
  }

  function renderScannerName() {
    const scannerStaffNames = props.scannerStaffNames ? props.scannerStaffNames : 'Unknown Staff';
    const label = (
      <React.Fragment>
        {props.scannerName} ({scannerStaffNames})
      </React.Fragment>
    );
    return (
      <button
        className="link-button"
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          props.toggleDeviceName();
        }}
      >
        {label}
      </button>
    );
  }

  async function sync(event: React.MouseEvent) {
    event.preventDefault();
    setSyncing(true);
    await props.sync(true);
    setSyncing(false);
  }

  function renderSyncStatus() {
    if (!props.hasCheckinPermission) return null;

    let label = 'Unknown';
    let disabled = false;

    if (!props.isOnline) {
      label = 'Offline';
      disabled = true;
    } else if (syncing) {
      label = 'Syncing...';
      disabled = true;
    } else {
      label = props.lastSync;
    }

    return (
      <button className="link-button" onClick={sync} data-testid="sync-btn" disabled={disabled}>
        {label}
      </button>
    );
  }

  function scanHistory() {
    if (!props.hasCheckinPermission) return null;
    return (
      <button onClick={toggleScanHistory} aria-label="Show scan history" className="link-button">
        <i className="icon icon-history" aria-hidden />
      </button>
    );
  }

  function renderTicketCount() {
    if (!props.hasCheckinPermission) return null;
    if (!props.hasGlobalStatsPermission) return null;
    const { ticketCount } = props;
    return <React.Fragment>{ticketCount} tickets</React.Fragment>;
  }

  function renderShareConfig() {
    if (!props.hasCheckinPermission) return null;
    return (
      <button
        className="link-button"
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          props.openSetup(SetupScreens.ConfigureQR);
        }}
      >
        Export Config
      </button>
    );
  }

  function renderThemeSwitcher() {
    return (
      <button onClick={switchTheme} className="link-button" aria-label="Change colour scheme">
        <i className="icon icon-invert-colors" aria-hidden />
      </button>
    );
  }

  if (!props.hasCheckinPermission) {
    return null;
  }

  const chunks = [
    renderScannerName(),
    renderTicketCount(),
    renderSyncStatus(),
    renderShareConfig(),
  ].reduce((prev, curr) => (
    <React.Fragment>
      {prev} <span className="sep">/</span> {curr}
    </React.Fragment>
  ));

  return (
    <div className="status-text">
      {chunks}
      <span className="icon-links">
        {renderThemeSwitcher()}
        {scanHistory()}
      </span>
    </div>
  );
};

const StatusBarContainer: React.FunctionComponent<Props> = props => {
  return (
    <ConnectivityContext.Consumer>
      {context => {
        return (
          <StatusBar
            {...props}
            isOnline={context.isOnline}
            hasCheckinPermission={context.hasCheckinPermission}
            hasGlobalStatsPermission={context.hasGlobalStatsPermission}
          />
        );
      }}
    </ConnectivityContext.Consumer>
  );
};

function mapStateToProps(state: RootState) {
  const { scannerName, scannerStaffNames } = getShiftSettings(state);
  const { lastSync, ticketCount, currentTheme, hasScansThisShift } = statusBarSelector(state);

  return {
    scannerName,
    scannerStaffNames,
    lastSync,
    ticketCount,
    currentTheme,
    hasScansThisShift,
  };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) =>
  bindActionCreators(
    {
      updateSettings,
      openSetup,
      sync,
    },
    dispatch,
  );

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface Props extends StateProps, DispatchProps {
  selectInput: () => void;
  toggleScanHistory: () => void;
  toggleDeviceName: () => void;
}

export interface PassedProps
  extends Props,
    Pick<
      ConnectivityContextState,
      'isOnline' | 'hasCheckinPermission' | 'hasGlobalStatsPermission'
    > {}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBarContainer);
