import { v4 as uuidv4 } from 'uuid';
import addTicketTypeToTicket from './addTicketTypeToTicket';
import getAnswersForTicket, { getQuestions } from './getAnswersForTicket';
import { IItem } from './items';
import { RootState } from './rootReducer';
import { IScan } from './scans';
import { detectTestScan } from './testTickets';
import { getTicketTypes } from './ticketTypeSelector';
import TickitUtils from './Utils';

interface Meta {
  reversal?: boolean;
}

// preserveScanScreen is set to TRUE when admitting a ticket from the search
// results, allowing us to leave the scan screen in its previous state
//
// NOTE: if you update this you should also update ./testTickets to make the
// messaging match
export default function validateScan(
  state: RootState,
  ticketNumber: string,
  meta: Meta = {},
): IScan {
  let { reversal } = meta;
  const { settings, scans } = state;

  const testScan = detectTestScan(settings, ticketNumber);
  if (testScan) return testScan;

  ticketNumber = TickitUtils.normalizeTicketNumber(ticketNumber);

  let ticket = state.tickets[ticketNumber]
    ? Object.assign({}, state.tickets[ticketNumber])
    : undefined;
  let lastScan: IScan | undefined = undefined;

  if (!reversal) reversal = false;

  const currentScan: IScan = {
    uuid: uuidv4(),
    itemID: undefined,
    ticketNumber,
    scannedAt: new Date().getTime(),

    location: settings.scannerName,
    scannerID: settings.scannerID,
    scannerUserAgent: settings.scannerUserAgent,
    shift: settings.currentShiftDigest,
    valid: false,
    synced: false,
    ticketFound: false,
    reversal,
    message: '',
    unlimitedCheckins: false,
  };

  if (!ticket) {
    currentScan.message = 'Not found';
    return currentScan;
  }

  const ticketTypes: Map<string, IItem> = getTicketTypes(state);
  ticket = addTicketTypeToTicket(ticket, ticketTypes);

  if (ticket.qa && ticket.qa.length > 0) {
    const questions = getQuestions(state);
    ticket.answers = getAnswersForTicket(ticket, questions);
  }

  currentScan.unlimitedCheckins = ticket.unlimitedCheckins;
  currentScan.ticketFound = true;
  currentScan.ticket = ticket;
  currentScan.itemID = ticket.itemID;
  currentScan.seatSection = ticket.seatSection;
  currentScan.seatRow = ticket.seatRow;
  currentScan.seatNumber = ticket.seatNumber;

  if (ticket.deleted) {
    currentScan.message = 'Cancelled ticket';
    currentScan.details = ticket.deletedReason;
    return currentScan;
  }

  if (ticket.unlimitedCheckins) {
    if (reversal) {
      currentScan.message = 'Can not reverse unlimited check-in tickets';
      return currentScan;
    }
  }

  if (!ticket.unlimitedCheckins) {
    lastScan = scans.scansByTicketNumber[ticketNumber];
  }

  // last scan was an admit an and this scan is too means already admitted
  if (lastScan && !lastScan.reversal && !currentScan.reversal) {
    currentScan.message = 'Already admitted';
    const previousDate = TickitUtils.formattedTimestamp(lastScan.scannedAt);
    const previousAgo = TickitUtils.timestampAgo(lastScan.scannedAt);
    currentScan.details = `Admitted on ${previousDate} (${previousAgo}) at ${lastScan.location}`;
    return currentScan;
  }

  // all good
  currentScan.valid = true;
  currentScan.message = currentScan.reversal ? 'Reversed!' : ticket.itemTitle;
  return currentScan;
}
