import React from 'react';
import { ITicket } from 'shared/Ticket';

interface Props {
  ticket: ITicket;
}
const Seat: React.FunctionComponent<Props> = props => {
  const { seatSection, seatRow, seatNumber } = props.ticket;
  if (!seatNumber) return null;

  const output = seatSection
    ? `${seatRow}\u00a0${seatNumber} - ${seatSection}`
    : `${seatRow}\u00a0${seatNumber}`;

  return <span className="seat">{output}</span>;
};

export default Seat;
