import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import React from 'react';
import { ITicket } from 'shared/Ticket';
import { ErrorBoundary } from './ErrorBoundary';
import { SearchResultRow } from './SearchResultRow';

interface Props {
  searchResults: Array<ITicket>;
  searchTerms: string;
  onValidate: (ticketNumber: string) => void;
  scrollToTop: () => void;
  toggleScanHistory: () => void;
  selectInput: () => void;
}

export class SearchResults extends React.Component<Props> {
  public static defaultProps = {
    searchResults: [],
    searchTerms: '',
  };

  ticketCacheKeys: Array<string>;

  constructor(props) {
    super(props);
    this.ticketCacheKeys = map(this.props.searchResults, ticket => {
      if (!ticket.cacheKey) {
        console.warn('missing cacheKey - search results may not updated properly', ticket);
      }
      return ticket.cacheKey || ticket.ticketNumber;
    });
  }

  shouldComponentUpdate(nextProps) {
    const nextTicketCacheKeys: Array<string> = map(
      nextProps.searchResults,
      ticket => ticket.cacheKey,
    );

    // do we have different cache keys?
    if (!isEqual(this.ticketCacheKeys, nextTicketCacheKeys)) return true;
    if (nextProps.searchTerms !== this.props.searchTerms) return true;
    return false;
  }

  scrollToTop = event => {
    event.preventDefault();
    this.props.scrollToTop();
  };

  toggleScanHistory = event => {
    event.preventDefault();
    this.props.toggleScanHistory();
  };

  renderPlaceholder() {
    return (
      <div className="search-results-placeholder">
        <p>If you’re using a scanner, make sure the cursor is in the field above.</p>

        <p>
          Look up your attendees by name or ticket number. You can omit the ”#“ sign when searching
          by ticket number.
        </p>

        <p>
          Use the Admit button to record entry, or the Reverse button if you accidentally admitted a
          ticket. You can also{' '}
          <a href="#toggleScanHistory" onClick={this.toggleScanHistory}>
            click to view your recent scans
          </a>
          .
        </p>

        <p>Click on any search result for more ticket details.</p>
      </div>
    );
  }

  render() {
    // no search performed, so don't show a 'no results' message
    if (this.props.searchTerms.length < 2) return this.renderPlaceholder();
    this.ticketCacheKeys = this.props.searchResults.map(
      ticket => ticket.cacheKey || ticket.ticketNumber,
    );

    const className = ['search-results'];
    let backToTop, message;

    if (this.ticketCacheKeys.length > 0) {
      className.push('has-results');
      backToTop = (
        <div className="back-to-top">
          <button className="btn btn-outlined" onClick={this.scrollToTop}>
            Return to top
          </button>
        </div>
      );
    } else {
      className.push('no-results');
      if (this.props.searchTerms.length > 0) {
        message = `No search results for “${this.props.searchTerms}”`;
      }
    }

    const rows = map(this.props.searchResults, ticket => {
      return (
        <SearchResultRow
          ticket={ticket}
          key={ticket.cacheKey}
          onValidate={this.props.onValidate}
          selectInput={this.props.selectInput}
        />
      );
    });

    return (
      <div className={className.join(' ')}>
        <ErrorBoundary>
          <p className="message">{message}</p>
          <div className="search-results-rows">{rows}</div>
          {backToTop}
        </ErrorBoundary>
      </div>
    );
  }
}
