import {
  ERROR_MESSAGES,
  TickitConnectionError,
  API_URL,
  RequestHeaders,
} from 'shared/lib/jsonRequest-helpers';
import { debugNetwork } from 'shared/debug';

const REQUEST_TIMEOUT = 120 * 1000;
const requests: Array<XMLHttpRequest> = [];

function authorizationHeader(username: string, password: string): string {
  const encoded = btoa(`${username}:${password}`);
  return `Basic ${encoded}`;
}

const debugRequest = (request: XMLHttpRequest, requestHeaders: RequestHeaders, body = '') => {
  debugNetwork(
    `HTTP ${request.status}: ${request.responseURL}, state?: ${request.readyState}, statusText: ${
      request.statusText
    }, responseText: ${request.responseText}, headers: ${JSON.stringify(
      requestHeaders,
    )}, body: ${body}`,
  );
};

function platformRequest(
  method: string,
  endpoint: string,
  requestHeaders: RequestHeaders,
  data?: Record<string, unknown>,
): Promise<Record<string, unknown> | null> {
  delete requestHeaders['User-Agent']; // not allowed via a web browser

  const body = data ? JSON.stringify(data) : null;

  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    // request.id = requests.length;

    // This isn't useful since our gzip'd responses don't have a content-length
    // header - https://github.com/alexdunae/tickit-mobile/issues/86
    // request.addEventListener('progress', function(progressEvent) {
    //   debugNetwork(`endpoint progressEvent, len: ${progressEvent.lengthComputable},
    //     loaded: ${progressEvent.loaded}/${progressEvent.total}
    //   `);
    // });

    request.onreadystatechange = () => {
      if (request.readyState !== XMLHttpRequest.DONE) {
        return;
      }

      // if (request.aborting) {
      //   reject(false);
      //   return;
      // }

      switch (request.status) {
        case 200:
        case 201:
        case 422:
          // API's response when the request was fine but the record was invalid
          if (request.responseText) {
            debugNetwork(`${endpoint} downloaded`);
            resolve(JSON.parse(request.responseText));
          } else {
            resolve({});
          }
          break;

        default:
          debugRequest(request, requestHeaders);
          reject(
            new TickitConnectionError(
              ERROR_MESSAGES[request.status.toString()] || ERROR_MESSAGES['500'],
              endpoint,
              requestHeaders,
            ),
          );
          break;
      }
    };

    request.open(method, endpoint, true);
    request.timeout = REQUEST_TIMEOUT;

    Object.keys(requestHeaders).forEach(key => {
      request.setRequestHeader(key, requestHeaders[key]);
    });

    request.send(body);
    requests.push(request);
  });
}

interface JSONRequestSettings {
  scannerUserAgent: string;
  storeSlug: string;
  storeKey: string;
}

export default function jsonRequest(
  settings: JSONRequestSettings,
  method: 'GET' | 'POST',
  endpoint: string,
  data?: Record<string, unknown>,
): Promise<Record<string, unknown> | null> {
  const requestHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/json; charset=UTF-8',
    'User-Agent': settings.scannerUserAgent,
    Authorization: authorizationHeader(settings.storeSlug, settings.storeKey),
  };

  endpoint = `${API_URL}${endpoint}`;
  console.warn('jsonRequest', endpoint);
  debugNetwork(`${method} ${endpoint}`);
  return platformRequest(method, endpoint, requestHeaders, data);
}

window.jsonRequest = jsonRequest;
