import Dinero from 'dinero.js';
import createTokenCurrency from './createTokenCurrency';
export interface MoneyCreatable {
  currency: string;
  amount: number;
  precision?: number;
}

export const DEFAULT_CURRENCIES = {
  CAD: {
    iso_code: 'CAD',
    subunit_to_unit: 100,
    name: 'Canadian Dollar',
    symbol: '$',
    fiat: true,
  },
  AUD: {
    iso_code: 'AUD',
    subunit_to_unit: 100,
    name: 'Australian Dollar',
    symbol: '$',
    fiat: true,
  },
  USD: {
    iso_code: 'USD',
    subunit_to_unit: 100,
    name: 'US Dollar',
    symbol: '$',
    fiat: true,
  },
};

export const FIAT_CURRENCIES = Object.keys(DEFAULT_CURRENCIES);

const TOKEN_CURRENCY_RE = new RegExp(/^TT-/i);

export function isTokenCurrency(currency?: string): boolean {
  if (!currency) return false;
  return TOKEN_CURRENCY_RE.test(String(currency));
}

export function fiat(cents: number): Dinero.Dinero {
  try {
    return Dinero({ amount: cents, currency: 'CAD' });
  } catch (error) {
    console.error('error', error, cents);
    return Dinero({ amount: 0, currency: 'CAD' });
  }
}

export function tokens(units: number, currency: string): Dinero.Dinero {
  if (!isTokenCurrency(currency))
    throw new Error(`Valid token currency must be provided (${units}, ${currency})`);

  return createTokenCurrency(units, currency);
}

export function formatMoney(dinero: Dinero.Dinero, displayUnits = true): string {
  // console.debug('formatMoney', dinero.toJSON(), dinero.toUnit())
  let units = dinero.toUnit();

  if (!units || isNaN(units)) {
    units = 0;
  }

  if (isTokenCurrency(dinero.getCurrency())) {
    if (!displayUnits) return String(units);
    const pluralized = units === 1.0 ? 'token' : 'tokens';
    return `${units} ${pluralized}`;
  } else {
    return `$${units.toFixed(2)}`;
  }
}

export function moneyFromObject(object?: MoneyCreatable) {
  if (!object) {
    throw new Error('Null passed to moneyFromObject');
  }
  try {
    if (isTokenCurrency(object.currency)) {
      return tokens(object.amount, object.currency);
    } else {
      return fiat(object.amount);
    }
  } catch (error) {
    console.error('error', error, object);
    throw error;
  }
}
