import { IItem, IItemPrice, IItemPrices } from './items';
import { IEventWithItems } from './settingsReducer';

type IEventsWithItems = Array<IEventWithItems>;

function parseItemPrices(pricesJSON: IServerPricesJSON): IItemPrices {
  const prices: IItemPrices = {};
  Object.keys(pricesJSON).forEach(currency => {
    const priceJSON = pricesJSON[currency];

    const taxPercent = parseFloat(priceJSON.taxPercent);

    const parsed: IItemPrice = {
      fiat: !!priceJSON.fiat,
      currency,
      basePriceCents: priceJSON.basePriceCents,
      tickitFeesCents: priceJSON.tickitFeesCents || 0,
      sellerFeesCents: priceJSON.sellerFeesCents || 0,
      unitTaxCents: priceJSON.unitTaxCents || 0,
      displayFeesCents: priceJSON.displayFeesCents || 0,
      displayPriceCents: priceJSON.displayPriceCents || 0,
      taxPercent: isNaN(taxPercent) ? 0.0 : taxPercent,
    };

    prices[currency] = parsed;
  });

  return prices;
}

export function parseManifestItem(itemJSON: IItemJSON): IItem {
  let occurrenceDate: number | undefined;
  let occurrenceStartsAt: number | undefined;
  let occurrenceEntireDay: boolean | undefined;

  if (itemJSON.event_occurrence_id) {
    occurrenceDate = itemJSON.occurrence_date ? Date.parse(itemJSON.occurrence_date) : undefined;
    occurrenceStartsAt = new Date((itemJSON.occurrence_starts_at || 0) * 1000).getTime();
    occurrenceEntireDay = itemJSON.occurrence_entire_day;
  }

  const quantityAvailable =
    itemJSON.quantity_available == undefined || itemJSON.quantity_available == null
      ? undefined
      : itemJSON.quantity_available;

  return {
    eventID: String(itemJSON.event_id),
    eventTitle: itemJSON.event_title || '',
    itemID: String(itemJSON.item_id),
    itemTitle: itemJSON.item_title,
    generatedTokenCurrency: itemJSON.generatedTokenCurrency || null,
    tokensToGenerateCents: itemJSON.tokensToGenerateCents || 0,
    itemGroupTitle: itemJSON.item_group_title || undefined,
    itemGroupPosition: itemJSON.item_group_position || 0,
    itemPosition: itemJSON.item_position || 0,
    quantityAvailable: quantityAvailable,
    purchasable: itemJSON.purchasable,
    scannable: itemJSON.scannable,
    assignedSeating: itemJSON.assigned_seating || false,
    unlimitedCheckins: itemJSON.unlimited_checkins,
    prices: parseItemPrices(itemJSON.prices),
    occurrenceID: itemJSON.event_occurrence_id,
    occurrenceDate,
    occurrenceStartsAt,
    occurrenceEntireDay,
  };
}

// returns a settings object subset
export default function parseManifestReponse(data): IEventsWithItems {
  const availableItems: IEventsWithItems = [];
  data.events.forEach(event => {
    if (!event.items) return;

    const items = event.items.map(itemJSON => {
      const parsedItem = parseManifestItem(itemJSON);
      return parsedItem;
    });

    const eventStartsAt = event.event_starts_at
      ? new Date(event.event_starts_at * 1000).getTime()
      : null;
    const multipleStartTimes = !!event.multiple_start_times;

    const seatMapUrl = event.seat_map_url;
    const seatWebviewUrl = event.seat_webview_url;
    const seatAvailabilityUrl = event.seat_availability_url;
    const assignedSeating = !!seatMapUrl && !!seatAvailabilityUrl;

    availableItems.push({
      eventID: String(event.event_id),
      eventTitle: event.event_title,
      eventStartsAt,
      multipleStartTimes,
      items,
      seatMapUrl,
      seatWebviewUrl,
      seatAvailabilityUrl,
      assignedSeating,
    });
  });

  return availableItems;
}

export interface IItemJSON {
  event_id: string | number;
  event_title?: string | null | undefined;
  item_id: string | number;
  item_title: string;
  generatedTokenCurrency?: string | null | undefined;
  tokensToGenerateCents?: number;
  item_group_title?: string | null | undefined;
  item_group_position?: number | null | undefined;
  item_position?: number | null | undefined;
  quantity_available?: number | null | undefined;
  purchasable: boolean;
  scannable: boolean;
  unlimited_checkins: boolean;
  assigned_seating?: boolean;
  event_occurrence_id?: string;
  occurrence_starts_at?: number;
  occurrence_date?: string;
  occurrence_entire_day?: boolean;
  prices: IServerPricesJSON;
}

interface IServerPricesJSON {
  [index: string]: IServerPriceJSON;
}

export interface IServerPriceJSON {
  fiat: boolean;
  currency: string;
  basePriceCents: number;
  tickitFeesCents?: number;
  sellerFeesCents?: number;
  unitTaxCents?: number;
  taxPercent: string;
  displayFeesCents: number;
  displayPriceCents: number;
}

export interface ManifestJSONResponse {
  hasCheckinPermission: boolean;
  hasGlobalStatsPermission: boolean;
  currencies: {
    [key: string]: ManifestCurrencyJSONResponse;
  };
  events: Array<ManifestEventJSONResponse>;
}

interface ManifestEventJSONResponse {
  event_id: string;
  event_title: string;
  event_position: number;
  event_starts_at: number | null;
  multiple_start_times: boolean;
  items: Array<ManifestItemJSONResponse>;
  seat_map_url?: string | null;
  seat_webview_url?: string | null;
  seat_availability_url?: string | null;
  occurrences?: {
    [key: string]: {
      timeslot_count: number;
    };
  };
}

interface ManifestItemJSONResponse {
  event_id: string;
  event_title: string | null;
  item_id: string;
  item_title: string;
  item_group_title: string | null;
  item_group_position: number | null;
  item_position: number | null;
  ticket_terms: string | null;
  ticket_instructions: string | null;
  quantity_available: number | null;
  unlimited_checkins: boolean;
  assigned_seating?: boolean;
  scannable: boolean;
  purchasable: boolean;
  tokensToGenerateCents: number;
  generatedTokenCurrency: string;
  occurrence_starts_at?: number;
  occurrence_date?: string;
  occurrence_entire_day?: boolean;
  event_occurrence_id?: string;
  prices: {
    [key: string]: ManifestItemPriceJSONResponse;
  };
}

interface ManifestItemPriceJSONResponse {
  currency: string;
  fiat: boolean;
  basePriceCents: number;
  tickitFeesCents: number;
  sellerFeesCents: number;
  unitTaxCents: number;
  taxPercent: string;
  displayPriceCents: number;
  displayFeesCents: number;
}

interface ManifestCurrencyJSONResponse {
  currency: string;
  iso_code: string;
  subunit_to_unit: number;
  name: string;
  symbol: string;
  fiat: boolean;
  subunit?: string;
  symbol_first?: boolean;
  thousands_separator?: string;
}
