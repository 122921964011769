import { createReducer } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import * as actions from './actions';
import { IItem } from './items';
import { printerStatuses } from './printerStatuses';
import { RootState } from './rootReducer';
import {
  calculateShiftDigest,
  ICheckinShift,
  maybeUpdateCurrentShiftDigest,
} from './settings/checkinShift';
import {
  defineManifest,
  filterAvailableItems,
  getItemIDsFromEvents,
  getOccurrenceFromEvents,
  readyToScan,
  readyToSell,
} from './settings/defineManifest';

export enum SetupScreens {
  FetchManifests = 'fetch_manifests',
  LoadingManifest = 'loading_manifest',
  ConfigureQR = 'config_qr', // web only
}

export interface IQuestion {
  label: string;
  question_type: string;
}

export enum UIScreen {
  scan = 'scan',
  purchase = 'purchase',
  search = 'search',
  stats = 'stats',
  settings = 'settings',
}

// debug helper to allow use to watch certain settings keys as they flow through the system
const DEBUG_WATCH_KEYS = []; // ['availableItems', 'scannableItemIDs', 'scanningItemIDs'];

const LAST_SYNC_FIELDS = [
  'hasTicketsSince',
  'hasScansSince',
  'lastTicketPull',
  'lastTicketReceived',
  'lastScanPull',
  'lastScanReceived',
];

// these are the keys that are allowed to be set via updateSettings
const SETTINGS_KEYS = [
  'currencies',

  'lastTicketReceived',
  'lastScanReceived',
  'lastTicketPull',
  'lastScanPull',
  'lastScanPost',
  'hasTicketsSince',
  'hasScansSince',
  'scannerName',
  'scannerID',
  'scannerUserAgent',
  'scannerStaffNames',
  'currentShiftDigest',
  'shifts',
  'availableItems',
  'scannableItems',
  'scannableItemIDs',
  'itemsForSale',
  'purchasableItems',
  'purchasableItemIDs',
  'storeSlug',
  'storeKey',
  'scanningItemIDs',
  'sellingItemIDs',
  'scanningOccurrenceIDs',
  'sellingOccurrenceIDs',
  'questions',
  'setupScreen',
  'playSounds',
  'storeCredentialsOK',
  'printerCapable',
  'lineaScannerCapable',
  'hasBuiltinBarcodeReader',
  'useHardwareBarcodeReader',
  'stripeTerminalCapable',
  'enableStripeTerminal',
  'useHeadphoneMagStripeReader',
  'stripeTerminalConnectionToken',
  'printerIP',
  'printerStatus',
  'printerError',
  'currentTheme',
  'currentScreen',
  'processingOrder',
  'hasCheckinPermission',
  'hasGlobalStatsPermission',
  'requirePurchaseName',
  'requirePurchaseEmail',
  'suggestedTipPercents',
  'importingManifest',
];

const UNPERSISTED_KEYS = [
  'currentScreen',
  'currentShiftDigest',

  'printerCapable',
  'printerConnected',
  'printerError',
  'printerStatus',
  'processingOrder',
  'scannerUserAgent',
  'setupScreen',
  'lineaScannerCapable',
  'stripeTerminalConnectionToken',
  'stripeTerminalCapable,',
  'importingManifest',
];

export function debugSettingsKeys(settings: Partial<ISettingsState>, label: string) {
  if (!console.table || DEBUG_WATCH_KEYS.length < 1) return;
  const vals = {};
  DEBUG_WATCH_KEYS.forEach(keyName => {
    return (vals[keyName] = cloneDeep(settings[keyName]));
  });

  const output = {};
  output[label] = vals;

  console.table(output);
}
const INITIAL_STATE: ISettingsState = {
  currencies: {}, // currency, iso_code, name, symbol, subunit_to_unit

  setupScreen: undefined,
  playSounds: true,

  // these are used for generating the since param in API calls
  hasTicketsSince: null,
  hasScansSince: null,

  // these are displayed in the setup screen
  lastTicketReceived: null,
  lastScanReceived: null,

  // these are used for displaying connection history
  lastTicketPull: null,
  lastScanPull: null,
  lastScanPost: null,
  lastLocalScan: null,

  currentTheme: 'dark', // only used in web checkin

  availableItems: [], // the event-item map of all items we known about from the manifest
  availableItemIDs: [],
  scannableItems: [], // the event-item map of items ALLOWED for scanning
  scannableItemIDs: [],
  purchasableItems: [], // the event-item map of items ALLOWED for sale
  purchasableItemIDs: [],
  itemsForSale: [], // the event-item map of items actually ON SALE in the purchase section
  scanningItemIDs: [], // these are the currently selected IDs for scanning
  sellingItemIDs: [], // these are the currently selected IDs for selling
  questions: {},

  availableOccurrenceIDs: [],
  availableOccurrences: [],
  scanningOccurrenceIDs: [],
  sellingOccurrenceIDs: [],

  scannerName: 'Box Office',
  scannerID: '',
  scannerStaffNames: '',
  currentShiftDigest: '',
  checkinShifts: {},

  scannerUserAgent: '',
  storeSlug: '',
  storeKey: '',
  storeCredentialsOK: false,

  lastSync: null,
  readyToScan: false,
  readyToSell: false,

  hasCheckinPermission: true,
  hasGlobalStatsPermission: true,

  printerIP: '',
  printerCapable: false,
  printerStatus: printerStatuses.notConnected,
  printerError: undefined,
  printerConnected: false, // convenience method -- updated based on the printerStatus setting

  lineaScannerCapable: false,
  hasBuiltinBarcodeReader: false,
  useHardwareBarcodeReader: false,

  stripeTerminalCapable: true,
  enableStripeTerminal: false,
  useHeadphoneMagStripeReader: false,
  stripeTerminalConnectionToken: null,

  processingOrder: false,

  requirePurchaseName: false,
  requirePurchaseEmail: false,

  suggestedTipPercents: [0, 10, 20, 30, 50], // TODO

  currentScreen: UIScreen.scan, // only used on mobile

  importingManifest: false,
};

function settingsAfterPurge(state: ISettingsState): Partial<ISettingsState> {
  // preserve a few key settings
  // TODO: we should also preserve NFC capabilities, battery status, etc...
  return {
    ...INITIAL_STATE,
    scannerID: state.scannerID,
    scannerUserAgent: state.scannerUserAgent,
  };
}

function lastSync(settings: Partial<ISettingsState>): number {
  return max(compact(LAST_SYNC_FIELDS.map(key => settings[key]))) || null;
}

function updateSettings(state: ISettingsState, newSettings: Partial<ISettingsState>): void {
  const newSettingsFields: string[] = [];

  const validNewSettings = cloneDeep(newSettings) as Partial<ISettingsState>;

  Object.keys(validNewSettings).forEach(field => {
    if (!SETTINGS_KEYS.includes(field)) {
      delete validNewSettings[field];
      return;
    }

    newSettingsFields.push(field);
    state[field] = validNewSettings[field];
  });

  if (newSettingsFields.length < 1) return;

  // TODO: not working yet
  // only one card reader type allowed at a time
  if (validNewSettings.enableStripeTerminal) {
    state.useHeadphoneMagStripeReader = false;
  } else if (validNewSettings.useHeadphoneMagStripeReader) {
    state.enableStripeTerminal = false;
  }

  maybeUpdateCurrentShiftDigest(state, validNewSettings);

  const availableItemIDs = getItemIDsFromEvents(state.availableItems || []);
  if (state.availableItems) {
    state.availableItems = state.availableItems.map(event => {
      event.data = event.items;
      return event;
    });
  }

  state.availableOccurrences = getOccurrenceFromEvents(state.availableItems || []);
  state.availableOccurrenceIDs = state.availableOccurrences.map(o => o.occurrenceID);

  // compare availableItemIDs with what's already loaded, and only keep items
  // that match – used when the store stays the same between runs through the
  // setup screen
  state.scanningItemIDs = intersection(state.scanningItemIDs || [], availableItemIDs).sort();
  state.sellingItemIDs = intersection(state.sellingItemIDs || [], availableItemIDs).sort();

  if (state.hasCheckinPermission === false) {
    state.scanningItemIDs = [];
  }

  state.scannableItems = filterAvailableItems(
    'scannable',
    state.availableItems || [],
    state.scanningItemIDs,
    false,
  );
  state.scannableItemIDs = getItemIDsFromEvents(state.scannableItems);

  state.purchasableItems = filterAvailableItems(
    'purchasable',
    state.availableItems || [],
    state.sellingItemIDs,
    false,
  );
  state.purchasableItemIDs = getItemIDsFromEvents(state.purchasableItems);

  state.itemsForSale = filterAvailableItems(
    'purchasable',
    state.availableItems || [],
    state.sellingItemIDs,
    true,
  );

  state.readyToScan = readyToScan(state);
  state.readyToSell = readyToSell(state);
  if (state.printerCapable) {
    if (isEmpty(state.printerIP)) {
      state.printerStatus = printerStatuses.noPrinter;
      state.printerConnected = false;
    } else {
      state.printerConnected = state.printerStatus === printerStatuses.connected;
    }
  } else {
    state.printerConnected = false;
    state.printerStatus = printerStatuses.notCapable;
  }
  state.lastSync = lastSync(state);

  debugSettingsKeys(state, 'afterUpdateSettings');
}

function markShiftsAsSynced(state: ISettingsState, syncedShiftDigests: Array<string>): void {
  syncedShiftDigests.forEach(shiftDigest => {
    if (state.checkinShifts[shiftDigest] && state.checkinShifts[shiftDigest].unsynced) {
      state.checkinShifts[shiftDigest].unsynced = false;
    }
  });
}

// const CONFIG_SHARING_FIELDS = ['storeSlug', 'storeKey', 'scanningItemIDs', 'sellingItemIDs'];

const getScanSettings = (
  state: RootState,
): Pick<ISettingsState, 'playSounds' | 'readyToScan' | 'importingManifest'> => {
  const { playSounds, readyToScan, importingManifest } = state.settings;
  return { playSounds, readyToScan, importingManifest };
};

export const getSyncSettings = (state: RootState): ISyncSettings => {
  const {
    storeKey,
    storeSlug,
    scannerUserAgent,
    scanningItemIDs,
    hasScansSince,
    hasTicketsSince,
    currentShiftDigest,
  } = state.settings;
  return {
    storeKey,
    storeSlug,
    scannerUserAgent,
    scanningItemIDs,
    hasScansSince,
    hasTicketsSince,
    currentShiftDigest,
  };
};

const getShiftSettings = (state: RootState): Partial<ISettingsState> => {
  const { scannerName, scannerStaffNames, currentShiftDigest, currentTheme } = state.settings;
  return { scannerName, scannerStaffNames, currentShiftDigest, currentTheme };
};

const getSetupScreen = (state: RootState): SetupScreens | undefined => state.settings.setupScreen;

const getCurrencies = (state: RootState) => state.settings.currencies || {};

const getItemsForSale = (state: RootState) => {
  return state.settings.itemsForSale || [];
};
const getUnsyncedShifts = (state: RootState): Array<ICheckinShift> => {
  const settings: ISettingsState = state.settings;

  return Object.values(settings.checkinShifts).filter(shift => {
    return shift.unsynced;
  });
};

const getConfigSettings = (state: RootState) => {
  const {
    readyToScan,
    readyToSell,
    setupScreen,
    storeKey,
    storeSlug,
    currentTheme,
    currentScreen,
    hasCheckinPermission,
    hasGlobalStatsPermission,
    printerIP,
    printerStatus,
    printerConnected,
    processingOrder,
    printerCapable,
    lineaScannerCapable,
    useHardwareBarcodeReader,
    hasBuiltinBarcodeReader,
    enableStripeTerminal,
    stripeTerminalCapable,
    stripeTerminalConnectionToken,
    useHeadphoneMagStripeReader,
    requirePurchaseName,
    requirePurchaseEmail,

    suggestedTipPercents,
    storeCredentialsOK,
    importingManifest,
  } = state.settings;

  return {
    printerConnected,
    printerCapable,
    printerStatus,
    printerIP,
    readyToScan,
    readyToSell,
    setupScreen,
    storeKey,
    storeSlug,
    currentTheme,
    currentScreen,
    hasCheckinPermission,
    hasGlobalStatsPermission,
    processingOrder,
    lineaScannerCapable,
    useHardwareBarcodeReader,
    hasBuiltinBarcodeReader,
    enableStripeTerminal,
    stripeTerminalCapable,
    stripeTerminalConnectionToken,
    useHeadphoneMagStripeReader,
    requirePurchaseName,
    requirePurchaseEmail,

    suggestedTipPercents,
    storeCredentialsOK,
    importingManifest,
  };
};

export const getWebSetupSettings = (state: RootState) => {
  const {
    readyToScan,
    readyToSell,
    setupScreen,
    storeKey,
    storeSlug,
    hasCheckinPermission,
    hasGlobalStatsPermission,
    scanningItemIDs,
    sellingItemIDs, // for configsharing QR codes
    scanningOccurrenceIDs,
    storeCredentialsOK,
    scannerName,
    scannerStaffNames,
    importingManifest,
  } = state.settings;

  return {
    readyToScan,
    readyToSell,
    setupScreen,
    storeKey,
    storeSlug,
    hasCheckinPermission,
    hasGlobalStatsPermission,
    scanningItemIDs,
    sellingItemIDs,
    scanningOccurrenceIDs,
    storeCredentialsOK,
    scannerName,
    scannerStaffNames,
    importingManifest,
  };
};

export {
  getCurrencies,
  getScanSettings,
  getConfigSettings,
  getSetupScreen,
  calculateShiftDigest,
  getUnsyncedShifts,
  getShiftSettings,
  getItemsForSale,
  UNPERSISTED_KEYS,
  updateSettings,
  INITIAL_STATE,
};

export interface IOccurrenceWithItemIDs {
  eventID: string;
  occurrenceID: string;
  occurrenceStartsAt: number;
  occurrenceDate: number;
  itemIDs: Array<string>;
}

export interface ICurrency {
  currency: string;
  iso_code: string;
  name: string;
  symbol?: string;
  subunit_to_unit: number;
  fiat: boolean;
}

export interface ICurrencies {
  [key: string]: ICurrency;
}

interface ICheckinShifts {
  [key: string]: ICheckinShift;
}

export type IEventsWithItems = Array<IEventWithItems>;

export interface IEventWithItems {
  eventID: string;
  eventTitle?: string;
  eventStartsAt: number | null;
  multipleStartTimes?: boolean;
  hasLoadedItems?: boolean;
  items: Array<IItem>;
  data?: Array<IItem>;
  seatMapUrl?: string;
  seatWebviewUrl?: string;
  seatAvailabilityUrl?: string;
  assignedSeating: boolean;
}

export interface ISettingsState {
  currencies: ICurrencies;

  setupScreen: SetupScreens | undefined;
  playSounds: boolean;
  hasTicketsSince: number | null;
  hasScansSince: number | null;
  lastTicketReceived: number | null;
  lastScanReceived: number | null;
  lastTicketPull: number | null;
  lastScanPull: number | null;
  lastScanPost: number | null;
  lastLocalScan: number | null;

  currentTheme: string;

  availableItems: IEventsWithItems;
  availableItemIDs: string[];

  scannableItems: IEventsWithItems;
  scannableItemIDs: string[];
  purchasableItems: IEventsWithItems;
  purchasableItemIDs: string[];
  itemsForSale: IEventsWithItems;
  scanningItemIDs: string[];
  sellingItemIDs: string[];

  questions: {
    [key: string]: IQuestion;
  };

  availableOccurrenceIDs: string[];
  availableOccurrences: IOccurrenceWithItemIDs[];
  scanningOccurrenceIDs: string[];
  sellingOccurrenceIDs: string[];

  scannerName: string;
  scannerID: string;
  scannerStaffNames: string;
  currentShiftDigest: string;
  checkinShifts: ICheckinShifts;

  scannerUserAgent: string;
  storeSlug: string;
  storeKey: string;
  storeCredentialsOK: boolean;

  lastSync: number | null;
  readyToScan: boolean;
  readyToSell: boolean;

  hasCheckinPermission: boolean;
  hasGlobalStatsPermission: boolean;

  printerIP: string;
  printerStatus: string;
  printerError?: string;
  printerConnected: boolean;
  printerCapable: boolean;

  lineaScannerCapable: boolean;
  hasBuiltinBarcodeReader: boolean;
  useHardwareBarcodeReader: boolean;

  stripeTerminalCapable: boolean;
  enableStripeTerminal: boolean;
  useHeadphoneMagStripeReader: boolean;
  stripeTerminalConnectionToken: string | null;

  processingOrder: boolean;

  requirePurchaseName: boolean;
  requirePurchaseEmail: boolean;

  suggestedTipPercents: number[];

  currentScreen: UIScreen;

  importingManifest: boolean;
}

export type ConnectionSettings = Pick<ISettingsState, 'storeSlug' | 'storeKey'>;

export const getConnectionSettings = (state: RootState): ConnectionSettings => {
  const { storeSlug, storeKey } = state.settings;
  return { storeSlug, storeKey };
};

export type ISyncSettings = Pick<
  ISettingsState,
  | 'storeKey'
  | 'storeSlug'
  | 'scannerUserAgent'
  | 'scanningItemIDs'
  | 'hasScansSince'
  | 'hasTicketsSince'
  | 'currentShiftDigest'
>;

export const settingsReducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(actions.updateSettings, (state, action) => {
    updateSettings(state, action.payload.settings);
  });

  builder.addCase(actions.loadTickets, (state, action) => {
    updateSettings(state, action.payload.settings);
  });

  builder.addCase(actions.loadScans, (state, action) => {
    updateSettings(state, action.payload.settings);
  });

  builder.addCase(actions.markShiftsAsSynced, (state, action) => {
    markShiftsAsSynced(state, action.payload);
  });

  builder.addCase(actions.defineManifest, (state, action) => {
    const updatedSettings = defineManifest(state, action.payload);
    updateSettings(state, updatedSettings);
  });

  builder.addCase(actions.purge, state => {
    updateSettings(state, settingsAfterPurge(state));
  });
});
