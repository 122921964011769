import ActionCable from '@rails/actioncable';
import isEmpty from 'lodash/isEmpty';
import { debugNetwork } from 'shared/debug';
import { formatScanForServer, IScan } from 'shared/scans';
import { IWebsocketTransporter, WebSocketConnectionSettings } from 'shared/websocketMiddleware';
import { formatCheckinShiftForServer, ICheckinShift } from './settings/checkinShift';

const debug = (logLine: any, params?: any) => {
  if (params) {
    debugNetwork(`websocketsTS: ${logLine}`, params);
  } else {
    debugNetwork(`websocketsTS: ${logLine}`);
  }
};

const BASE_URL = 'wss://api.tickit.ca';

export default class WebsocketTransporter implements IWebsocketTransporter {
  connected: boolean;
  connecting: boolean;
  settings: WebSocketConnectionSettings;
  createConsumer: typeof ActionCable.createConsumer;
  subscription?: ActionCable.Subscription;
  onMessage?: (data: any) => void;

  constructor(
    createConsumer: typeof ActionCable.createConsumer,
    settings: WebSocketConnectionSettings,
  ) {
    debug('initialize');
    this.createConsumer = createConsumer;
    this.subscription = undefined;
    this.settings = settings;

    this.connected = false;
    this.connecting = false;

    this.connect();
  }

  url() {
    return `${BASE_URL}/cable?store_url=${encodeURIComponent(
      this.settings.storeSlug || '',
    )}&checkin_key=${encodeURIComponent(this.settings.storeKey || '')}`;
  }

  isConnected() {
    if (!this.subscription) return false;
    return this.connected;
  }

  isConnecting() {
    return this.connecting;
  }

  reconnect(settings: WebSocketConnectionSettings) {
    debug(`reconnect as ${settings.storeSlug}`);
    this.disconnect();
    this.connecting = false;
    this.settings = { ...settings };
    this.connect();
  }

  connect() {
    if (this.isConnecting()) {
      debug('already connecting...');
      return false;
    }
    if (this.isConnected()) {
      debug('already connected');
      return true;
    }

    this.connecting = true;
    if (isEmpty(this.settings.storeSlug) || isEmpty(this.settings.storeKey)) {
      debug('missing credentials');
      this.connecting = false;
      return false;
    }

    this.connecting = true;
    const consumer = this.createConsumer(this.url());
    debug('creatingConsumer', this.url());

    const createParams: ActionCable.ChannelNameWithParams = {
      channel: 'CheckinChannel',
      store: this.settings.storeSlug,
    };

    this.subscription = consumer.subscriptions.create(createParams, {
      connected: () => {
        this.connecting = false;
        this.connected = true;
        try {
          const subscriptions = consumer.subscriptions['subscriptions'] || [];
          debug(`connected with ${subscriptions.length} subscriptions`);
        } catch (e) {
          console.error(e);
        }
      },
      disconnected: () => {
        this.connected = false;
        debug('disconnected');
      },
      initialized: () => {
        debug('initialized');
      },
      rejected: () => {
        debug('rejected');
      },
      received: (data: any) => {
        this.connecting = false;
        this.connected = true;
        if (!this.onMessage) {
          debug('received but no handler', data);
          return;
        }
        this.onMessage(data);
        return;
      },
    });
    globalThis.ActionCableConnection = consumer.connection;
    // window.subs = consumer.subscriptions;
    // window.consumer = consumer;
  }

  disconnect() {
    if (this.isConnected()) {
      debug('disconnect');
      this.connected = false;
    }
  }

  recordScan(scan: IScan) {
    if (!scan) return;
    if (!scan.valid) return;
    debug('recordScan', scan.uuid);
    this.send('record_scan', formatScanForServer(scan));
  }

  recordShifts(shifts: Array<ICheckinShift>) {
    debug('recordShifts', shifts);
    if (shifts.length < 1) return;
    this.send('record_shifts', { shifts: shifts.map(shift => formatCheckinShiftForServer(shift)) });
  }

  // onScansRecorded(data) {
  //   debug(`received checkins ${data.uuids}`);
  //   store.dispatch(markScansAsSynced(data.uuids));
  //   store.dispatch(
  //     updateSettings({ lastScanReceived: new Date().getTime() } as Partial<ISyncSettings>),
  //   );
  // }

  send(action: string, data: any) {
    if (!this.isConnected()) {
      debug(`send failed (not connected)`);
      return false;
    }

    try {
      const success = this.subscription && this.subscription.perform(action, data);
      debug(`send (success=${success})`, action);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
