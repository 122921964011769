import React from 'react';

interface Props {
  onChange(input: string): void;
  onValidate(ticketNumber: string): void;
  selectInput(): void;
}

export class ScanForm extends React.Component<Props> {
  ticketInput: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.ticketInput = React.createRef();
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  };

  validate = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    if (!this.ticketInput.current) return;
    this.props.onValidate(this.ticketInput.current.value);
  };

  render() {
    return (
      <div className="scan-form">
        <form method="post" onSubmit={this.validate}>
          <div className="input-controls">
            <input
              type="text"
              onTouchStart={this.props.selectInput}
              onClick={this.props.selectInput}
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              autoFocus
              id="scan-input"
              className="string ticket-number"
              ref={this.ticketInput}
              onChange={this.onChange}
              placeholder="Enter name or ticket #"
            />
            <i className="input-icon icon icon-magnifier" />
          </div>
        </form>
      </div>
    );
  }
}
