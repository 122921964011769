import { RootState } from '../rootReducer';
import { ITicket } from '../Ticket';

export interface ScansByUUID {
  [key: string]: IScan;
}

export interface ScansByTicketNumber {
  [key: string]: IScan;
}

export interface QuestionWithAnswer {
  question: string;
  answer: string;
}

export interface IScan {
  uuid: string;
  ticketNumber: string;
  itemID?: string;
  scannedAt: number;
  scannerID?: string;
  scannerUserAgent?: string;
  location: string;
  reversal: boolean;
  synced: boolean;
  ticketFound?: boolean;
  valid: boolean;
  ticket?: ITicket;
  message?: string;
  details?: string;
  seatSection?: string;
  seatRow?: string;
  seatNumber?: string;
  shift: string;
  unlimitedCheckins: boolean;
}

// dexie schema - https://dexie.org/docs/Version/Version.stores()
// only fields that should be indexed need to be defined here
export const ScanSchema = {
  name: 'Scan',
  primaryKey: 'uuid',
  properties: {
    uuid: 'string',
    ticketNumber: 'string',
    itemID: 'string',
    scannedAt: 'number',
    scannerID: 'string?',
    scannerUserAgent: 'string?',
    shift: 'string',
    location: 'string?',
    reversal: 'bool',
    synced: 'bool',
    valid: 'bool',
    unlimitedCheckins: 'bool',
  },
};

// export { ScanSchema, formatScanForServer };

export const getCurrentScan = (state: RootState): IScan | undefined => state.scans.currentScan;

export const getUnsyncedScans = (state: RootState): Map<string, IScan> => {
  const { unsyncedUUIDs, scansByUUID } = state.scans;
  const unsynced = new Map();

  unsyncedUUIDs.forEach(uuid => {
    const scan = scansByUUID[uuid];

    if (!scan) {
      console.warn('missing usynced uuid', uuid);
      return;
    }
    unsynced.set(uuid, scan);
  });

  return unsynced;
};

export const getDeviceScanCountLastHour = (state): number =>
  state.scans.deviceAdmitTimestamps.length;

export { default as formatScanForServer } from './formatScanForServer';
export { default as getScanHistory } from './getScanHistory';
export * from './scansReducer';
