/* main parser that transforms raw ticket JSON from the API into our
   client-side objects */

import { IQuestion, ISettingsState } from './settingsReducer';
import { generateTicketCacheKey, ITicket, TicketAnswer, TicketFGL } from './Ticket';
import { ITicketsState } from './ticketsReducer';

type TicketsLastUpdatedSettings = Pick<
  ISettingsState,
  'hasTicketsSince' | 'lastTicketReceived' | 'lastTicketPull' | 'questions'
>;

export interface TicketsWithSettings {
  tickets: ITicketsState;
  settings: Partial<TicketsLastUpdatedSettings>;
  pagination?: {
    next_cursor?: string;
  };
}

export interface TicketsJSONResponse {
  last: number;
  since: number;
  questions?: {
    [key: string]: IQuestion;
  };
  tickets: {
    [key: string]: TicketJSON;
  };
  pagination?: {
    next_cursor?: string;
  };
}

export interface TicketJSON {
  order_id?: string;
  event_id?: string;
  item_id?: string;
  tags?: string[];
  guest_list?: boolean;
  deleted?: boolean;
  deleted_reason?: string;
  ticket_holder: string;
  purchaser_name?: string;
  organization?: string;
  emails: string[];
  check_in_comments?: string;
  seat_section?: string;
  seat_row?: string;
  seat_number?: string;
  hard_ticket?: boolean;
  unlimited_checkins?: boolean;
  fgl?: TicketFGL;
  qa?: TicketAnswer[];
  event_occurrence_entire_day?: boolean;
  event_occurrence_id?: string;
  event_occurrence_starts_at?: number;
}

export const ticketFromJSON = (ticketNumber: string, rawTicket: Partial<TicketJSON>): ITicket => {
  let fgl = {};

  if (rawTicket.fgl) {
    fgl = rawTicket.fgl;
  }

  let qa: TicketAnswer[] = [];
  if (rawTicket.qa) {
    qa = rawTicket.qa;
  }

  const occurrenceStartsAt = rawTicket.event_occurrence_starts_at
    ? new Date(rawTicket.event_occurrence_starts_at * 1000).getTime()
    : undefined;

  const ticket: ITicket = {
    ticketNumber: ticketNumber,
    tags: rawTicket.tags || [],
    guestList: !!rawTicket.guest_list,
    deleted: !!rawTicket.deleted,
    deletedReason:
      rawTicket.deleted_reason && rawTicket.deleted_reason.length > 0
        ? rawTicket.deleted_reason
        : undefined,
    itemID: String(rawTicket.item_id),
    orderID: rawTicket.order_id ? String(rawTicket.order_id) : '',
    ticketHolder: rawTicket.ticket_holder || '',
    purchaserName: rawTicket.purchaser_name,
    organization: rawTicket.organization || '',
    emails: rawTicket.emails || [],
    checkInComments: rawTicket.check_in_comments || '',
    seatSection: rawTicket.seat_section,
    seatRow: rawTicket.seat_row,
    seatNumber: rawTicket.seat_number,
    hardTicket: !!rawTicket.hard_ticket,
    unlimitedCheckins: !!rawTicket.unlimited_checkins,
    occurrenceID: rawTicket.event_occurrence_id,
    occurrenceStartsAt,
    occurrenceEntireDay: rawTicket.event_occurrence_entire_day,
    fgl,
    qa,
  };

  ticket.cacheKey = generateTicketCacheKey(ticket);
  return ticket;
};

// returns an object of { tickets, settings, pagination }
export default function (data: TicketsJSONResponse): TicketsWithSettings {
  const tickets: ITicketsState = {};
  const settings = {} as TicketsLastUpdatedSettings;
  const { pagination } = data;
  Object.keys(data.tickets).forEach(ticketNumber => {
    tickets[ticketNumber] = ticketFromJSON(ticketNumber, data.tickets[ticketNumber]);
  });

  if (Object.keys(tickets).length > 0) settings.lastTicketReceived = new Date().getTime();
  if (data.last !== undefined && data.last > -1)
    settings.hasTicketsSince = new Date(data.last * 1000).getTime();

  if (data.questions) {
    settings.questions = data.questions;
  }

  return { tickets, settings, pagination };
}
