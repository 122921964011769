// IMPORTANT -- do not use VSCode's organize imports feature since we need our polyfills (in ./lib/)
// to be imported first
import './lib/analytics';
import './lib/BackgroundPollingTimer';
import './lib/jsonRequest';

import ready from 'document-ready';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppContainer from './AppContainer';
import './css/application.scss';
import { setupErrorReporting } from './lib/errorReporting';
import registerServiceWorker from './registerServiceWorker';
import { store } from './stores/store';
import { testClient } from './ui/setup';

// whether we want to output timing data in the dev console
window.debugTiming = false;

// whether we want to output network debug data in the dev console
window.debugNetwork = false;

// whether we want to output analytics debug data in the dev console
window.debugAnalytics = true;

ready(() => {
  setupErrorReporting();
  if (!testClient()) return;

  ReactDOM.render(
    <Provider store={store}>
      <AppContainer />
    </Provider>,
    document.getElementById('root'),
  );

  registerServiceWorker();
});
