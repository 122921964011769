import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import React from 'react';
import { PassedProps as ParentProps } from './index';
import { SettingsActions } from './SettingsActions';
import { SettingsSelectEvent } from './SettingsSelectEvent';

interface Props
  extends Pick<
    ParentProps,
    'configureManifestAndImport' | 'loadingManifest' | 'setupErrorMessage' | 'availableItems'
  > {
  scanningItemIDs: Array<string>;
  scanningOccurrenceIDs: Array<string>;
}

interface State {
  scanningItemIDs: Array<string>;
  scanningOccurrenceIDs: Array<string>;
}

export class SettingsLoadTickets extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      scanningItemIDs: [...props.scanningItemIDs], // clone
      scanningOccurrenceIDs: [...props.scanningOccurrenceIDs], // clone
    };
  }

  onToggleItem = (itemID: string, selected: boolean) => {
    const { scanningItemIDs } = this.state;

    if (selected && !scanningItemIDs.includes(itemID)) {
      scanningItemIDs.push(itemID);
    } else {
      let idx;
      if ((idx = scanningItemIDs.indexOf(itemID)) > -1) {
        scanningItemIDs.splice(idx, 1);
      }
    }

    this.setState({ scanningItemIDs });
  };

  onToggleOccurrence = (occurrenceID: string, selected: boolean) => {
    const { scanningOccurrenceIDs } = this.state;

    if (selected && !scanningOccurrenceIDs.includes(occurrenceID)) {
      scanningOccurrenceIDs.push(occurrenceID);
    } else {
      let idx;
      if ((idx = scanningOccurrenceIDs.indexOf(occurrenceID)) > -1) {
        scanningOccurrenceIDs.splice(idx, 1);
      }
    }

    this.setState({ scanningOccurrenceIDs });
  };

  submit = event => {
    event.preventDefault();
    this.props.configureManifestAndImport({
      scanningItemIDs: this.state.scanningItemIDs,
      scanningOccurrenceIDs: this.state.scanningOccurrenceIDs,
    });
  };

  toggleAllInEvent = (eventID: string, markAllSelected: boolean) => {
    let newItemIDs: string[] = [];
    let newOccurrenceIDs: string[] = [];
    const event = this.props.availableItems.find(e => e.eventID === eventID);

    if (!event) {
      console.warn('Missing event');
      return;
    }
    const eventItemIDs = event.eventItemIDs;
    const eventOccurrenceIDs = event.eventOccurrenceIDs;
    console.log('toggle all', eventItemIDs, eventOccurrenceIDs);
    if (markAllSelected) {
      newItemIDs = this.state.scanningItemIDs.concat(eventItemIDs);
      newOccurrenceIDs = this.state.scanningOccurrenceIDs.concat(eventOccurrenceIDs);
    } else {
      newItemIDs = reject(this.state.scanningItemIDs, itemID => eventItemIDs.indexOf(itemID) > -1);
      newOccurrenceIDs = reject(
        this.state.scanningOccurrenceIDs,
        occurrenceID => eventOccurrenceIDs.indexOf(occurrenceID) > -1,
      );
    }

    this.setState({
      scanningItemIDs: uniq(newItemIDs),
      scanningOccurrenceIDs: uniq(newOccurrenceIDs),
    });
  };

  render() {
    const onSelectAll = this.toggleAllInEvent;

    const { scanningItemIDs, scanningOccurrenceIDs } = this.state;
    const events = this.props.availableItems.map(eventData => {
      return (
        <SettingsSelectEvent
          eventData={eventData}
          key={eventData.eventID}
          onToggleItem={this.onToggleItem}
          onToggleOccurrence={this.onToggleOccurrence}
          onSelectAll={onSelectAll}
          selectedItemIDs={scanningItemIDs}
          selectedOccurrenceIDs={scanningOccurrenceIDs}
          readonly={false}
        />
      );
    });

    if (events.length === 0) return false;

    return (
      <div className="settings-single-screen settings-items-screen">
        <form method="post" onSubmit={this.submit}>
          <div className="overlay-header">
            <h3 className="overlay-header-title">Load tickets</h3>
            <p className="overlay-header-text">
              Select the tickets you’d like to admit and click the <strong>LOAD</strong> button at
              the bottom of the screen.
            </p>
          </div>

          <div className="items">{events}</div>

          <SettingsActions
            waiting={this.props.loadingManifest}
            setupErrorMessage={this.props.setupErrorMessage}
            buttonLabel="Load"
            waitingLabel="Loading..."
          />
        </form>
      </div>
    );
  }
}
