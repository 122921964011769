import { deviceStatus, IDeviceStatus } from './deviceStatusReducer';
import ordersReducer, { IOrder, IOrdersState } from './orders';
import { IScansState, IScan, scansReducer } from './scans';
import searchReducer, { ISearchState } from './search';
import { ISettingsState, settingsReducer } from './settingsReducer';
import { ITicketsState, ticketsReducer } from './ticketsReducer';
import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
// import { websocketMiddleware } from './websocketMiddleware';
// import { persist } from './persist';

export interface RootState {
  scans: IScansState;
  tickets: ITicketsState;
  deviceStatus: IDeviceStatus;
  settings: ISettingsState;
  orders: IOrdersState;
  search: ISearchState;
}

/**
 * Common interface that both web and mobile persistence logic must conform to
 */
export interface IPersister {
  persistTickets: (tickets: ITicketsState) => Promise<void>;
  persistScans: (scans: IScan[]) => Promise<void>;
  persistSettings: (settingsJSON: string) => Promise<void>;
  persistOrders: (orders: Array<IOrder>) => Promise<void>;
  purge: () => Promise<void>;
}

export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

// build up the state tree incrementally so the later reducers will have access
// to the intermediate state -- this allows the search, orders and currentOrder to get
// access to the latest scans and tickets after they've been validated

// const rootReducer = combineReducers({
//   deviceStatus,
//   settings,
//   scans,
//   tickets,
//   search,
//   orders,
//   currentOrder,
// });

const rootReducer = (state: Partial<RootState> = {}, action): RootState => {
  // build up the state tree incrementally so the later reducers will have access
  // to the intermediate state -- this allows the search, orders and currentOrder to get
  // access to the latest scans and tickets after they've been validated
  // state.deviceStatus = deviceStatus(state.deviceStatus, action);
  // state.settings = settings(state.settings, action);
  // state.scans = scans(state, action);
  // state.tickets = tickets(state.tickets, action);
  // state.search = search(state, action); // must come after scans and tickets
  // // state.websockets = websockets(state, action); // must come after scans and settings
  // state.orders = orders(state, action); // must come after scans
  // // state.currentOrder = currentOrder(state, action); // must come after scans and orders
  // // state.persist = persist(state, action);

  // return {
  //   deviceStatus: state.deviceStatus,
  //   settings: state.settings,
  //   scans: state.scans,
  //   tickets: state.tickets,
  //   // stats: state.stats,
  //   search: state.search,
  //   // websockets: state.websockets,
  //   // currentOrder: state.currentOrder,
  //   orders: state.orders,
  //   // persist: state.persist
  // };

  return {
    deviceStatus: deviceStatus(state.deviceStatus, action),
    settings: settingsReducer(state.settings, action),
    scans: scansReducer(state.scans, action),
    tickets: ticketsReducer(state.tickets, action),
    search: searchReducer(state.search, action), // must come after scans and ticket,
    orders: ordersReducer(state.orders, action), // must come after scan,
  };
};

export default rootReducer;
