import { SHA1 } from 'crypto-js';
import { ISettingsState } from '../settingsReducer';
import trim from 'lodash/trim';
export type ShareableSettings = Pick<
  ISettingsState,
  | 'storeSlug'
  | 'storeKey'
  | 'scanningItemIDs'
  | 'scanningOccurrenceIDs'
  | 'sellingItemIDs'
  | 'sellingOccurrenceIDs'
  | 'requirePurchaseName'
  | 'requirePurchaseEmail'
>;

export interface ShareableConfig {
  digest: string;
  label: string;
  settings: ShareableSettings;
  userAgent?: string;
}

export interface PersistedShareableConfig extends ShareableConfig {
  deviceConfigurationID: string;
}

export const SHAREABLE_CONFIG_URL_PREFIX = 'tickitcheckin://deviceConfigurations';

// the order must be stable
const DIGEST_FIELDS = [
  'storeSlug',
  'storeKey',
  'scanningItemIDs',
  'scanningOccurrenceIDs',
  'sellingItemIDs',
  'sellingOccurrenceIDs',
  'requirePurchaseName',
  'requirePurchaseEmail',
];

export function isShareableConfigURL(url: string | null | undefined): boolean {
  return String(url).indexOf(SHAREABLE_CONFIG_URL_PREFIX) > -1;
}

// this code must match the logic in web checkin and server-side CheckInShift model
// TODO: this will generate identical digests for all devices with the same settings - is that ok?
export function calculateDigest(settings: ShareableSettings): string {
  const components = DIGEST_FIELDS.map(field => {
    let value = settings[field];
    if (Array.isArray(value)) {
      value = [...value].sort().join(',');
    }
    return trim(String(value));
  });
  return SHA1(components.join('--')).toString();
}

export function generateShareableConfigURL(shareableConfig: PersistedShareableConfig) {
  const { storeKey, storeSlug } = shareableConfig.settings;
  return `${SHAREABLE_CONFIG_URL_PREFIX}/${
    shareableConfig.deviceConfigurationID
  }?storeKey=${encodeURIComponent(storeKey)}&storeSlug=${encodeURIComponent(storeSlug)}`;
}

export const parseShareableConfigURL = function (configUrl: string) {
  const pathRegex = new RegExp(`(deviceConfigurations)?/([a-z0-9]+)`);
  let deviceConfigurationID: string | null = null;
  let storeKey: string | null = null;
  let storeSlug: string | null = null;

  try {
    const parsedURL = new URL(configUrl.trim());
    const match = pathRegex.exec(parsedURL.pathname);
    storeKey = parsedURL.searchParams.get('storeKey');
    storeSlug = parsedURL.searchParams.get('storeSlug');

    if (match) {
      deviceConfigurationID = match[2];
    }
  } catch (e) {
    console.error(e);
  }
  return { deviceConfigurationID, storeKey, storeSlug };
};
const LABEL_SEED_ADVERBS = [
  'actually',
  'very',
  'basically',
  'certainly',
  'clearly',
  'curiously',
  'definitely',
  'easily',
  'entirely',
  'extremely',
  'fairly',
  'generally',
  'subtly',
  'partly',
  'somewhat',
];

const LABEL_SEED_ADJECTIVES = [
  'interesting',
  'dope',
  'funky',
  'groovy',
  'eloquent',
  'happy',
  'jazzy',
  'joyful',
  'lovely',
  'lucky',
  'rad',
  'soulful',
  'sweet',
  'mystical',
  'magical',
  'mellow',
  'magnificent',
];

const LABEL_SEED_NOUNS = [
  'hair',
  'piranha',
  'frog',
  'avenger',
  'rebel',
  'kombucha',
  'artifact',
  'samosa',
  'tamagotchi',
  'pinecone',
  'condition',
  'puppy',
  'puddle',
  'panda',
  'kebab',
];

/* This will generate a user-displayable label for a shareable config.
   Given the same digest it will always return the same result.

   It seems to favour some words over others - we should investigate later.
*/
export function generateLabel(digest) {
  const truncatedDigest = digest.slice(-8);
  const seedInt = parseInt(truncatedDigest, 16);
  const adverb = LABEL_SEED_ADVERBS[seedInt % LABEL_SEED_ADVERBS.length];
  const adjective = LABEL_SEED_ADJECTIVES[seedInt % LABEL_SEED_ADJECTIVES.length];
  const noun = LABEL_SEED_NOUNS[seedInt % LABEL_SEED_NOUNS.length];
  const number = seedInt % 1000;
  return `${adverb}-${adjective}-${noun}-${number}`;
}
