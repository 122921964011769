import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createConsumer } from '@rails/actioncable';
import thunk from 'redux-thunk';
import persistMiddleware from 'shared/persistMiddleware';
import rootReducer, { RootState } from 'shared/rootReducer';
import websocketMiddleware from 'shared/websocketMiddleware';
import WebsocketTransporter from 'shared/WebsocketTransporter';
import { persister } from './persist';

const ws = new WebsocketTransporter(createConsumer, {
  storeSlug: '',
  storeKey: '',
  scannerUserAgent: '',
});

const configureDefault = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [
      thunk,
      ...getDefaultMiddleware(),
      websocketMiddleware(ws),
      persistMiddleware(persister),
    ],
  });
};
const store: EnhancedStore<RootState> = configureDefault();

export { store, configureDefault as configureStore };
