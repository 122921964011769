import { createReducer } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import * as actions from '../actions';
import getAnswersForTicket from '../getAnswersForTicket';
import { RootState } from '../rootReducer';
import { IScan, IScansState } from '../scans';
import { IQuestion } from '../settingsReducer';
import { generateTicketCacheKey, hasSamePurchaserAndTicketHolder, ITicket } from '../Ticket';
import TickitUtils from '../Utils';
import getSearchResults from './getSearchResults';

export interface ISearchState {
  searchTerms: string;
}

const INITIAL_STATE: ISearchState = {
  searchTerms: '',
};

function clearSearch(state: ISearchState) {
  state.searchTerms = '';
}

const searchReducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(actions.search, (state, action) => {
    if (isEqual(action.payload.searchTerms, state.searchTerms)) {
      return;
    }

    state.searchTerms = action.payload.searchTerms;

    // return searchTickets(state, action.payload.searchTerms);
  });
  builder.addCase(actions.defineManifest, clearSearch);
  builder.addCase(actions.purge, clearSearch);
});

function isAdmitted(scan?: IScan) {
  if (!scan || scan.reversal) return false;
  return true;
}

function extraDetails(ticket: ITicket, scan?: IScan): Array<string> {
  const messages: Array<string> = [];

  if (ticket.deleted) {
    if (ticket.deletedReason) {
      messages.push(ticket.deletedReason);
    }
    return messages;
  }

  if (ticket.unlimitedCheckins) {
    messages.push('Unlimited check-in ticket');
  }

  if (!hasSamePurchaserAndTicketHolder(ticket)) {
    if (ticket.guestList) {
      messages.push(`On the guest list under ${ticket.purchaserName}`);
    } else {
      messages.push(`Originally purchased by ${ticket.purchaserName}`);
    }
  }

  if (ticket.unlimitedCheckins) return messages;

  if (scan) {
    const previous_date = TickitUtils.formattedTimestamp(scan.scannedAt);
    const previous_ago = TickitUtils.timestampAgo(scan.scannedAt);
    if (scan.reversal) {
      messages.push(`Scan reversed on ${previous_date} (${previous_ago}) at ${scan.location}`);
    } else {
      messages.push(`Admitted on ${previous_date} (${previous_ago}) at ${scan.location}`);
    }
  } else {
    messages.push('Not admitted');
  }

  return messages;
}

// tickets should be an array, scans is the entire scan state
// used to update search results and the post-purchase admit screen
function updateTicketsAfterScan(searchResults: Array<ITicket>, scans: IScansState) {
  if (!searchResults || searchResults.length < 1) return { searchResults, changed: false };
  let changed = false;

  // console.log('inner', searchResults.length);

  const updatedSearchResults: Array<ITicket> = cloneDeep(searchResults);

  for (const index in searchResults) {
    const ticket = updatedSearchResults[index];
    const existingScan = ticket.scan;
    const newScan = scans.scansByTicketNumber[ticket.ticketNumber];

    if (newScan && existingScan && newScan.uuid === existingScan.uuid) {
      // console.log('BAIL', newScan);
      continue;
    }

    // console.log('DO IT', ticket);

    changed = true;

    // ticket.scan = newScan;
    // ticket.admitted = isAdmitted(newScan);
    // ticket.details = extraDetails(ticket, newScan);
    const updatedTicket: ITicket = {
      ...ticket,
      scan: newScan,
      admitted: isAdmitted(newScan),
      details: extraDetails(ticket, newScan),
    };
    updatedTicket.cacheKey = generateTicketCacheKey(updatedTicket);
    updatedSearchResults[index] = updatedTicket;
  }

  return { tickets: changed ? updatedSearchResults : searchResults, changed };
}

function generateSearchResult(
  rawTicket: ITicket,
  scan?: IScan,
  questions?: { [key: string]: IQuestion },
): ITicket {
  const ticket = { ...rawTicket };
  ticket.scan = scan;
  ticket.admitted = isAdmitted(scan);
  ticket.details = extraDetails(ticket, scan);
  ticket.cacheKey = generateTicketCacheKey(ticket);
  if (ticket.qa && questions) {
    ticket.answers = getAnswersForTicket(ticket, questions);
  }

  return ticket;
}

// resetSearchIndexes();

const getSearchTerms = (state: RootState): string => state.search.searchTerms;

export * from './getSearchResults';
export {
  searchReducer as default,
  getSearchTerms,
  getSearchResults,
  // saveSearchIndexes,
  // resetSearchIndexes,
  updateTicketsAfterScan,
  generateSearchResult,
  extraDetails,
};
