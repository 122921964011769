import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { addTicketTypesToOrder, IOrder, IOrders } from 'shared/orders';
import { generateSearchResult } from 'shared/search';
import { getTicketTypes } from 'shared/ticketTypeSelector';
import { getQuestions } from '../getAnswersForTicket';
import { RootState } from '../rootReducer';

const getScansByTicketNumber = (state: RootState) =>
  state.scans && state.scans.scansByTicketNumber ? state.scans.scansByTicketNumber : {};

const getOrders = (state: Partial<RootState>): IOrders => {
  return state.orders && state.orders.orders ? state.orders.orders : {};
};

const getScanningItemIDs = (state: Partial<RootState>): Array<string> => {
  if (state.settings && state.settings.scanningItemIDs) {
    return state.settings.scanningItemIDs;
  } else {
    return [];
  }
};

const getOrdersWithTickets = createSelector(
  [getOrders, getTicketTypes, getScanningItemIDs, getScansByTicketNumber, getQuestions],
  (rawOrders, ticketTypes, scanningItemIDs, scansByTicketNumber, questions): IOrders => {
    const orders: IOrders = {};
    Object.keys(rawOrders).forEach(orderUUID => {
      const rawOrder = rawOrders[orderUUID];
      if (!rawOrder || !rawOrder.orderID || !rawOrder.completed) {
        return;
      }
      orders[orderUUID] = addTicketTypesToOrder(rawOrder, ticketTypes, scanningItemIDs);

      orders[orderUUID].tickets = orders[orderUUID].tickets.map(ticket => {
        const scan = scansByTicketNumber[ticket.ticketNumber];
        return generateSearchResult(ticket, scan, questions);
      });
    });

    return orders;
  },
);

const sortedOrders = (orders: Array<IOrder>): Array<IOrder> =>
  sortBy(orders, order => (order.createdAt || 0) * -1);

// returns an array of recent orders with ticket type details added to line items
// and tickets
//
// TODO: do we want to limit by quantity this somehow?
const getRecentOrders = createSelector(
  [getOrdersWithTickets],
  (orders): Array<IOrder> => {
    return sortedOrders(Object.values(orders));
  },
);

export { getRecentOrders as default };
