import { createSelector } from '@reduxjs/toolkit';
import { UNPERSISTED_KEYS, ISettingsState, debugSettingsKeys } from './settingsReducer';
import { RootState } from './rootReducer';

const getSettings = (state: RootState) => state.settings;

const settingsToPersistSelector = createSelector([getSettings], (settings: ISettingsState) => {
  const settingsToPersist: Partial<ISettingsState> = { ...settings };
  UNPERSISTED_KEYS.forEach(key => delete settingsToPersist[key]);

  debugSettingsKeys(settingsToPersist, 'settingsToPersist');
  return settingsToPersist;
});

export default settingsToPersistSelector;
