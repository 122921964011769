import { IScan } from './scans';
import { ISettingsState } from './settingsReducer';

export type ScansLastUpdatedSettings = Pick<
  ISettingsState,
  'lastScanReceived' | 'hasScansSince' | 'lastScanPull'
>;

export interface ScansJSONResponse {
  last?: number; // not included via websockets
  since?: number;
  scans: { [key: string]: IScanJSONFromServer };
  pagination?: {
    next_cursor?: string;
  };
}

export interface ScansWithSettings {
  scans: Array<IScan>;
  settings: Partial<ScansLastUpdatedSettings>;
  pagination?: {
    next_cursor?: string;
  };
}

export interface IScanJSON {
  uuid: string;
  ticket_number: string;
  scanned_at: number;
  location: string;
  reversal?: boolean;
  shift?: string;
  item_id?: string | number;
  scanner_id?: string;
  scanner_user_agent?: string;
  synced?: boolean;
  valid?: boolean;
  unlimited_checkins?: boolean;
}

type IScanJSONFromServer = Omit<IScanJSON, 'uuid' | 'scanner_id' | 'scanner_user_agent'>;

const scanFromJSON = (uuid, rawScan: IScanJSONFromServer): IScan => {
  return {
    uuid: uuid,
    ticketNumber: rawScan.ticket_number,
    itemID: String(rawScan.item_id),
    scannedAt: new Date(rawScan.scanned_at * 1000).getTime(),
    location: rawScan.location,
    reversal: !!rawScan.reversal,
    synced: rawScan.synced || false,
    shift: rawScan.shift || '',
    valid: rawScan.valid || false,
    unlimitedCheckins: rawScan.unlimited_checkins || false,
  };
};

// returns an object of { scans, settings, pagination }
export default function (data: ScansJSONResponse): ScansWithSettings {
  const scans: Array<IScan> = [];
  const settings = {} as ScansLastUpdatedSettings;
  const { pagination } = data;
  if (!data) return { scans, settings };

  Object.keys(data.scans).forEach(uuid => {
    const scan = scanFromJSON(uuid, data.scans[uuid]);
    scan.synced = true;
    scan.valid = true;
    scans.push(scan);
  });

  if (scans.length > 0) settings.lastScanReceived = new Date().getTime();
  if (data.last !== undefined && data.last > -1)
    settings.hasScansSince = new Date(data.last * 1000).getTime();

  return { scans, settings, pagination };
}
