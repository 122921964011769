import React from 'react';
import { EventWithItemsAndOccurrences } from 'shared/settings/availableItemsForLoading';
import TickitUtils from 'shared/Utils';
import { SettingsOccurrence } from './SettingsOccurrence';
import { SettingsTicketItem } from './SettingsTicketItem';

export interface Props {
  eventData: EventWithItemsAndOccurrences;
  onToggleItem: (itemID: string, selected: boolean) => void;
  onToggleOccurrence: (itemID: string, selected: boolean) => void;
  onSelectAll: (eventID: string, markAllSelected: boolean) => void;
  selectedItemIDs: Array<string>;
  selectedOccurrenceIDs: Array<string>;
  readonly: boolean;
}

interface State {
  expanded: boolean;
}

export class SettingsSelectEvent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    let hasActiveItems = false;

    for (const item of this.props.eventData.data) {
      if (item.itemID && this.props.selectedItemIDs.includes(item.itemID)) {
        hasActiveItems = true;
        break;
      }

      if (item.occurrenceID && this.props.selectedOccurrenceIDs.includes(item.occurrenceID)) {
        hasActiveItems = true;
        break;
      }
    }

    this.state = { expanded: hasActiveItems };
  }

  toggleExpand = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ expanded: !this.state.expanded });
  };

  onSelectAll = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelectAll(this.props.eventData.eventID, true);
  };

  onDeselectAll = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelectAll(this.props.eventData.eventID, false);
  };

  renderStartsAt() {
    const { eventData } = this.props;
    if (eventData.multipleStartTimes) {
      return <span className="event-starts-at">Multiple Dates</span>;
    } else if (eventData.eventStartsAt) {
      const startsAt = TickitUtils.formattedOccurrenceDate(eventData.eventStartsAt);
      return <span className="event-starts-at">{startsAt}</span>;
    } else {
      return null;
    }
  }

  render() {
    let icon;
    const classNames = ['item-group'];
    let selectedCount = 0;
    let hasActiveItems = false;

    const items = this.props.eventData.data.map(item => {
      const itemID = item.itemID;
      const occurrenceID = item.occurrenceID;

      let isSelected = false;
      if (occurrenceID) {
        isSelected = this.props.selectedOccurrenceIDs.includes(occurrenceID);
      } else if (itemID) {
        isSelected = this.props.selectedItemIDs.includes(itemID);
      }

      if (isSelected) {
        hasActiveItems = true;
        selectedCount++;
      }

      if (occurrenceID) {
        return (
          <SettingsOccurrence
            occurrenceID={occurrenceID}
            title={item.title}
            key={occurrenceID}
            selected={isSelected}
            onToggleOccurrence={this.props.onToggleOccurrence}
            readonly={this.props.readonly}
          />
        );
      }

      if (itemID) {
        return (
          <SettingsTicketItem
            key={item.itemID}
            selected={isSelected}
            itemID={itemID}
            itemTitle={item.title}
            onToggleItem={this.props.onToggleItem}
            readonly={this.props.readonly}
          />
        );
      }

      console.warn('Invalid item', item);
      return null;
    });

    if (this.state.expanded) {
      classNames.push('active');
      icon = <i className="icon icon-minus" />;
    } else {
      icon = <i className="icon icon-plus" />;
    }

    let selectAllToggle: React.ReactNode = hasActiveItems ? (
      <button className="link-button toggle-all-items" onClick={this.onDeselectAll}>
        De-select All
      </button>
    ) : (
      <button className="link-button toggle-all-items" onClick={this.onSelectAll}>
        Select All
      </button>
    );

    if (this.props.readonly) {
      selectAllToggle = false;
    }

    return (
      <div className={classNames.join(' ')}>
        <h3 className="event-title" onClick={this.toggleExpand}>
          <span className="toggle-icon">{icon}</span>
          {this.props.eventData.eventTitle}
          {this.renderStartsAt()}
          <span className="loaded-count">
            {selectedCount} / {items.length}
          </span>
        </h3>

        <div className="item-list">
          {selectAllToggle}
          {items}
        </div>
      </div>
    );
  }
}
