import { createSelector } from '@reduxjs/toolkit';
import { getTicketTypes } from '../ticketTypeSelector';
import addTicketTypeToTicket from '../addTicketTypeToTicket';
import { RootState } from '../rootReducer';
import { generateSearchResult } from '../search';
import { ITicket } from '../Ticket';
import { getQuestions } from '../getAnswersForTicket';

const getTickets = (state: RootState) => state.tickets;
const getScanningItemIDs = (state: RootState) => state.settings.scanningItemIDs;
const getRecentScans = (state: RootState) => state.scans.scanHistory;

const getScanHistory = createSelector(
  [getRecentScans, getTickets, getTicketTypes, getScanningItemIDs, getQuestions],
  (scanHistory, tickets, ticketTypes, scanningItemIDs, questions) => {
    const history: Array<ITicket> = [];
    scanHistory.forEach(scan => {
      const ticket: ITicket = tickets[scan.ticketNumber];
      if (!ticket) {
        console.warn(`Missing ticket ${scan.ticketNumber} even though it was in the scan history`);
        return;
      }

      // not scannable (likely bought via point of sale)
      if (!ticket.itemID || !scanningItemIDs.includes(ticket.itemID)) return;

      const ticketWithItem = addTicketTypeToTicket(ticket, ticketTypes);
      ticketWithItem.showAdmitButton = false;

      history.push(generateSearchResult(ticketWithItem, scan, questions));
    });
    return history;
  },
);

export default getScanHistory;
