import { createSelector } from '@reduxjs/toolkit';
import arrayFrom from 'array-from';
import { sum } from 'lodash';
import { RootState } from './rootReducer';
import { getDeviceScanCountLastHour } from './scans';
import { getTicketCountsByItem } from './ticketsReducer';
import { getScanningTicketTypes } from './ticketTypeSelector';
import TickitUtils from './Utils';

interface ItemCounts {
  [key: string]: number;
}

const getLastSync = (state: RootState): number | null => state.settings.lastSync;
const getScansByItem = (state: RootState) => state.scans.countsByItem;
export const getScansByItemInCurrentShift = (state: RootState) => {
  const shiftID = state.settings.currentShiftDigest;
  return state.scans.countsByItemAndShift[shiftID] || {};
};
const getUsyncedScanCount = (state: RootState): number => state.scans.unsyncedUUIDs.length;
const getCurrentTheme = (state: RootState): string => state.settings.currentTheme;

// only used in web checkin
export const statusBarSelector = createSelector(
  getTicketCountsByItem,
  getScansByItemInCurrentShift,
  getLastSync,
  getCurrentTheme,
  (ticketsByItem, scansByItemInShift, lastSync, currentTheme) => {
    const shiftScanCount = sum(Object.values(scansByItemInShift));
    return {
      ticketCount: sum(arrayFrom(ticketsByItem.values())),
      hasScansThisShift: shiftScanCount !== 0,

      // TODO: check if we're on the same day
      lastSync: lastSync ? `Synced at ${TickitUtils.formattedTimeOnly(lastSync)}` : 'Never synced',
      currentTheme,
    };
  },
);

export const statsSelector = createSelector(
  getScanningTicketTypes,
  getTicketCountsByItem,
  getScansByItem,
  getScansByItemInCurrentShift,
  getLastSync,
  getUsyncedScanCount,
  getDeviceScanCountLastHour,

  (
    ticketTypes,
    ticketsByItem,
    scansByItem,
    scansByItemInShift,
    lastSync,
    unsyncedScanCount,
    deviceScanCountLastHour,
  ) => {
    const output: IStats = {
      events: [],
      overview: {
        multiScanCount: 0,
        singleScanCount: 0,
        singleScanTotal: 0,
        ticketCount: 0,
        shiftScanCount: 0,
        shiftMultiScanCount: 0,
        totalScanCount: 0,
        unsyncedScanCount,
        deviceScanCountLastHour,
        lastSync,
      },
    };

    const perEventStats: {
      [key: string]: IEventStats;
    } = {};

    ticketTypes.forEach((ticketType, itemID) => {
      const eventID = ticketType.eventID;

      const itemStats: IItemStats = {
        unlimitedCheckins: ticketType.unlimitedCheckins,
        itemTitle: ticketType.itemTitle,
        itemID: itemID,
        soldCount: ticketsByItem.get(itemID) || 0,
        scanCount: scansByItem[itemID] || 0,
        shiftScanCount: scansByItemInShift[itemID] || 0,
        percentAdmitted: 0,
        percentAdmittedInShift: 0,
      };

      output.overview.ticketCount += itemStats.soldCount;
      output.overview.totalScanCount += itemStats.scanCount;
      output.overview.shiftScanCount += itemStats.shiftScanCount;

      if (itemStats.unlimitedCheckins) {
        output.overview.multiScanCount += itemStats.scanCount;
        output.overview.shiftMultiScanCount += itemStats.shiftScanCount;
        itemStats.percentAdmitted = 1;
        itemStats.percentAdmittedInShift = 1;
      } else {
        if (itemStats.soldCount > 0) {
          // ensure we're always between 0-1
          itemStats.percentAdmitted = Math.max(
            0,
            Math.min(1, itemStats.scanCount / itemStats.soldCount),
          );

          itemStats.percentAdmittedInShift = Math.max(
            0,
            Math.min(1, itemStats.shiftScanCount / itemStats.soldCount),
          );
        }

        output.overview.singleScanCount += itemStats.scanCount;
        output.overview.singleScanTotal += itemStats.soldCount;
      }

      if (!perEventStats[eventID]) {
        perEventStats[eventID] = {
          eventID: eventID,
          eventTitle: ticketType.eventTitle,
          data: [],
        } as IEventStats;
      }

      perEventStats[eventID].data.push(itemStats);
    });

    output.events = Object.values(perEventStats);
    return output;
  },
);

export interface IStats {
  events: Array<IEventStats>;
  overview: IStatsOverview;
}

export interface IEventStats {
  eventID: string;
  eventTitle: string;
  data: Array<IItemStats>;
}

interface IStatsOverview {
  multiScanCount: number;
  singleScanCount: number;
  singleScanTotal: number;
  ticketCount: number;
  shiftScanCount: number;
  shiftMultiScanCount: number;
  totalScanCount: number;
  unsyncedScanCount: number;
  deviceScanCountLastHour: number;
  lastSync: number | null;
}

export interface IItemStats {
  unlimitedCheckins: boolean;
  itemTitle: string;
  itemID: string;
  soldCount: number;
  scanCount: number;
  shiftScanCount: number;
  percentAdmitted: number;
  percentAdmittedInShift: number;
}
