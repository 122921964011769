import Dinero from 'dinero.js';

export default function createTokenCurrency(units: number, currency: string): Dinero.Dinero {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore -- Dinero does not support custom currencies so we suppress
    // this check for now
    // see https://github.com/sarahdayan/dinero.js/issues/36
    // see https://github.com/sarahdayan/dinero.js/issues/71
    return Dinero({ amount: units, precision: 3, currency });
  } catch (error) {
    console.error('error', error, units);
    throw error;
  }
}
