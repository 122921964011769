const BROWSER_REC = 'We recommend the latest version of either Mozilla Firefox or Google Chrome';

export const addBannerMessage = function(msg, unsupported) {
  const body = document.querySelector('body');
  const banner = document.createElement('div');
  banner.className = 'app-status';
  banner.innerHTML = unsupported
    ? `
    <p>${msg}</p>
    <p class='browsers'>${BROWSER_REC}</p>
    `
    : `<p>${msg}</p>`;

  body.appendChild(banner);
  body.classList.add('show-app-status');
  if (unsupported) {
    body.classList.add('unsupported-client');
  }
};

export const testClient = function() {
  if (!window.indexedDB) {
    addBannerMessage(
      `Sorry, your web browser doesn’t support “indexedDB” which is needed for offline checkin.`,
      true
    );
    return false;
  }

  // if (!('serviceWorker' in navigator)) {
  //   addBannerMessage(`Sorry, your web browser doesn’t support offline mode.`, true);
  //   return false;
  // }

  if (!('WebSocket' in window)) {
    addBannerMessage(
      `Sorry, your web browser doesn’t support websockets, which are needed to sync scans.`,
      true
    );
    return false;
  }

  return true;
};
