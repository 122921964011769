import { createSelector } from '@reduxjs/toolkit';
import { IItem } from './items';
import { RootState } from './rootReducer';
import { IEventWithItems } from './settingsReducer';
const getAvailableItems = (state: Partial<RootState>) => {
  // console.info('availavle', state.settings);
  if (state.settings && state.settings.availableItems) {
    return state.settings.availableItems;
  } else {
    return [];
  }
};
const getScanningItemIDs = (state: Partial<RootState>) => {
  if (state.settings && state.settings.scanningItemIDs) {
    return state.settings.scanningItemIDs;
  } else {
    return [];
  }
};

export const getTicketTypes = createSelector([getAvailableItems], availableItems => {
  const ticketTypes: Map<string, IItem> = new Map();
  Object.keys(availableItems).forEach(eventID => {
    availableItems[eventID].items.forEach(item => {
      ticketTypes.set(item.itemID, item);
    });
  });

  return ticketTypes;
});

// returns a Map() of all tickets that are currently loaded for scanning
export const getScanningTicketTypes = createSelector(
  getAvailableItems,
  getScanningItemIDs,
  (availableItems: Array<IEventWithItems>, scanningItemIDs: Array<string>): Map<string, IItem> => {
    const ticketTypes = new Map();
    Object.keys(availableItems).forEach(eventID => {
      availableItems[eventID].items.forEach(item => {
        if (scanningItemIDs.indexOf(item.itemID) === -1) return;
        ticketTypes.set(item.itemID, item);
      });
    });

    return ticketTypes;
  },
);

// returns a Map() of all tickets that are currently loaded for scanning
export const getUnlimitedCheckinItemIDs = createSelector([getAvailableItems], availableItems => {
  const itemIDs: Array<string> = [];
  Object.keys(availableItems).forEach(eventID => {
    availableItems[eventID].items.forEach(item => {
      if (item.unlimitedCheckins && item.itemID) itemIDs.push(item.itemID);
    });
  });

  return itemIDs;
});
