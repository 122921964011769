import { IScan } from 'shared/scans';
import { IScanJSON } from 'shared/parseScansResponse';

export default function formatScanForServer(scan: IScan): IScanJSON {
  return {
    uuid: scan.uuid,
    ticket_number: scan.ticketNumber,
    scanned_at: scan.scannedAt / 1000.0,
    scanner_id: scan.scannerID,
    scanner_user_agent: scan.scannerUserAgent,
    location: scan.location,
    reversal: !!scan.reversal,
    shift: scan.shift,
  };
}
