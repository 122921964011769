import { IItem } from 'shared/items';
import { CartData, IOrder } from 'shared/orders';
import updateOrderLineItem from './updateOrderLineItem';

/* merge the contents of a cartData object into an order object - used when syncing up the results
of a seatmap order API call */
const updateOrderFromServer = (rawOrder: IOrder, cartData: CartData): IOrder => {
  let order: IOrder = { ...rawOrder, lineItems: {} };

  if (order.orderID && cartData.orderID && order.orderID !== cartData.orderID.toString()) {
    throw new Error(
      `updateOrderFromServer: orderID mismatch: ${order.orderID} vs ${cartData.orderID}`,
    );
  }

  order.orderID = cartData.orderID ? cartData.orderID.toString() : undefined;
  order.orderToken = cartData.orderToken;
  order.currency = cartData.currency;

  order.cartExpiresAt = cartData.remaining
    ? new Date(Date.now() + cartData.remaining * 1000).getTime()
    : undefined;

  for (const [itemID, cartItem] of Object.entries(cartData.cartItems || {})) {
    const item = { itemID: itemID, itemTitle: cartItem.t } as IItem;
    order = updateOrderLineItem(order, item, cartItem.q, cartItem.s);
  }

  return order;
};

export default updateOrderFromServer;
