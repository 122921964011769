import browserInfo from 'browser-info';
import Cookies from 'js-cookie';
import { IDeviceStatus } from 'shared/deviceStatusReducer';
import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_SCANNER_NAME = 'Computer';
export const COOKIE_ID = 'tickit-scanner-id';

import packageJSON from '../../package.json';

let deviceInfo: Partial<IDeviceStatus> | undefined = undefined;

const parsedBrowserInfo = browserInfo();

async function getDeviceInfo(): Promise<Partial<IDeviceStatus>> {
  // create a unique scanner ID for logging
  let scannerID = Cookies.get(COOKIE_ID);
  if (!scannerID) {
    scannerID = uuidv4();
    Cookies.set(COOKIE_ID, scannerID, { secure: true });
  }
  deviceInfo = {
    deviceType: 'Computer',
    hasBuiltinBarcodeReader: false,
    hasHeadphoneJack: undefined,
    scannerName: DEFAULT_SCANNER_NAME,
    scannerID: scannerID || '',
    deviceBrand: parsedBrowserInfo.os,
    deviceManufacturer: parsedBrowserInfo.os,
    systemName: parsedBrowserInfo.name,
    systemVersion: parsedBrowserInfo.version,
    printerCapable: false,
    readNFC: false,
    writeNFC: false,
    scannerUserAgent: `Tickit Web Checkin/${packageJSON.version} (${String(navigator.userAgent)})`,
  };

  return deviceInfo;
}

export { getDeviceInfo };
