import { ICurrencies, ICurrency } from './settingsReducer';

// returns a settings object subset
export default function parseCurrencyResponse(json?: ICurrencies): ICurrencies {
  const currencies: ICurrencies = {};

  if (!json) return {};

  Object.keys(json).forEach(currencyID => {
    const currency = json[currencyID];

    currencies[currencyID] = {
      currency: currencyID,
      iso_code: currency.iso_code,
      fiat: !!currency.fiat,
      name: currency.name,
      symbol: currency.symbol,
      subunit_to_unit: currency.subunit_to_unit,
    } as ICurrency;
  });

  return currencies;
}
