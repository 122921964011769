import { buildTicket } from 'shared/fixtures';
import { v4 as uuidv4 } from 'uuid';
import { IScan } from './scans';
import { ITicket } from './Ticket';
import TickitUtils from './Utils';

// test scans are for gate staff to practice with before an event
const TEST_TICKET_PREFIX = '00000-';

function testTicket(properties: Partial<ITicket>) {
  const testTicket = buildTicket(properties);
  return testTicket;
}

export function isTestTicketNumber(ticketNumber: string) {
  return ticketNumber.indexOf(TEST_TICKET_PREFIX) === 0;
}

export function detectTestScan(
  settings,
  ticketNumber: string,
  reversal = false,
): IScan | undefined {
  if (!isTestTicketNumber(ticketNumber)) return undefined;

  let ticket;
  const scan: IScan = {
    uuid: uuidv4(),
    itemID: undefined,
    ticketNumber,
    scannedAt: new Date().getTime(),
    location: settings.scannerName,
    scannerID: settings.scannerID,
    scannerUserAgent: settings.scannerUserAgent,
    shift: settings.currentShiftDigest,
    valid: false,
    ticketFound: true,
    synced: true,
    reversal,
    message: '',
    unlimitedCheckins: true,
  };

  const previousTs = new Date().getTime() - 60 * 60 * 2 * 1000;
  const previousAgo = TickitUtils.timestampAgo(previousTs);

  switch (ticketNumber) {
    // valid
    case '00000-00-1-A':
      ticket = testTicket({
        ticketNumber,
        seatSection: 'Orchestra',
        seatRow: 'A',
        seatNumber: '15',
        unlimitedCheckins: true,
      });
      scan.valid = true;
      scan.message = ticket.itemTitle;
      break;

    // valid with tags and comments
    case '00000-00-2-A':
      ticket = testTicket({
        ticketNumber,
        tags: ['VIP Entry', 'Drink Tickets: 5'],
        checkInComments:
          'Here are some check-in comments that may have extra information about the attendee',
        unlimitedCheckins: true,
        seatSection: 'Orchestra',
        seatRow: 'A',
        seatNumber: '15',
        occurrenceEntireDay: false,
        occurrenceStartsAt: Date.parse('2069-04-20 1:23:45'),
      });
      scan.valid = true;
      scan.message = ticket.itemTitle;
      break;

    // already admitted
    case '00000-00-3-A':
      ticket = testTicket({
        ticketNumber,
        unlimitedCheckins: true,
      });
      ticket.answers = [
        { question: 'Meal Plan', answer: 'Standard' },
        { question: 'Shirt Size', answer: 'Pink / Medium' },
      ];

      scan.message = 'Already admitted';
      scan.details = `Admitted on ${TickitUtils.formattedTimestamp(
        previousTs,
      )} (${previousAgo}) at Main Gate #2`;
      break;

    // transferred
    case '00000-00-4-A':
      ticket = testTicket({
        ticketNumber,
        deleted: true,
        unlimitedCheckins: true,
      });

      scan.message = 'Cancelled ticket';
      scan.details = `This ticket was transferred to suzy@tickit.ca on ${TickitUtils.formattedTimestamp(
        previousTs,
      )}`;
      break;

    // not found
    case '00000-00-9-A':
      scan.message = 'Not found';
      break;

    default:
      return undefined;
  }

  scan.ticket = ticket;
  if (ticket) {
    scan.itemID = ticket.itemID;
    scan.seatSection = ticket.seatSection;
    scan.seatRow = ticket.seatRow;
    scan.seatNumber = ticket.seatNumber;
  }

  return scan;
}
