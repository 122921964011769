import { RootState } from './rootReducer';
import { QuestionWithAnswer } from './scans';
import { IQuestion } from './settingsReducer';
import { ITicket } from './Ticket';

export default function getAnswersForTicket(
  ticket: ITicket,
  questions: {
    [key: string]: IQuestion;
  },
): QuestionWithAnswer[] {
  if (!ticket) return [];

  const qa = ticket.qa;
  if (!qa) return [];

  return qa.map(({ question_id, answer }) => {
    const question = questions[question_id];

    return {
      question: question?.label || 'Unknown Question',
      answer: answer || '',
    };
  });
}

export const getQuestions = (state: Partial<RootState>): { [key: string]: IQuestion } => {
  if (state.settings && state.settings.questions) {
    return state.settings.questions;
  } else {
    return {};
  }
};
