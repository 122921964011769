import { IItemPrice, IItemPriceWithMoneyObjects } from 'shared/items';
import { fiat, tokens } from 'shared/money';

/**
 * Decorate an IITemPrice object with Dinero money objects
 *
 * Used for price calculations during the purchase flow
 *
 * IMMUTABLE
 */
export default function monetizeItemPrice(
  rawPrice: IItemPrice | IItemPriceWithMoneyObjects,
): IItemPriceWithMoneyObjects {
  const currency = rawPrice.currency;
  const monetize = rawPrice.fiat ? fiat : tokens;

  return Object.assign({}, rawPrice, {
    basePrice: monetize(rawPrice.basePriceCents, currency),
    tickitFees: monetize(rawPrice.tickitFeesCents || 0, currency),
    sellerFees: monetize(rawPrice.sellerFeesCents || 0, currency),
    unitTax: monetize(rawPrice.unitTaxCents || 0, currency),
    displayFees: monetize(rawPrice.displayFeesCents, currency),
    displayPrice: monetize(rawPrice.displayPriceCents, currency),
  }) as IItemPriceWithMoneyObjects;
}
