import SHA1 from 'crypto-js/sha1';
import { ISettingsState } from '../settingsReducer';
import trim from 'lodash/trim';
export interface IShiftJSON {
  digest: string;
  scanner_id: string;
  location: string;
  scanner_user_agent: string;
  staff_names: string;
}

export interface ICheckinShift {
  shiftDigest: string;
  scannerName: string;
  scannerID: string;
  scannerUserAgent: string;
  scannerStaffNames: string;
  unsynced?: boolean;
}

const SHIFT_DIGEST_FIELDS = [
  'storeSlug',
  'scannerName',
  'scannerID',
  'scannerUserAgent',
  'scannerStaffNames',
];

export function formatCheckinShiftForServer(checkinShift: ICheckinShift): IShiftJSON {
  return {
    digest: checkinShift.shiftDigest,
    scanner_id: checkinShift.scannerID,
    location: checkinShift.scannerName,
    scanner_user_agent: checkinShift.scannerUserAgent,
    staff_names: checkinShift.scannerStaffNames,
  };
}

// this code must match the logic in web checkin and server-side CheckInShift model
export function calculateShiftDigest(settings: ISettingsState): string {
  const components = SHIFT_DIGEST_FIELDS.map(field => trim(settings[field]));
  return SHA1(components.join('--')).toString();
}

export function maybeSaveCheckinShift(state: ISettingsState): void {
  const { currentShiftDigest } = state;
  if (!currentShiftDigest || currentShiftDigest.length < 1) return;

  // already saved
  if (state.checkinShifts && state.checkinShifts[currentShiftDigest]) return;

  const checkinShift: ICheckinShift = {
    shiftDigest: currentShiftDigest,
    scannerID: state.scannerID,
    scannerName: state.scannerName,
    scannerStaffNames: state.scannerStaffNames,
    scannerUserAgent: state.scannerUserAgent,
    unsynced: true,
  };
  state.checkinShifts[currentShiftDigest] = checkinShift;
  return;
}

export function maybeUpdateCurrentShiftDigest(state, newSettings): void {
  // force update if we don't have a digest yet
  let shouldCalculateNewShiftDigest =
    !state.currentShiftDigest || state.currentShiftDigest.length < 1;

  if (!shouldCalculateNewShiftDigest) {
    for (const field of SHIFT_DIGEST_FIELDS) {
      // did relevant fields change?
      if (!newSettings[field]) continue;
      shouldCalculateNewShiftDigest = true;
      break;
    }
  }

  if (shouldCalculateNewShiftDigest) {
    const currentShiftDigest = calculateShiftDigest(state);
    if (currentShiftDigest !== state.currentShiftDigest) {
      state.currentShiftDigest = currentShiftDigest;
      console.debug(
        `Updated currentShiftDigest from '${state.currentShiftDigest}' to '${currentShiftDigest}'`,
      );
    }

    // we always call this to ensure we don't ever have a currentShiftDigest without a corresponding object in checkinShifts
    maybeSaveCheckinShift(state);
  }
  return;
}
