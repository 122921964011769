import { IItemPrice } from 'shared/items';
import { formatMoney } from 'shared/money';
import { ILineItemWithPrices, ITotalsByCurrency } from 'shared/orders';

const root = typeof globalThis !== 'undefined' ? globalThis : global;

export function debugTiming(logLine: any, ts: number) {
  if (!root['debugTiming']) return;
  console.debug(`[DebugTiming] ${logLine} took ${Date.now() - ts}ms`);
}

export function debugNetwork(logLine: any, ...params: any) {
  if (params && params.length) {
    console.debug(`[DebugNetwork] ${logLine}`, params);
  } else {
    console.debug(`[DebugNetwork] ${logLine}`);
  }
}

export function debugScanner(logLine: any, ...params: any) {
  if (params && params.length) {
    console.debug(`[DebugScanner] ${logLine}`, params);
  } else {
    console.debug(`[DebugScanner] ${logLine}`);
  }
}

export function debugStripeTerminal(logLine: any, ...params: any) {
  if (params && params.length) {
    console.debug(`[StripeTerminal] ${logLine}`, params);
  } else {
    console.debug(`[StripeTerminal] ${logLine}`);
  }
}

export function debugPurchase(logLine: any, ...params: any) {
  if (params && params.length) {
    console.debug(`[DebugPurchase] ${logLine}`, params);
  } else {
    console.debug(`[DebugPurchase] ${logLine}`);
  }
}

export function debugAnalytics(logLine: any, params?: any) {
  if (!root['debugAnalytics']) return;
  if (params && params.length) {
    console.debug(`[DebugAnalytics] ${logLine}`, params);
  } else {
    console.debug(`[DebugAnalytics] ${logLine}`);
  }
}

export function debugLineItem(lineItem: Partial<ILineItemWithPrices>) {
  if (process.env.NODE_ENV === 'development') return;
  const keys = [
    'basePrice',
    'extendedBasePrice',
    'taxable',
    'tickitFees',
    'tax',
    'displayFees',
    'subTotal',
  ];
  const vals = keys.map(key => `${key}: ${lineItem[key] ? formatMoney(lineItem[key]) : '--'}`);
  console.debug(`itemID: #${lineItem.itemID} x ${lineItem.quantity} --- ${vals.join(' ')}`);
}

export function debugItemPrice(currency: string, itemPrice: IItemPrice) {
  if (process.env.NODE_ENV === 'development') return;
  const keys = ['basePrice', 'unitTax', 'taxPercent', 'displayFees', 'sellerFees', 'displayPrice'];
  const vals = keys.map(
    key =>
      `${key}: ${
        itemPrice[key] && itemPrice[key].toUnit ? formatMoney(itemPrice[key]) : itemPrice[key]
      }`,
  );
  console.debug(`itemPrice: ${currency} (${itemPrice.fiat}) --- ${vals.join(' ')}`);
}

export function debugTotalsByCurrency(totalsByCurrency: ITotalsByCurrency) {
  if (process.env.NODE_ENV === 'development') return;
  const vals = Object.values(totalsByCurrency).map(
    t => `    ${t.currency} ${t.fiat ? 'fiat' : 'token'}: ${formatMoney(t.grandTotal)}`,
  );
  console.debug(`totalsByCurrency:\n${vals.join('\n')}\n`);
}
