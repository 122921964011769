import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSettings } from 'shared/actions';
import { RootState } from 'shared/rootReducer';
import { ErrorBoundary } from './ErrorBoundary';
import { SettingsActions } from './Settings/SettingsActions';

interface State {
  waiting: boolean;
}

class DeviceName extends React.Component<Props, State> {
  scannerNameInput: React.RefObject<HTMLInputElement>;
  scannerStaffNamesInput: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.scannerNameInput = React.createRef();
    this.scannerStaffNamesInput = React.createRef();
    this.state = { waiting: false };
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ waiting: true });

    const scannerName = this.scannerNameInput.current ? this.scannerNameInput.current.value : '';
    const scannerStaffNames = this.scannerStaffNamesInput.current
      ? this.scannerStaffNamesInput.current.value
      : '';

    this.props.updateSettings({ scannerName, scannerStaffNames, setupScreen: undefined });
    this.props.onClose();
  };

  render() {
    return (
      <div className="overlay-panel settings-panel visible">
        <ErrorBoundary>
          <button
            className="overlay-close-btn"
            onClick={this.props.onClose}
            aria-label="Close Device Name Settings"
          >
            <i className="icon icon-close" />
          </button>
          <div className="settings-panel-content overlay-content">
            <div className="settings-single-screen settings-device-name-screen device-name-form">
              <div className="overlay-header">
                <h3 className="overlay-header-title">Name this device</h3>
                <p className="overlay-header-text">
                  Entering a scanning location (e.g. “Front Gate #2”) is useful for troubleshooting
                  duplicate scans and scheduling staff shifts.
                </p>
                <p className="overlay-header-text">
                  Staff names let you track how many people are admitted during a scanning shift.
                </p>
              </div>

              <form
                method="post"
                acceptCharset="utf-8"
                className="device-name-form"
                onSubmit={this.onSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    autoCapitalize="off"
                    autoCorrect="off"
                    required={true}
                    className="form-control text"
                    name="scannerName"
                    ref={this.scannerNameInput}
                    placeholder="Scanning location"
                    defaultValue={this.props.scannerName}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    autoCapitalize="off"
                    autoCorrect="off"
                    className="form-control text"
                    name="scannerStaffNames"
                    ref={this.scannerStaffNamesInput}
                    placeholder="Current staff names (optional)"
                    defaultValue={this.props.scannerStaffNames}
                  />
                </div>

                <SettingsActions
                  {...this.props}
                  waiting={this.state.waiting}
                  buttonLabel="Save"
                  waitingLabel="Saving..."
                />
              </form>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

const DeviceNameContainer: React.FunctionComponent<Props> = props => {
  return <DeviceName {...props} />;
};

const mapStateToProps = (state: RootState) => {
  return {
    scannerName: state.settings.scannerName,
    scannerStaffNames: state.settings.scannerStaffNames,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSettings,
    },
    dispatch,
  );

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface Props extends StateProps, DispatchProps {
  onClose: () => void;
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNameContainer);
