import { createReducer } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import { IItem } from 'shared/items';
import { v4 as uuidv4 } from 'uuid';
import * as actions from '../actions';
import addTicketTypeToTicket from '../addTicketTypeToTicket';
import addLineItemPrices from './addLineItemPrices';
import getCurrentOrder from './getCurrentOrder';
import getRecentOrders from './getRecentOrders';
import { ILineItems, IOrder } from './Order';
import updateOrderLineItem from './updateOrderLineItem';
import validateOrder from './validateOrder';
import sanitizeCardNumber from './sanitizeCardNumber';
import updateOrderFromServer from './updateOrderFromServer';

const INITIAL_STATE: IOrdersState = {
  orders: {},
  currentOrderUUID: undefined,
};

const UPDATEABLE_FIELDS = [
  'uuid',
  'completed',
  'success',
  'orderID',
  'completed',
  'currency',
  'email',
  'errors',
  'name',
  'success',
  'tipPercent',
  'optin',
  'currentShiftDigest',
  'hardTicketDelivery',
  'cardNumber',
  'cardBrand',
  'cardExpiresOn',
  'cardVerification',
];

export const UNPERSISTED_KEYS = ['cardNumber', 'cardExpiresOn', 'cardVerification'];

export enum PurchaseMode {
  cashPurchase = 'cashPurchase',
  cardPurchase = 'cardPurchase',
  tokenPurchase = 'tokenPurchase',
  compPurchase = 'compPurchase',
}

export interface IOrdersState {
  orders: IOrders;
  currentOrderUUID: string | undefined;
}

export interface IOrders {
  [key: string]: IOrder;
}

const createBlankOrder = (props: Partial<IOrder> = {}): IOrder => {
  const order: IOrder = Object.assign(
    {},
    {
      uuid: uuidv4(), // TODO2019 -- this is un-pure
      orderID: undefined,
      completed: false,
      errors: [],
      success: false,
      tickets: [],
      lineItems: {},
      hardTicketDelivery: false,
      tipPercent: 0,
    } as IOrder,
    props,
  );
  return order;
};

function loadOrders(oldState: IOrdersState, newOrders: Array<IOrder>): IOrders {
  const mergedOrders: IOrders = Object.assign({}, oldState.orders);
  newOrders.forEach(order => {
    const { uuid } = order;
    mergedOrders[uuid] = order;
  });

  return mergedOrders;
}

const ordersReducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(actions.loadOrders, (state, action) => {
    state.orders = loadOrders(state, action.payload);
  });

  builder.addCase(actions.saveCompletedOrder, (state, action) => {
    const order = action.payload as IOrder;
    state.orders = loadOrders(state, [order]);
    state.currentOrderUUID = order.uuid;
  });

  builder.addCase(actions.setCurrentOrderUUID, (state, action) => {
    const orderUUID: string | undefined = action.payload;
    if (orderUUID === undefined) {
      state.currentOrderUUID = undefined;
    } else if (state.orders[orderUUID]) {
      state.currentOrderUUID = orderUUID;
    } else {
      console.error(
        `Could not find orderID: '${orderUUID}' in setCurrentOrderUUID`,
        Object.keys(state.orders),
      );
    }
  });

  builder.addCase(actions.resetCurrentOrderLocally, state => {
    if (state.currentOrderUUID) {
      const currentOrder = state.orders[state.currentOrderUUID];
      if (currentOrder && !currentOrder.completed) {
        delete state.orders[state.currentOrderUUID];
      }
    }
    const newOrder = createBlankOrder({});
    state.orders[newOrder.uuid] = newOrder;
    state.currentOrderUUID = newOrder.uuid;
  });

  // CURRENT ORDER HANDLING
  builder.addCase(actions.updateOrder, (state, action) => {
    const order: IOrder =
      state.currentOrderUUID && state.orders[state.currentOrderUUID]
        ? state.orders[state.currentOrderUUID]
        : createBlankOrder();

    const payload = (action.payload || {}) as Partial<IOrder>;

    Object.keys(payload || {}).forEach(keyName => {
      if (!UPDATEABLE_FIELDS.includes(keyName)) {
        // console.debug(`updateOrder skipping ${keyName}`);
        return;
      }

      let value = payload[keyName];

      if (keyName === 'cardNumber' && value && value.length) {
        value = sanitizeCardNumber(value);
      }

      // console.debug(`updateOrder setting ${keyName}=${value}`);

      order[keyName] = value;
    });

    state.orders[order.uuid] = order;
    state.currentOrderUUID = order.uuid;
  });

  builder.addCase(actions.updateCurrentOrderFromServer, (state, action) => {
    let order: IOrder =
      state.currentOrderUUID && state.orders[state.currentOrderUUID]
        ? state.orders[state.currentOrderUUID]
        : createBlankOrder();

    if (order.completed) {
      console.warn('Cannot call updateCurrentOrderFromServer on a completed order');
      order = createBlankOrder();
    }

    state.orders[order.uuid] = updateOrderFromServer(order, action.payload);
    state.currentOrderUUID = order.uuid;
  });

  builder.addCase(actions.updateOrderLineItem, (state, action) => {
    const order: IOrder =
      state.currentOrderUUID && state.orders[state.currentOrderUUID]
        ? state.orders[state.currentOrderUUID]
        : createBlankOrder();

    const item: IItem = action.payload.item;
    const quantity: number = action.payload.quantity;

    state.orders[order.uuid] = updateOrderLineItem(order, item, quantity);
    state.currentOrderUUID = order.uuid;
  });

  builder.addCase(actions.defineManifest, () => {
    return INITIAL_STATE;
  });

  builder.addCase(actions.purge, () => {
    return INITIAL_STATE;
  });
});

/**
 * Adds ticket and line item details to an order
 *
 * IMMUTABLE
 */
const addTicketTypesToOrder = (
  rawOrder: IOrder,
  ticketTypes: Map<string, IItem>,
  scanningItemIDs: Array<string> = [],
): IOrder => {
  const order = { ...rawOrder };
  if (!order.tickets) order.tickets = [];
  if (!ticketTypes || ticketTypes.size < 1) return order;

  order.tickets = order.tickets.map(ticket => {
    if (!ticket.itemID) return ticket;

    return addTicketTypeToTicket(ticket, ticketTypes, scanningItemIDs);
  });

  const updatedLineItems: ILineItems = { ...order.lineItems };

  Object.keys(updatedLineItems).forEach(itemID => {
    const ticketType = ticketTypes.get(itemID);
    if (!ticketType) {
      console.warn(`Missing lineItem ticketType ${itemID} even though it was in the order history`);
      return;
    }
    const lineItem = order.lineItems[itemID];
    updatedLineItems[itemID] = { ...lineItem, item: ticketType };
  });

  return { ...order, lineItems: updatedLineItems };
};

function orderHasHardTickets(order: IOrder): boolean {
  return !!order.tickets.find(ticket => ticket.hardTicket);
}

function orderHasEmail(order: IOrder): boolean {
  return !isNil(order.email) && String(order.email).length > 0;
}

export * from './getPossibleOrderTotals';
export * from './Order';
export {
  ordersReducer as default,
  createBlankOrder,
  INITIAL_STATE,
  addTicketTypesToOrder,
  getRecentOrders,
  orderHasHardTickets,
  orderHasEmail,
  addLineItemPrices,
  getCurrentOrder,
  validateOrder,
  updateOrderLineItem,
};
