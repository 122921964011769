import { IItem } from './items';
import { ITicket } from './Ticket';

export default function addTicketTypeToTicket(
  originalTicket: ITicket,
  ticketTypes: Map<string, IItem>,
  scanningItemIDs?: Array<string>,
): ITicket {
  if (!originalTicket.itemID) return originalTicket;
  const ticketType = ticketTypes.get(originalTicket.itemID);

  if (!ticketType) {
    console.warn(`Missing ticketType '${originalTicket.itemID}'`);
    return originalTicket;
  }

  const updatedTicket = Object.assign({}, originalTicket);

  if (scanningItemIDs) {
    updatedTicket.showAdmitButton = scanningItemIDs.includes(originalTicket.itemID);
  }
  updatedTicket.itemTitle = ticketType.itemTitle;
  updatedTicket.eventTitle = ticketType.eventTitle;
  updatedTicket.eventID = ticketType.eventID;
  updatedTicket.unlimitedCheckins = ticketType.unlimitedCheckins;

  return updatedTicket;
}
