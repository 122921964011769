import React from 'react';
import { Props as ParentProps } from './AppContainer';

type Props = Pick<ParentProps, 'updateBattery'>;

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withBatteryStatus = WrappedComponent => {
  class WithBatteryStatus extends React.Component<Props> {
    static displayName = `WithBatteryStatus(${getDisplayName(WrappedComponent)})`;

    onBatteryStatus = batteryManager => {
      const level = isNaN(batteryManager.level) ? null : batteryManager.level * 100;
      this.props.updateBattery(level, batteryManager.charging);
    };

    componentDidMount() {
      if (!navigator.getBattery) return;

      navigator
        .getBattery()
        .then(batteryManager => {
          this.onBatteryStatus(batteryManager);
          batteryManager.addEventListener('chargingchange', this.onBatteryStatus);
          batteryManager.addEventListener('levelchange', this.onBatteryStatus);
        })
        .catch(error => console.error(error));
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithBatteryStatus;
};

export default withBatteryStatus;
