import { createSelector } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import { IDeviceStatus } from './deviceStatusReducer';
import { RootState } from './rootReducer';
import { getDeviceScanCountLastHour } from './scans';
import { getShiftSettings } from './settingsReducer';
import { getScansByItemInCurrentShift } from './statsSelector';
import { ITicket } from './Ticket';
const DEFAULT_SCANNER_NAME = 'DEFAULT_SCANNER_NAME';
const getDeviceStatus = (state: RootState): IDeviceStatus => state.deviceStatus;
const getScanningItemIDs = (state: RootState) => state.settings.scanningItemIDs;
const getSellingItemIDs = (state: RootState) => state.settings.sellingItemIDs;
const getTicketCount = (state: RootState): number => {
  const tickets: Array<ITicket> = Object.values(state.tickets);
  return tickets.filter(ticket => !ticket.deleted).length;
};
const getTotalDeviceScanCount = (state: RootState): number => state.scans.totalDeviceScanCount;

export const deviceStatusSelector = createSelector(
  [
    getDeviceStatus,
    getScansByItemInCurrentShift,
    getScanningItemIDs,
    getSellingItemIDs,
    getTicketCount,
    getShiftSettings,
    getDeviceScanCountLastHour,
    getTotalDeviceScanCount,
  ],
  (
    deviceStatus,
    scansByItemInShift,
    scanningItemIDs,
    sellingItemIDs,
    ticketCount,
    shiftSettings,
    scansByDeviceLastHour,
    scansByDevice,
  ) => {
    const scansInShift = sum(Object.values(scansByItemInShift));
    const scannerName =
      shiftSettings.scannerName === DEFAULT_SCANNER_NAME ? null : shiftSettings.scannerName;

    return Object.assign({}, deviceStatus, shiftSettings, {
      scansInShift,
      scanningItemIDs,
      sellingItemIDs,
      ticketCount,
      scannerName,
      scansByDeviceLastHour,
      scansByDevice,
    });
  },
);
