import React from 'react';
import arrayFrom from 'array-from';
import { ITicket } from 'shared/Ticket';

interface Props {
  ticket: ITicket;
}

const TicketBadges: React.FunctionComponent<Props> = props => {
  const { ticket } = props;
  const badges: JSX.Element[] = [];

  if (ticket.tags) {
    for (const tag of arrayFrom(ticket.tags)) {
      badges.push(
        <span key={tag} className="ticket-badge ticket-badge-user">
          {tag}
        </span>,
      );
    }
  }

  if (ticket.deleted) {
    badges.push(
      <span key="deleted-badge" className="ticket-badge ticket-badge-deleted">
        Cancelled
      </span>,
    );
  }

  if (badges.length < 1) return null;

  return <span className="ticket-badges">{badges}</span>;
};

export { TicketBadges };
