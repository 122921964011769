import React from 'react';
import { connect } from 'react-redux';
import { AppThunkDispatch, RootState } from 'shared/rootReducer';
import { connectToStore } from 'shared/syncActions';
import ConnectivityContext, { ConnectivityContextState } from '../ConnectivityContext';
import Login from './index';

const LoginContainer: React.FunctionComponent<Props> = props => {
  return (
    <ConnectivityContext.Consumer>
      {context => {
        return <Login {...props} isOnline={context.isOnline} />;
      }}
    </ConnectivityContext.Consumer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    storeSlug: state.settings.storeSlug,
    storeKey: state.settings.storeKey,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    connectToStore: (storeSlug: string, storeKey: string) =>
      dispatch(connectToStore(storeSlug, storeKey)),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface Props extends StateProps, DispatchProps {}

export interface PassedProps extends Props, Pick<ConnectivityContextState, 'isOnline'> {}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
