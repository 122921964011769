export const PROCESS_SCAN = 'PROCESS_SCAN';
export const SCAN_RANDOM = 'SCAN_RANDOM';
export const CLEAR_CURRENT_SCAN = 'CLEAR_CURRENT_SCAN';
export const CONNECT_TO_STORE = 'CONNECT_TO_STORE';
export const SETUP_ERROR = 'SETUP_ERROR';
export const OPEN_SETUP = 'OPEN_SETUP';
export const CHANGE_CURRENT_SCREEN = 'CHANGE_CURRENT_SCREEN';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const CONFIGURE_MANIFEST_AND_IMPORT = 'CONFIGURE_MANIFEST_AND_IMPORT';
export const SYNC = 'SYNC';
export const START_POLLING = 'START_POLLING';
export const STOP_POLLING = 'STOP_POLLING';
export const SET_HARD_TICKET_DELIVERY = 'SET_HARD_TICKET_DELIVERY';
export const PRINT_TICKETS = 'PRINT_TICKETS';
export const CONNECT_TO_PRINTER = 'CONNECT_TO_PRINTER';
export const RECONNECT_TO_PRINTER = 'RECONNECT_TO_PRINTER';
export const DISCONNECT_FROM_PRINTER = 'DISCONNECT_FROM_PRINTER';
export const POST_DEBUG_DATA = 'POST_DEBUG_DATA';
export const UPDATE_BATTERY = 'UPDATE_BATTERY';
export const UPDATE_DEVICE_STATUS = 'UPDATE_DEVICE_STATUS';
export const UPDATE_FROM_SHARED_CONFIG = 'UPDATE_FROM_SHARED_CONFIG';
