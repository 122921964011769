import React from 'react';
import { IScan } from 'shared/scans';
import TickitUtils from 'shared/Utils';
import { CheckInComments } from './CheckInComments';
import { ErrorBoundary } from './ErrorBoundary';
import Seat from './Seat';
import { TicketBadges } from './TicketBadges';

interface Props {
  currentScan?: IScan;
  readyToScan: boolean;
}

interface State {
  fresh: boolean;
}

class ScanResults extends React.Component<Props, State> {
  staleTimeout: number | undefined;

  constructor(props) {
    super(props);
    this.staleTimeout = undefined;
    this.state = { fresh: true };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentScan &&
      this.props.currentScan &&
      prevProps.currentScan.uuid === this.props.currentScan.uuid
    ) {
      return;
    }

    // new scan so clear old timer
    window.clearTimeout(this.staleTimeout);

    if (!this.props.currentScan) return;

    // wire up the icon fade/bg transition timer
    this.setState({ fresh: true });
    this.staleTimeout = window.setTimeout(() => {
      this.setState({ fresh: false });
    }, 500);
  }

  icon() {
    const className = ['scan-results-icon'];
    if (!this.props.currentScan) {
      return <div className={className.join(' ')} />;
    }

    const iconClassName = ['icon'];

    if (this.props.currentScan.valid) {
      if (this.props.currentScan.reversal) {
        iconClassName.push('icon-check-in-reverse');
      } else {
        iconClassName.push('icon-check-in-good');
      }
    } else {
      iconClassName.push('icon-check-in-bad');
    }

    return (
      <div className={className.join(' ')}>
        <i className={iconClassName.join(' ')} />
      </div>
    );
  }

  renderOccurrence(currentScan: IScan | undefined) {
    if (!currentScan) return null;
    if (currentScan.reversal) return null;

    const ticket = currentScan.ticket;
    if (!ticket) return null;

    const occurrence = TickitUtils.formattedOccurrence(ticket);
    if (!occurrence) return null;

    return <p className="scan-results-occurrence">{occurrence}</p>;
  }

  renderTicket() {
    if (!this.props.currentScan || !this.props.currentScan.ticket) return <div />;
    const { ticket } = this.props.currentScan;

    return (
      <div className="ticket-container">
        <Seat ticket={ticket} />
        <p className="ticket-type">
          {ticket.itemTitle}
          <span className="event-name">{ticket.eventTitle}</span>
        </p>

        <p className="ticket-holder">{ticket.ticketHolder}</p>
        <p className="ticket-number">{ticket.ticketNumber}</p>
      </div>
    );
  }

  renderBadges() {
    if (!this.props.currentScan) return false;
    const { ticket } = this.props.currentScan;
    if (!ticket) return false;
    return <TicketBadges ticket={ticket} />;
  }

  renderComments() {
    if (!this.props.currentScan) return false;
    const { ticket } = this.props.currentScan;
    if (!ticket) return false;
    return <CheckInComments ticket={ticket} />;
  }

  renderScan() {
    let details, message;
    let emptyDetails = false;
    if (this.props.currentScan) {
      ({ message } = this.props.currentScan);
      if (this.props.currentScan.details) {
        ({ details } = this.props.currentScan);
      }
    }

    if (!message || message === '') {
      message = '\xA0';
    }

    if (!details || details === '') {
      details = '\xA0';
      emptyDetails = true;
    }

    const detailsClass = emptyDetails ? 'scan-results-details empty' : 'scan-results-details';

    return (
      <div className="scan-results-badge-container">
        {this.icon()}
        <p className="scan-results-message">{message}</p>
        {this.renderOccurrence(this.props.currentScan)}
        <p className={detailsClass}>{details}</p>
        {this.renderBadges()}
        {this.renderComments()}
      </div>
    );
  }

  render() {
    const className = ['sidebar', 'scan-results'];

    const { currentScan, readyToScan } = this.props;

    if (!readyToScan) return null;

    if (this.state.fresh !== true) {
      className.push('stale');
    }

    if (currentScan) {
      if (currentScan.reversal) {
        className.push('reversal');
      } else if (currentScan.valid) {
        className.push('valid');
      } else {
        className.push('invalid');
      }

      if (currentScan.ticket) {
        className.push('found-ticket');
      } else {
        className.push('no-ticket');
      }
    } else {
      className.push('no-current-scan');
    }

    return (
      <aside className={className.join(' ')}>
        <ErrorBoundary>
          {this.renderScan()}
          {this.renderTicket()}
        </ErrorBoundary>
      </aside>
    );
  }
}

export default ScanResults;
