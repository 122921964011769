import React, { useContext, useEffect, useState } from 'react';
import { QRCode } from 'react-qr-svg';
import {
  generateShareableConfigURL,
  PersistedShareableConfig,
} from 'shared/settings/shareableConfig';
import ConnectivityContext from '../ConnectivityContext';
import { PassedProps as ParentProps } from './index';
type Props = Pick<ParentProps, 'shareableConfigSettings' | 'postDeviceConfiguration'>;

const SettingsConfigQR: React.FunctionComponent<Props> = props => {
  const connectivity = useContext(ConnectivityContext);
  const { postDeviceConfiguration } = props;
  const { isOnline } = connectivity;
  const [deviceConfiguration, setDeviceConfiguration] = useState<
    PersistedShareableConfig | undefined
  >(undefined);

  useEffect(() => {
    async function share() {
      const result = await postDeviceConfiguration();
      setDeviceConfiguration(result);
    }
    if (isOnline) {
      share();
    }
  }, [postDeviceConfiguration, isOnline]);

  if (!isOnline) {
    return (
      <div className="settings-single-screen settings-credentials-screen credentials-form">
        <div className="overlay-header">
          <h3 className="overlay-header-title">Share Settings</h3>
        </div>

        <p>You need to be connected to the internet to share your configuration.</p>
      </div>
    );
  }

  if (!deviceConfiguration) {
    return (
      <div className="settings-single-screen settings-credentials-screen credentials-form">
        <div className="overlay-header">
          <h3 className="overlay-header-title">Share Settings</h3>
        </div>

        <p>Syncing...</p>
      </div>
    );
  }

  const data = generateShareableConfigURL(deviceConfiguration);

  return (
    <div className="settings-single-screen settings-credentials-screen credentials-form">
      <div className="overlay-header">
        <h3 className="overlay-header-title">Share Settings</h3>
      </div>

      <p>Scan this barcode to copy your settings to another Tickit Check-in app.</p>

      <div className="config-qr">
        <QRCode bgColor="#FFFFFF" fgColor="#000000" level="M" style={{ width: 256 }} value={data} />

        <p className="config-qr-label">{deviceConfiguration.label}</p>
      </div>
    </div>
  );
};

export default SettingsConfigQR;
