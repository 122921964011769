import React from 'react';
import { connect } from 'react-redux';
import { updateBattery, updateSettings } from 'shared/actions';
import { debugNetwork } from 'shared/debug';
import { AppThunkDispatch, RootState } from 'shared/rootReducer';
import { getCurrentScan } from 'shared/scans';
import { getConfigSettings, ISettingsState, SetupScreens } from 'shared/settingsReducer';
import { clearAllData, openSetup, startPolling, stopPolling, sync } from 'shared/syncActions';
import App from './App';
import ConnectivityContext, { ConnectivityContextState } from './ConnectivityContext';
import { setErrorReportingUser } from './lib/errorReporting';
import { getDeviceInfo } from './lib/TickitDeviceInfo';
import Login from './Login/LoginContainer';
import { rehydrate } from './stores/rehydrate';
import withBatteryStatus from './withBatteryStatus';

interface State {
  isOnline: boolean;
  dataLoaded: boolean;
}

class AppContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOnline: navigator.onLine,
      dataLoaded: false,
    };
  }

  componentDidMount() {
    rehydrate()
      .then(() => {
        // we need to wait for credentials before we can start polling
        this.setState({ dataLoaded: true }, () => this.handleReachabilityChange());
      })
      .catch(error => console.error(error));

    window.addEventListener('online', this.onOnline);
    window.addEventListener('offline', this.onOffline);
    getDeviceInfo().then(deviceInfo => {
      this.props.updateSettings(deviceInfo, true);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeKey !== this.props.storeKey) {
      setErrorReportingUser(this.props.storeKey);
    }
  }

  componentWillUnmount() {
    debugNetwork('Unmounting, dispatching stopPolling');
    window.removeEventListener('online', this.onOnline);
    window.removeEventListener('offline', this.onOffline);
    this.props.stopPolling();
    // if (this.props.websockets) {
    //   this.props.websockets.disconnect();
    // }
  }

  onOnline = () => {
    this.setState({ isOnline: true }, () => this.handleReachabilityChange());
  };

  onOffline = () => {
    this.setState({ isOnline: false }, () => this.handleReachabilityChange());
  };

  handleReachabilityChange = () => {
    debugNetwork('handleReachabilityChange', this.state.isOnline);
    if (this.state.isOnline) {
      this.props.startPolling();
      // this.props.websockets.connect();
    } else this.props.stopPolling();
  };

  render() {
    const { readyToScan, currentTheme, setupScreen, storeCredentialsOK } = this.props;

    const context: ConnectivityContextState = {
      isOnline: this.state.isOnline,
      readyToScan: this.props.readyToScan,
      readyToSell: false,
      storeCredentialsOK: this.props.storeCredentialsOK,
      storeKey: this.props.storeKey,
      hasCheckinPermission: this.props.hasCheckinPermission,
      hasGlobalStatsPermission: this.props.hasGlobalStatsPermission,
      useHardwareBarcodeReader: false,
      printerCapable: false,
    };

    if (this.state.dataLoaded && !storeCredentialsOK) {
      return (
        <ConnectivityContext.Provider value={context}>
          <Login />
        </ConnectivityContext.Provider>
      );
    }

    return (
      <ConnectivityContext.Provider value={context}>
        <App
          showFirstRun={!readyToScan}
          readyToScan={readyToScan}
          currentTheme={currentTheme}
          isOnline={context.isOnline}
          setupScreen={setupScreen}
          openSetup={this.props.openSetup}
          currentScan={this.props.currentScan}
          dataLoaded={this.state.dataLoaded}
          hasCheckinPermission={context.hasCheckinPermission}
          hasGlobalStatsPermission={context.hasGlobalStatsPermission}
          sync={this.props.sync}
          clearAllData={this.props.clearAllData}
        />
      </ConnectivityContext.Provider>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const settings = getConfigSettings(state);

  return {
    readyToScan: settings.readyToScan,
    storeCredentialsOK: settings.storeCredentialsOK,
    storeKey: settings.storeKey,
    currentTheme: settings.currentTheme,
    setupScreen: settings.setupScreen,
    hasCheckinPermission: settings.hasCheckinPermission,
    hasGlobalStatsPermission: settings.hasGlobalStatsPermission,
    currentScan: getCurrentScan(state),
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  // bindActionCreators plays poorly with typesript and thunks
  return {
    startPolling: () => dispatch(startPolling()),
    stopPolling: () => dispatch(stopPolling()),
    sync: () => dispatch(sync()),
    updateBattery: (batteryPercent: number | undefined | null, pluggedIn: boolean) =>
      dispatch(updateBattery(batteryPercent, pluggedIn)),
    openSetup: (setupScreen: SetupScreens | undefined) => dispatch(openSetup(setupScreen)),
    updateSettings: (settings: Partial<ISettingsState>, skipPersisting?: boolean) =>
      dispatch(updateSettings(settings, skipPersisting)),
    clearAllData: () => dispatch(clearAllData()),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export interface Props extends StateProps, DispatchProps {}

export default connect(mapStateToProps, mapDispatchToProps)(withBatteryStatus(AppContainer));
